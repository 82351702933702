import { makeApiCalls } from "../store/api";

export function createNewTierPayment() {
  return makeApiCalls(
    [
      {
        endpoint: 'CREATE_TIER_PAYMENT',
        data: {}
      }
    ],
  ).then((response) => {
    return response[0].data;
  });
}

export function createYAPayment() {
  return makeApiCalls(
    [
      {
        endpoint: 'CREATE_YA_PAYMENT',
        data: {}
      }
    ],
  ).then((response) => {
    return response[0].data;
  });
}