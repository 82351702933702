import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './routes';
import { sharedSetItem, SharedSetItemType } from './store/shared';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { getInstrumentList, getJwtToken, getUserProfile } from './services/user';
import { getDebugUser, toHash } from './services/helpers';
import { getStaticContent } from './services/staticContent';
import { getAllRedeemItems } from './services/redeem';
import ScrollToTop from "./scrollToTop";
import { useTranslation } from 'react-i18next';
import { RewardType } from './constants';

function App({
  history,
  hasTriedLoginAuth0,

  setProfile,
  setChangePasswordPath,
  setResendEmailPath,
  setGenericStaticContent,
  setInstruments,
  setCountries,
  setAsiamile,
  setErrorPageNotFound,
  setFaq,
  setTnc,
  setHomeGrid,
  setWhatIsClubBravo,
  setWelcomeBlack,
  setWelcomeRed,
  setWelcomeGold,
  setRedeemOnSitePickUpItemSuccess,
  setRedeemMailItemSuccess,
  setRedeemCouponSuccess,
  setRedeemFreeTicketSuccess,
  setRedeemFailPointInsufficient,
  setRedeemFailStockInsufficient,
  setAsiaMilesSuccess,
  setAsiaMilesFail,
  setAsiaPayFail,
  setScanRequiredLogin,
  setAsiaPayError,
  setScanSuccess,
  setScanPending,
  setScanDuplicated,
  setScanExpired,
  setScanInvalid,
  setScanTutorial,
  setSchoolTypes,
  setHowToBanner,
  setPlaceholderMembershipNumber,
  setPlaceholderLastName,
  setPlaceholderEmailOrMobile,
  setBeforePriorityMessage,
  setStartPriorityMessage,
  setPublicExpiredMessage,
  setRegistration,
  setStaticPage,

  setRegistrationFormBenefit,
  setIsRedeemItemsReady,
  setRedeemedItemCategories,
  setHighlightedRewards,
  setAllYearRounds,
  setRedeems,
  setSpecialOffers,
  setArtsAndCultures,
  setBookStoreAndCulturalInstitutions,
  setLifestyles,
  setMusicCenterAndInstitutions,
  setRestaurants,
  setEvents
}) {
  const { user, getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();
  const { t, i18n } = useTranslation();

  const assignUserProfile = async (credential) => {
    const profile = await getUserProfile(credential);
    setProfile(profile);
  };

  const fetchInstruments = async () => {
    const res = await getInstrumentList();
    if (!res) return;
    setInstruments(res.allInstrument || []);
  };
  const parseCountryList = (countries) => {
    countries = countries.sort((a, b) => a.name.en > b.name.en ? 1 : -1);
    // var mcIndex = countries.findIndex((country) => country.country_code == '853');
    // countries.unshift(countries.splice(mcIndex, 1)[0]);

    var hkIndex = countries.findIndex((country) => country.country_code == '852');
    countries.unshift(countries.splice(hkIndex, 1)[0]);

    return countries;
  };

  const fetchStaticContents = async () => {
    const res = await getStaticContent();
    if (!res) return;
    // setCountries
    setCountries(parseCountryList(res.countries));
    setErrorPageNotFound(res.error_page_not_found || []);
    setFaq(res.faq || []);
    setTnc((res.terms_and_conditions || [])[0]);
    setHomeGrid(res.home_grid || []);
    setWhatIsClubBravo((res.what_is_club_bravo || []).map(v => {
      v.hash = toHash(v.name.en);
      return v;
    }));
    setWelcomeBlack(res.welcome_black || []);
    setWelcomeRed(res.welcome_red || []);
    setWelcomeGold(res.welcome_gold || []);
    setRedeemOnSitePickUpItemSuccess(res.redeem_on_site_pick_up_item_success || []);
    setRedeemMailItemSuccess(res.redeem_mail_item_success || []);
    setRedeemCouponSuccess(res.redeem_coupon_success || []);
    setRedeemFreeTicketSuccess(res.redeem_free_ticket_success || []);
    setRedeemFailPointInsufficient(res.redeem_fail_point_insufficient || []);
    setRedeemFailStockInsufficient(res.redeem_fail_stock_insufficient || []);
    setAsiaMilesSuccess(res.asia_miles_success || []);
    setAsiaMilesFail(res.asia_miles_fail || []);
    setScanRequiredLogin(res.scan_required_login || []);
    setScanDuplicated(res.scan_duplicated || []);
    setScanSuccess(res.scan_success || []);
    setScanPending(res.scan_pending || []);
    setScanExpired(res.scan_expired || []);
    setScanInvalid(res.scan_invalid || []);
    setScanTutorial(res.scan_tutorial || []);
    setSchoolTypes(res.school_types || []);
    setAsiaPayFail(res.asia_pay_fail || []);
    setAsiaPayError(res.asia_pay_error || []);
    setHowToBanner(res.how_to_banner || []);
    setPlaceholderMembershipNumber(res.placeholder_membership_number || []);
    setPlaceholderLastName(res.placeholder_last_name || []);
    setPlaceholderEmailOrMobile(res.placeholder_email_or_mobile || null);
    setBeforePriorityMessage(res.before_priority_message || null);
    setStartPriorityMessage(res.start_priority_message || null);
    setPublicExpiredMessage(res.public_expired_message || null);
    setRegistrationFormBenefit(res.registration_form_benefit || []);
    setAsiamile(res.asiamiles || null);
    setChangePasswordPath(res.auth0_change_password_path || null);
    setResendEmailPath(res.auth0_resend_email_path || null);
    setGenericStaticContent(res.generic || null);
    setRegistration((res.registration || []).map(v => {
      v.hash = toHash(v.name.en);
      return v;
    }));
    setStaticPage((res.static_page || []).map(v => {
      v.hash = toHash(v.name.en);
      return v;
    }));
  };

  const fetchRedeemItems = async () => {
    const res = user ? await getAllRedeemItems(user) : await getAllRedeemItems();
    if (!res) return;
    setHighlightedRewards(res.highlightRewards || []);
    // TODO refactor
    setRedeemedItemCategories(res?.redeemedItemCategories || []);
    let specialOfferItems = res?.allRedeemedItems?.filter(el => el.redeemed_item_category_id == 2) || [];
    setSpecialOffers([
      ...(specialOfferItems.map(reward => ({...reward, rewardType: RewardType.ITEM}))),
    ]);
    setAllYearRounds(res?.allRedeemedItems?.filter(el => el.redeemed_item_category_id == 3) || []);
    setArtsAndCultures(res?.allRedeemedItems?.filter(el => el.redeemed_item_category_id == 4) || []);
    setBookStoreAndCulturalInstitutions(res?.allRedeemedItems?.filter(el => el.redeemed_item_category_id == 5) || []);
    setLifestyles(res?.allRedeemedItems?.filter(el => el.redeemed_item_category_id == 6) || []);
    setRedeems(res?.allRedeemedItems?.filter(el => el.redeemed_item_category_id == 1) || []);
    setRestaurants(res?.allRedeemedItems?.filter(el => el.redeemed_item_category_id == 8) || []);
    setEvents(res?.events || []);



    setMusicCenterAndInstitutions(res?.allRedeemedItems?.filter(el => el.redeemed_item_category_id == 7) || []);
    if (user) {
      setIsRedeemItemsReady('ready_for_member');
    } else {
      setIsRedeemItemsReady('ready_for_guest');
    }
  }

  // auto login if auth0 user is set
  useEffect(() => {
    if (user) assignUserProfile(user);
  }, [user]);

  useEffect(() => {
    if (user) fetchRedeemItems();
  }, [user]);

  // trigger auto login if auth0 is logged in in other allowed site
  const checkAuth0Token = async () => {
    try {
      await getAccessTokenSilently();
    } catch (ex) {
      // do nothing
    }
  };

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) checkAuth0Token();
  }, [isLoading]);

  useEffect(() => {
    fetchInstruments();
    fetchStaticContents();
    fetchRedeemItems();
  }, []);

  return (
    <ConnectedRouter history={history}>
      <div className="App">
        <div id="mainWrapper">
          <Header />
          <ScrollToTop />
          <Routes />
          <Footer />
        </div>
      </div>
    </ConnectedRouter>
  );
}

const mapStateToProps = state => ({
  profile: state.shared.profile,
  hasTriedLoginAuth0: state.shared.has_tried_login_auth0,
});

const mapDispatchToProps = (dispatch) => ({
  setProfile: (value) => dispatch(sharedSetItem(SharedSetItemType.profile, value)),
  setChangePasswordPath: (value) => dispatch(sharedSetItem(SharedSetItemType.auth0_change_password_path, value)),
  setResendEmailPath: (value) => dispatch(sharedSetItem(SharedSetItemType.auth0_resend_email_path, value)),
  setGenericStaticContent: (value) => dispatch(sharedSetItem(SharedSetItemType.generic_static_content, value)),
  setInstruments: (value) => dispatch(sharedSetItem(SharedSetItemType.instruments, value)),
  setCountries: (value) => dispatch(sharedSetItem(SharedSetItemType.countries, value)),
  setAsiamile: (value) => dispatch(sharedSetItem(SharedSetItemType.asiamile, value)),
  setErrorPageNotFound: (value) => dispatch(sharedSetItem(SharedSetItemType.error_page_not_found, value)),
  setFaq: (value) => dispatch(sharedSetItem(SharedSetItemType.faq, value)),
  setTnc: (value) => dispatch(sharedSetItem(SharedSetItemType.tnc, value)),
  setHomeGrid: (value) => dispatch(sharedSetItem(SharedSetItemType.home_grid, value)),
  setWhatIsClubBravo: (value) => dispatch(sharedSetItem(SharedSetItemType.what_is_club_bravo, value)),
  setWelcomeBlack: (value) => dispatch(sharedSetItem(SharedSetItemType.welcome_black, value)),
  setWelcomeRed: (value) => dispatch(sharedSetItem(SharedSetItemType.welcome_red, value)),
  setWelcomeGold: (value) => dispatch(sharedSetItem(SharedSetItemType.welcome_gold, value)),
  setRedeemOnSitePickUpItemSuccess: (value) => dispatch(sharedSetItem(SharedSetItemType.redeem_on_site_pick_up_item_success, value)),
  setRedeemMailItemSuccess: (value) => dispatch(sharedSetItem(SharedSetItemType.redeem_mail_item_success, value)),
  setRedeemCouponSuccess: (value) => dispatch(sharedSetItem(SharedSetItemType.redeem_coupon_success, value)),
  setRedeemFreeTicketSuccess: (value) => dispatch(sharedSetItem(SharedSetItemType.redeem_free_ticket_success, value)),
  setRedeemFailPointInsufficient: (value) => dispatch(sharedSetItem(SharedSetItemType.point_insufficient, value)),
  setRedeemFailStockInsufficient: (value) => dispatch(sharedSetItem(SharedSetItemType.stock_insufficient, value)),
  setAsiaMilesSuccess: (value) => dispatch(sharedSetItem(SharedSetItemType.asia_miles_success, value)),
  setAsiaMilesFail: (value) => dispatch(sharedSetItem(SharedSetItemType.asia_miles_fail, value)),
  setScanRequiredLogin: (value) => dispatch(sharedSetItem(SharedSetItemType.scan_required_login, value)),
  setScanSuccess: (value) => dispatch(sharedSetItem(SharedSetItemType.scan_success, value)),
  setScanPending: (value) => dispatch(sharedSetItem(SharedSetItemType.scan_pending, value)),
  setScanDuplicated: (value) => dispatch(sharedSetItem(SharedSetItemType.scan_duplicated, value)),
  setScanExpired: (value) => dispatch(sharedSetItem(SharedSetItemType.scan_expired, value)),
  setScanInvalid: (value) => dispatch(sharedSetItem(SharedSetItemType.scan_invalid, value)),
  setAsiaPayFail: (value) => dispatch(sharedSetItem(SharedSetItemType.asia_pay_fail, value)),
  setAsiaPayError: (value) => dispatch(sharedSetItem(SharedSetItemType.asia_pay_error, value)),
  setHowToBanner: (value) => dispatch(sharedSetItem(SharedSetItemType.how_to_banner, value)),
  setRegistrationFormBenefit: (value) => dispatch(sharedSetItem(SharedSetItemType.registration_form_benefit, value)),
  setScanTutorial: (value) => dispatch(sharedSetItem(SharedSetItemType.scan_tutorial, value)),
  setSchoolTypes: (value) => dispatch(sharedSetItem(SharedSetItemType.school_types, value)),
  setPlaceholderMembershipNumber: (value) => dispatch(sharedSetItem(SharedSetItemType.placeholder_membership_number, value)),
  setPlaceholderLastName: (value) => dispatch(sharedSetItem(SharedSetItemType.placeholder_last_name, value)),
  setPlaceholderEmailOrMobile: (value) => dispatch(sharedSetItem(SharedSetItemType.placeholder_email_or_mobile, value)),
  setBeforePriorityMessage: (value) => dispatch(sharedSetItem(SharedSetItemType.before_priority_message, value)),
  setStartPriorityMessage: (value) => dispatch(sharedSetItem(SharedSetItemType.start_priority_message, value)),
  setPublicExpiredMessage: (value) => dispatch(sharedSetItem(SharedSetItemType.public_expired_message, value)),
  setRegistration:  (value) => dispatch(sharedSetItem(SharedSetItemType.registration, value)),
  setStaticPage:  (value) => dispatch(sharedSetItem(SharedSetItemType.static_page, value)),

  setIsRedeemItemsReady: (value) => dispatch(sharedSetItem(SharedSetItemType.is_redeem_items_ready, value)),
  setRedeemedItemCategories: (value) => dispatch(sharedSetItem(SharedSetItemType.redeemed_item_categories, value)),
  setHighlightedRewards: (value) => dispatch(sharedSetItem(SharedSetItemType.highlight_rewards, value)),
  setAllYearRounds: (value) => dispatch(sharedSetItem(SharedSetItemType.all_year_rounds, value)),
  setRedeems: (value) => dispatch(sharedSetItem(SharedSetItemType.redeems, value)),
  setSpecialOffers: (value) => dispatch(sharedSetItem(SharedSetItemType.special_offers, value)),
  setArtsAndCultures: (value) => dispatch(sharedSetItem(SharedSetItemType.arts_and_cultures, value)),
  setBookStoreAndCulturalInstitutions: (value) => dispatch(sharedSetItem(SharedSetItemType.bookstore_and_cultural_institutions, value)),
  setLifestyles: (value) => dispatch(sharedSetItem(SharedSetItemType.lifestyles, value)),
  setMusicCenterAndInstitutions: (value) => dispatch(sharedSetItem(SharedSetItemType.music_centre_and_institutions, value)),
  setRestaurants: (value) => dispatch(sharedSetItem(SharedSetItemType.restaurants, value)),
  setEvents: (value) => dispatch(sharedSetItem(SharedSetItemType.events, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
