import { useTranslation } from 'react-i18next'
import { connect } from "react-redux";
import UserName from './UserName';

const WelcomeBackBar = ({ showAvailablePoints = true, profile }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="d-flex col-12 con1 flex-wrap">
      <div className="col-12 col-sm-6 welcomeBack">
        {profile &&
          <p className="title1 text-center text-sm-start">{t('user.welcome_back')}</p>
        }
        <h5 className="title2 text-center text-sm-start">
          <UserName profile={profile} />
        </h5>
      </div>
      <div className="col-12 col-sm-6 d-flex memberStatus justify-content-end flex-wrap">
        {profile && (
          <p className="col-12 text-center text-sm-end mb-0">
            {t(`about_club_bravo_tier.club_bravo_${profile?.membership_tier || 'Black'}`)}
          </p>
        )}
        {profile && showAvailablePoints && (
          <p className="col-12 text-center text-sm-end align-self-end">
            {t('about_club_bravo_tier.available_point')}: 
            <span className='cb-logo ms-2'>b</span> 
            {profile?.available_points.toLocaleString()}
          </p>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  profile: state.shared.profile
});

export default connect(mapStateToProps)(WelcomeBackBar);