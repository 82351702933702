import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUser, getUserProfile  } from '../services/user';
import { sharedSetItem, SharedSetItemType } from '../store/shared';
import { getDebugUser } from '../services/helpers';

const LoginButton = ({ 
    profile, 
    setProfile, 
    showLoginIcon = false,
    fontStyle, 
    className = '',
    loginBtnText
}: {
    profile: any;
    setProfile: any;
    showLoginIcon?: boolean;
    className?: string;
    fontStyle?: string;
    loginBtnText?: string;
}) => {
    const { t, i18n } = useTranslation();
    const { loginWithRedirect } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useHistory();

    const onNavigate = async () => {
        if (isLoading) return;

        if (profile) {
            const profile = await getUser();
            setProfile(profile);
            navigate.push(`/${i18n.language}/member-detail`);
        } else {
            const debugUser = getDebugUser();
            if (debugUser) {
                setIsLoading(true);
                const profile = await getUserProfile(debugUser);
                setProfile(profile);
                setIsLoading(false);
            } else {
                loginWithRedirect({
                    type: 'login',
                    lang: i18n.language,
                    redirectUri: `${window.location.origin}/login`,
                    appState: {
                        page: navigate.location.pathname == ('/' + i18n.language + '/registration-sc') // Redirect user back to SC reg with token
                            ? (navigate.location.pathname + navigate.location.hash)
                            : navigate.location.pathname
                    }
                });
            }
        }
    };

    return (
        <>
            <a className={`nav-link nav-link-bottom btn ${className}`}
                onClick={onNavigate}>
                {
                    <div className='d-l-none d-flex flex-column'>
                        {   
                            showLoginIcon &&
                            <div className="icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icon-tabler-user">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                                </svg>
                            </div>
                        }
                        <p><strong className={fontStyle}>
                            {t(profile ? 'header.my_account' : loginBtnText)}
                        </strong><br /></p>
                    </div>
                }
                {
                    <div className='d-l-flex d-none'>
                        <p><strong className={fontStyle}>
                            {t(profile ? 'header.my_account' : loginBtnText)}
                        </strong><br /></p>
                    </div>
                }
            </a>
        </>
    )
}

const mapStateToProps = state => ({
    profile: state.shared.profile
});

const mapDispatchToProps = (dispatch) => ({
    setProfile: (value) => dispatch(sharedSetItem(SharedSetItemType.profile, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);
