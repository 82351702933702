import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import LogoutButton from '../components/LogoutButton';
import Popup from '../components/Popup';
import { Link, useHistory } from "react-router-dom";
import { dateFormat } from '../services/helpers';
import UserName from '../components/UserName';
import PointTransactionTable from '../components/PointTransactionTable';
import { sharedSetItem, SharedSetItemType } from '../store/shared';
import NextLevelGauge from '../components/NextLevelGauge';
import { GenericStaticContentCode, MembershipTier } from '../constants';
import { getGenericStaticContent } from '../functions';
import { lang } from 'moment';
import { getGaugeLevels } from "../services/user";
import { registrationForm } from '../services/enums';
import './MemberDetail.css';

const MemberDetail = ({ profile, setProfile, auth0ChangePasswordPath, genericStaticContent }) => {
  const { t, i18n } = useTranslation();
  const navigate = useHistory();
  
  const [membershipTier, setMembershipTier] = useState(MembershipTier.BLACK);
  const [showMembershipCard, setShowMembershipCard] = useState(false);
  const [showSCUpgradePopup, setShowSCUpgradePopup] = useState(false);
  const [userIsCompletedProfile, setUserIsCompletedProfile] = useState(false);
  const [annualEarnedPoint, setAnnualEarnedPoint] = useState(0);
  const [annualNumberOfProgramSynonyms, setAnnualNumberOfProgramSynonyms] = useState(0);
  const [annualNumberOfTicketsPurchased, setAnnualNumberOfTicketsPurchased] = useState(0);
  const [nextUpgradeTicketSpend, setNextUpgradeTicketSpend] = useState(0);
  const [nextUpgradeProgramSynonyms, setNextUpgradeProgramSynonyms] = useState(0);
  const [nextUpgradeTicketsPurchased, setNextUpgradeTicketsPurchased] = useState(0);
  const [percentageOfTicketsPurchased, setPercentageOfTicketsPurchased] = useState('0,100');
  const [percentageOfNumberOfProgramSynonyms, setPercentageOfNumberOfProgramSynonyms] = useState('0,100');
  const [percentageAnnualPoints, setPercentageAnnualPoints] = useState('0,100');
  const [upgradableButIncompleteProfile, setUpgradableButIncompleteProfile] = useState(false);
  const [gaugeLevels, setGaugeLevels] = useState<ApiResponse | null>(null);

  interface ApiResponse {
    fullEarnedPoint: number;
    fullSynonyms: number;
    fullTicketPurchased: number;
  }

  // Fetch the gauge levels when the component mounts
  useEffect(() => {
    const fetchGaugeLevels = async () => {
      try {
        getGaugeLevels().then((res) => {
          setGaugeLevels(res);
        });
      } catch (error) {
        console.error('Error fetching gauge levels: ', error);
      }
    };

    fetchGaugeLevels();
  }, []);

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.member_detail')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.member_detail')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {

    // Do nothing if there's no current user or the user has completed registration
    if (!profile || profile.is_submitted_profile == registrationForm.submitted) {
      return;
    }

    // Redirect the user to the last selected registration page if the user has not completed registration
    switch (profile.is_submitted_profile) {
      case registrationForm.existing:
        navigate.push(`/${i18n.language}/registration-existing`);
        break;
      case registrationForm.free:
        navigate.push(`/${i18n.language}/registration-free`);
        break;
      case registrationForm.red:
        navigate.push(`/${i18n.language}/registration-red`);
        break;
      case registrationForm.sc:
        navigate.push(`/${i18n.language}/registration-sc`);
        break;
      case registrationForm.ya:
        navigate.push(`/${i18n.language}/registration-ya`);
        break;
      default:
        navigate.push(`/${i18n.language}/registration`);
        break;
    }
  }, [profile]);

  useEffect(() => {
    if (!profile) return;
    
    setMembershipTier(profile.membership_tier || MembershipTier.BLACK);
    setUserIsCompletedProfile(profile.is_profile_completed);

    setAnnualNumberOfTicketsPurchased(profile.upgrade_progress.annualTicket);
    setNextUpgradeTicketsPurchased(profile.upgrade_progress.nextUpgradeTicketsPurchased);
    let ticketsPurchasedPercentage = profile.upgrade_progress.annualTicket / profile.upgrade_progress.nextUpgradeTicketsPurchased * 100;
    setPercentageOfTicketsPurchased(ticketsPurchasedPercentage + ",100");
    setAnnualNumberOfProgramSynonyms(profile.upgrade_progress.annualNumberOfProgramSynonyms);
    setNextUpgradeProgramSynonyms(profile.upgrade_progress.nextUpgradeProgramSynonyms);
    let programSynonymsPercentage = profile.upgrade_progress.annualNumberOfProgramSynonyms / profile.upgrade_progress.nextUpgradeProgramSynonyms * 100;
    setPercentageOfNumberOfProgramSynonyms(programSynonymsPercentage + ",100");
    setNextUpgradeTicketSpend(profile.upgrade_progress.nextUpgradeTicketSpend);
    setPercentageAnnualPoints((profile.upgrade_progress.totalAnnualEarnedPoint / profile.upgrade_progress.nextUpgradeTicketSpend * 100) + ",100");
    setAnnualEarnedPoint(profile.upgrade_progress.totalAnnualEarnedPoint < 0? 0 : profile.upgrade_progress.totalAnnualEarnedPoint);

    // check if user is eligible to upgrade but incomplete profile
    setUpgradableButIncompleteProfile(
      !profile.is_profile_completed &&
      (
        profile.upgrade_progress.annualTicket >= profile.upgrade_progress.nextUpgradeTicketsPurchased ||
        profile.upgrade_progress.annualNumberOfProgramSynonyms >= profile.upgrade_progress.nextUpgradeProgramSynonyms ||
        profile.upgrade_progress.totalAnnualEarnedPoint >= profile.upgrade_progress.nextUpgradeTicketSpend
      )
    );

  }, [profile]);

  const onResetPassword = async () => {
    if (!auth0ChangePasswordPath) {
      console.error('Missing auth0 configures, please check system settings.');
      return;
    }

    const path = auth0ChangePasswordPath.split('?');
    const params = new URLSearchParams(path[1]);

    params.set('redirect_uri', window.location.origin);
    params.set('lang', i18n.language);
    window.location.href = path[0] + '?' + params.toString();
  };

  let asiaMilesHref = 'https://lifestyle.asiamiles.com/en/c/t3_eosccm_hkphil';
  if (i18n.language === 'tc') {
    asiaMilesHref = 'https://lifestyle.asiamiles.com/zh_TW/tc/c/t3_eosccm_hkphil';
  } else if (i18n.language === 'sc') {
    asiaMilesHref = 'https://lifestyle.asiamiles.com/zh/tc/c/t3_eosccm_hkphil';
  }

  return (
    <section className="mainContents mainContents2">
      {
        // Show member card in a popup
        showMembershipCard && (
          <Popup
            buttons={[
              {
                label: t('button.close'), callback: () => {
                  setShowMembershipCard(false)
                }
              },
            ]}
          >
            {profile?.membership_card
              ? <img src={profile.membership_card.path} className="w-100" />
              : <div className="message">{t('landing_page.missing_membership_card_hint')}</div>
            }
          </Popup>
        )
      }
      {
        // Show SC upgrade info in a popup
        showSCUpgradePopup && (
          <Popup
            buttons={[
              {
                label: t('button.close'), callback: () => {
                  setShowSCUpgradePopup(false)
                }
              },
            ]}
          >
            <p dangerouslySetInnerHTML={{ __html: getGenericStaticContent(genericStaticContent, GenericStaticContentCode.SC_UPGRADE_OFFER, i18n.language).content}}/>
            <div className="d-flex gap-3 mt-5">
              <a className="btn btn-cancel" href={asiaMilesHref} target="_blank">
                {t('landing_page.sc_upgrade_offer_btn_upgrade_now')}
              </a>
              <Link type="button" className="btn btn-cancel" to={`/${i18n.language}/whats-club-bravo#standard-chartered-cathay-mastercard`}>
                {t('landing_page.sc_upgrade_offer_btn_details')}
              </Link>
              <Link type="button" className="btn btn-cancel" to={`/${i18n.language}/terms-and-conditions`}>
                {t('landing_page.sc_upgrade_offer_btn_tnc')}
              </Link>
            </div>
          </Popup>
        )
      }

      <div className="container conWrapper">
        <div className="row">
          <div className="col-12 contents contents2">
            <div className="col-12 d-flex flex-wrap detailCons">
              <div className="d-flex col-12 con1 flex-wrap">
                <div className="col-12 col-lg-4 welcomeBack">
                  <p className="title1 text-center text-lg-start">{t('user.welcome_back')}</p>
                  <h5 className="title2 text-center text-lg-start">
                    <UserName profile={profile} />
                  </h5>
                </div>
                <div className="d-flex memberStatus col-12 col-lg-8 justify-content-between justify-content-lg-end flex-wrap">
                  <div className="dropdown">
                    <button className="dropbtn btn">
                      <span>
                        {t('landing_page.manage_membership')}
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg>
                    </button>
                    <div className="dropdown-content">
                      {/* complete profile upgrade to red btn */}
                      {upgradableButIncompleteProfile && (
                        <Link className="btn btn-outline" to={`/${i18n.language}/member-detail-edit`}>
                          {t('landing_page.complete_profile_to_upgrade')}
                        </Link>
                      )}

                      {
                        membershipTier == MembershipTier.BLACK ?
                          (
                            /* Let the user upgrade if they are still on black tier */
                            !upgradableButIncompleteProfile && (
                              <Link className="btn" to={`/${i18n.language}/registration-red`}>
                                {t('landing_page.upgrade_membership')}
                              </Link>
                            )
                          ) :
                          /* Show membership card for red/gold members */
                          <>
                            <button className="btn btn-outline" type="button" onClick={() => setShowMembershipCard(true)}>
                              {t('landing_page.membership_card')}
                            </button>
                          </>
                      }

                      {
                          membershipTier != MembershipTier.GOLD &&
                          <button className="btn btn-outline" type="button" onClick={() => setShowSCUpgradePopup(true)}>
                            {t('landing_page.sc_upgrade_offer')}
                          </button>
                      }

                      {/*{}*/}
                      {/*<div className="btnBox">*/}
                      {/*  <button className="btn" type="button" onClick={onResetPassword}>*/}
                      {/*    {t('change_password.change_of_password')}*/}
                      {/*  </button>*/}
                      {/*</div>*/}

                    </div>
                  </div>
                  <div className="btnBox">
                    <LogoutButton />
                  </div>
                </div>
              </div>
              {/* Member Card Row */}
              <div className="details d-flex col-12 flex-wrap">
                {/* Member Card */}
                <div className="col-12 col-lg-6 flex-wrap d-flex justify-content-between align-items-end memberDetails">
                  <div className="d-flex col-12 flex-wrap cons">
                    <div className="col-12">
                      <h6 className="title">
                        <UserName profile={profile} />
                      </h6>
                    </div>
                    <div className={`d-flex flex-wrap points align-items-center ${(profile?.membership_tier || 'Black').toLowerCase()}`}>
                      <div className="currentPoint">
                        <span className='cb-logo'>b</span>
                        {profile?.available_points.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nextGrade d-flex col-12 col-lg-6 flex-wrap">
                  <div className="d-flex justify-content-between col-12 flex-wrap mb-3">
                    <h4 className="col-12 col-lg-auto"><strong>{t('landing_page.until_next_level')}</strong></h4>
                  </div>
                  {/* Start of Until Next Level Graph */}
                  <div className="d-flex col-12 justify-content-between flex-wrap align-items-end cons">
                    <div className="gaugelog" style={{display: 'none'}}>
                      {gaugeLevels && annualNumberOfTicketsPurchased}
                      {gaugeLevels && gaugeLevels.fullTicketPurchased}
                    </div>
                    <NextLevelGauge
                      showFullLabel={gaugeLevels && annualNumberOfTicketsPurchased >= gaugeLevels.fullTicketPurchased}
                      strokeDasharray={percentageOfTicketsPurchased}
                      currentLevel={annualNumberOfTicketsPurchased}
                      targetLevel={nextUpgradeTicketsPurchased}
                      gaugeLabel={t('landing_page.tickets')}
                    />
                    <div className="gaugelog" style={{display: 'none'}}>
                      {gaugeLevels && annualNumberOfProgramSynonyms}
                      {gaugeLevels && gaugeLevels.fullSynonyms}
                    </div>
                    <NextLevelGauge
                      showFullLabel={gaugeLevels && annualNumberOfProgramSynonyms >= gaugeLevels.fullSynonyms}
                      strokeDasharray={percentageOfNumberOfProgramSynonyms}
                      currentLevel={annualNumberOfProgramSynonyms}
                      targetLevel={nextUpgradeProgramSynonyms}
                      gaugeLabel={t('landing_page.programs')}
                    />
                    <div className="gaugelog" style={{display: 'none'}}>
                      {gaugeLevels && annualEarnedPoint}
                      {gaugeLevels && gaugeLevels.fullEarnedPoint}
                    </div>
                    <NextLevelGauge
                      showFullLabel={gaugeLevels && annualEarnedPoint >= gaugeLevels.fullEarnedPoint}
                      strokeDasharray={percentageAnnualPoints}
                      currentLevel={annualEarnedPoint}
                      targetLevel={nextUpgradeTicketSpend}
                      gaugeLabel={t('landing_page.point')}
                    />
                    {/* End of Until Next Level Graph */}
                  </div>
                </div>
              </div>
              {/* Membership Row */}
              <div className="details d-flex col-12 flex-wrap">
                <div className="col-12 col-lg-6 flex-wrap d-flex justify-content-between status align-items-end">
                  <div className="d-flex justify-content-between col-12 flex-wrap mb-3">
                    <h4 className="col-12 col-lg-auto"><strong>{t('landing_page.membership')}</strong></h4>
                  </div>
                  <div className="d-flex col-12  flex-wrap cons  justify-content-lg-between justify-content-center">
                    <div className="col-6 text-lg-start text-center">
                      <h6 className="title">{t('landing_page.membership_status')}</h6>
                      <p className="col-12 mb-0">{t(`about_club_bravo_tier.club_bravo_${profile?.membership_tier || 'Black'}`)}</p>
                    </div>
                    {
                      membershipTier != MembershipTier.BLACK && (
                        <div className="col-6 text-lg-start text-center">
                          <h6 className="title">{t('landing_page.expiry_date') + ' *'}</h6>
                          <p>{dateFormat(profile?.tier_expiry_date) || '--'}</p>
                        </div>
                      )
                    }
                  </div>
                  <p className="renewal-remarks">*{t('landing_page.renewal_remarks')}</p>
                </div>
                <div className="personal2 d-flex col-12 col-lg-6 flex-wrap">
                  <div className="d-flex justify-content-between col-12 flex-wrap mb-lg-3">
                    <h4 className="col-12 col-lg-6"><br /></h4>
                  </div>
                  <div className="d-flex col-12 justify-content-between flex-wrap membershipCon align-items-end">
                    <div className="rewards d-flex col-12 col-lg-6">
                      <Link className="cons col-12 d-flex align-items-center justify-content-lg-start justify-content-center" to={`/${i18n.language}/reward-list`}>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-gift">
                            <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z"></path>
                          </svg>
                          <p className="title1">{t('landing_page.redeem_rewards')}</p>
                        </div>
                      </Link>
                    </div>
                    <Link className="d-flex col-12 col-lg-6 asiamiles" to={`/${i18n.language}/asiamiles`}>
                      <div className="cons col-12 d-flex align-items-center justify-content-lg-start justify-content-center">
                        <div className="text-start">
                          <div><img className="img-fluid" src="/assets/img/Cathay_EN_Horizontal_Colour.png" /></div>
                          <p className="title1">{t('landing_page.convert_to_asia_miles')} </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <p className="renewal-remarks" style={{ visibility: 'hidden' }}>''</p>

                </div>
              </div>
              {/* Personal Detail Row */}
              <div className="details d-flex col-12 flex-wrap">
                <div className="col-12 col-lg-6 flex-wrap d-flex justify-content-between align-items-end personal">
                  <div className="d-flex justify-content-between col-12 flex-wrap mb-3">
                    <div className="title1 col-12 col-lg-auto">
                      <h4>
                        <strong>{t('landing_page.personal_details')}</strong>
                      </h4>
                    </div>
                    <div className="col-12 col-lg-auto">
                      <p>
                        <Link className="text-end" to={`/${i18n.language}/personal-detail`}>
                          {t('landing_page.view_personal_details')}
                        </Link>
                      </p>
                    </div>
                    {
                      !userIsCompletedProfile &&
                      <p className="d-flex justify-content-start col-12">
                        <span className='' dangerouslySetInnerHTML={{ __html: t('landing_page.complete_profile_rewards') }}></span>
                      </p>
                    }
                    {
                      upgradableButIncompleteProfile &&
                        <p className="d-flex justify-content-start col-12 blinking-text">
                          <span className='' dangerouslySetInnerHTML={{ __html: t('about_club_bravo_tier.complete_profile_reminder') }}></span>
                        </p>
                    }
                  </div>
                  <div className="d-flex col-12 cons justify-content-between flex-wrap">
                    <div className="cons col-12">
                      <div>
                        <h6 className="title">{t('landing_page.email')}</h6>
                        <p>{profile?.email || '--'}</p>
                      </div>
                    </div>
                    {
                      membershipTier != MembershipTier.BLACK &&
                      <div className="cons col-12">
                        <div>
                          <h6 className="title">{t('landing_page.account_number')}</h6>
                          <p>{profile?.member_no || '--'}</p>
                        </div>
                      </div>
                    }
                    <div className="cons col-12">
                      <div>
                        <h6 className="title">{t('landing_page.phone')}</h6>
                        <p>
                          {profile?.country_code && <>+{profile?.country_code} </>}
                          {profile?.mobile || '--'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pointLog col-12 col-lg-6">
                  <div className="d-flex justify-content-between col-12 flex-wrap mb-3">
                    <h4 className="col-12 col-lg-6">
                      <strong>{t('point_transaction_log.point_transaction_log')}</strong>
                    </h4>
                    <Link className="col-12 col-lg-6 text-lg-end" to={`/${i18n.language}/point-transaction-log`}>
                      {t('point_transaction_log.view_point_transaction_log')}
                    </Link>
                  </div>
                  <p>&nbsp;</p>
                  <div className="col-12">
                    <PointTransactionTable transactions={(profile?.point_transactions || []).slice(0, 5)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

const mapStateToProps = state => ({
  profile: state.shared.profile,
  auth0ChangePasswordPath: state.shared.auth0_change_password_path,
  genericStaticContent: state.shared.generic_static_content,
});

const mapDispatchToProps = (dispatch) => ({
  setProfile: (value) => dispatch(sharedSetItem(SharedSetItemType.profile, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetail);
