/**
 * @description Get the generic static content given the static content dict, a StaticCotent.code and a language key
 */
export function getGenericStaticContent(
  staticContentDict: any,
  staticContentCode: string,
  languageKey: string
): {name: string, content: string} {

  // Assemble an array of static content objects related to the given static content code
  let matchingStaticContents = staticContentDict.reduce(
    (acc, cur) => {
      if (cur.code == staticContentCode) {
        acc.push(cur);
      }
      return acc;
    },
    []
  );

  // If there's any matching content
  if (matchingStaticContents.length > 0) {
    // Just take the 1st one
    return {
      name: matchingStaticContents[0].name?.[languageKey],
      content: matchingStaticContents[0].content?.[languageKey],
    };
  } else {
    // Fall back to empty content
    return { name: '', content: '' }
  }
}