import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import AsiaPayForm from "../components/AsiaPayForm";
import RegistrationForm, { ValidationInterface } from "../components/profile/RegistrationForm";
import { useTranslation } from "react-i18next";
import { validateDob, validateEmail, validateMobile, validateRequired } from "../services/helpers";
import { createYAPayment } from "../services/payment";
import {connect} from "react-redux";
import ReactLoadingSpinner from "../components/ReactLoadingSpinner";
import {useHistory} from "react-router";
import { AsiaPayFormLang, FormTypes, YAStatus } from "../services/enums";

// TODO these should be more reusable across pages


const RegistrationYA = ({profile}) => {
    const { t, i18n } = useTranslation();
    const { loginWithRedirect } = useAuth0();
    const [paymentParams, setPaymentParams] = useState(null);
    const navigate = useHistory();
    const [showForm, setShowForm] = useState(false);

    // These are the validations for the first page
    const [validations, setValidations] = useState<ValidationInterface>({
        salutations: [validateRequired],
        first_name: [validateRequired],
        last_name: [validateRequired],
        gender: [validateRequired],

        // Fields specific to YA on page 1
        guardian_name: [validateRequired],
        guardian_mobile: [validateRequired, validateMobile],
        guardian_email: [validateRequired, validateEmail],

        _custom: [
            formData => validateRequired('date_of_birth', formData.year, formData.month),
            formData => validateDob('date_of_birth', formData.year, formData.month),
        ]
    });

    const afterSubmit = ({ success }) => new Promise<string>(async (resolve) => {
        if (!success) return resolve(null);

        const res = await createYAPayment();
        if (!res.success) return resolve(null);

        setPaymentParams({...res.tier_payment, ...res.payment_gateway_params});

        // make sure giving React enough time to update layout
        setTimeout(() => {
            const button = document.getElementById("pay-btn");
            button.click();
            resolve(null);
        }, 300);
    });

    // Add validation rules on going to the next page
    const beforeNext = (current, next) => {
        let rules = { ...validations };

        // Validation rules for page 2
        if (next === 1) {
            rules.email = [validateRequired, validateEmail];
            rules.mobile = [validateRequired, validateMobile];
            rules.house_or_building_estate = [validateRequired];
            rules.road_or_street = [validateRequired];
            rules.district = [validateRequired];
            rules.country = [validateRequired];
        }
        // Validation rules for page 3
        else if (next === 2) {
            // YA-specific fields
            rules.school_type = [validateRequired];
            rules.school_name = [validateRequired];

            rules._custom.push(formData => !formData.consent ? t('error.missing_tnc') : null);
            rules._custom.push(formData => !formData.confirm_email ? t('error.confirmedEmailIsCorrect') : null);
            rules._custom.push(formData => !formData._student_card_base64 ? t('error.required', { field: t(`landing_page.student_card_file`) }) : null)
        }
        setValidations(rules);
        return true;
    }

    // Remove rules specific to this page's fields when "prev" is clicked
    const beforePrev = (current, prev) => {
        let rules = { ...validations };
        if (prev === 0) {
            delete rules.email;
            delete rules.mobile;
            delete rules.house_or_building_estate;
            delete rules.road_or_street;
            delete rules.district;
            delete rules.country;
        }
        if (prev === 1) {
            delete rules.school_type;
            delete rules.school_name;
            delete rules.student_card_file;

            rules._custom.splice(2, 3);
        }
        setValidations(rules);
        return true;
    }

    // Redirect to Auth0 login if there's no profile present
    useEffect(() => {

        // FIXME rework required now that the user has decided to go for a "select whether to login or register" approach

        // For the case of YA registration, we will use the "landed" param to check if we should redirect the browser to Auth0 login/register.
        // If landed is not set, this is just the first landing - in that case we'll just redirect the user to login/register.
        if (!/landed/.test(window.location.search)) {
            console.debug('[Registration YA] First landing - redirecting to Auth0 login/register.');
            loginWithRedirect({
                // This determines whether the user will be redirected to the login or registration page.
                // These params are defined by a 3rd party vendor Telensite, who's responsible for creating the registration / login pages
                //type: 'register',
                type: 'login',
                appState: {
                    page: `/${i18n.language}/registration-ya?landed=1`
                }
            });
        } else {
            // We have already visited Auth0 and the user should already have a user profile.
            // It takes a while for profile to become non-null, meanwhile we'll just hide the form
            if (!profile) {
                console.debug('[Registration YA] Not first landing - waiting for profile.');
                setShowForm(false);
            } else {
                // We now have a profile. But then we need to check if the user already has a YA status that
                // shouldn't need refilling the application. If so, we'll redirect to the member detail page
                if (
                    profile.is_submitted_profile == FormTypes.PROFILE_SUBMITTED
                    && profile.ya_status in [YAStatus.YA_STATUS_PENDING_APPROVAL, YAStatus.YA_STATUS_APPROVED]
                ) {
                    console.debug(`[Registration YA] YA status is ${profile.ya_status}. Redirecting to member detail page.`);
                    navigate.push(`/${i18n.language}/member-detail`);
                } else {
                    // Just let the user fill in the form
                    console.debug(`[Registration YA] User may fill in the registration form now.`);
                    setShowForm(true);
                }
            }

        }
    }, [profile]);

    // Set page title
    let setTitle = () => {
        document.title = t('html_title.registration_ya') + ' | ' + t('html_title.home');
    };
    useEffect(setTitle, []);
    useEffect(setTitle, [i18n.language]);

    return (
        showForm ?
            <>
                <RegistrationForm
                    formTitle={t('registration_common.ya_registration_title')}
                    formContent={t('registration_common.ya_registration_subtitle')}
                    validations={validations}
                    labelsWithStar={{ 'date_of_birth': true }}
                    beforeNext={beforeNext}
                    beforePrev={beforePrev}
                    afterSubmit={afterSubmit}
                    lastStepButtonLabel={t('registration_common.submit_and_pay')}
                    isYAForm={true}
                />

                {paymentParams && (
                    <AsiaPayForm
                        orderRef={paymentParams.order_ref}
                        amount={paymentParams.amount}
                        lang={AsiaPayFormLang[i18n.language]}
                        payType={paymentParams.pay_type}
                        merchantId={paymentParams.merchant_id}
                        secureHash={paymentParams.secure_hash}
                        isLive={paymentParams.is_live}
                    />
                )}
            </> :
            <ReactLoadingSpinner/>
    );
};

const mapStateToProps = state => ({
    profile: state.shared.profile,
});
export default connect(mapStateToProps)(RegistrationYA);