import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const NotFound = ({error_page_not_found}) => {
  const { t, i18n } = useTranslation();

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.not_found')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.not_found')+' | '+  t('html_title.home');
  }, [i18n.language]);

  return (

  <section className="mainContents">
    <div className="container conWrapper">
      <div className="row">
        <div className="col-sm-12 contents">
        {
          (error_page_not_found || []).map((item, i) => (
            <>
              <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
            </>
            ))
        }
        </div>
      </div>
    </div>
  </section>
  )
}

const mapStateToProps = state => ({
  error_page_not_found: state.shared.error_page_not_found,
});
export default connect(mapStateToProps)(NotFound);
