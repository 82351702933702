import { makeApiCalls } from "../store/api";

export function getRedeemItem(
  alias: string
) {
  return makeApiCalls(
    [
      {
        endpoint: 'PUBLIC_GET_REDEEM_ITEM',
        params: { alias }
      }
    ],
  ).then((responses) => {
    return responses[0]?.data;
  })
}

export function getEvent(
  alias: string
) {
  return makeApiCalls(
    [
      {
        endpoint: 'PUBLIC_GET_EVENT',
        params: { alias }
      }
    ],
  ).then((responses) => {
    return responses[0]?.data;
  })
}

export function getAllRedeemItems(user = null) {

  return makeApiCalls(
    [
      {
        endpoint: 'PUBLIC_GET_ALL_REDEEMED_ITEMS',
        params: { user }
      }
    ],
  ).then((responses) => {
    return responses[0]?.data;
  })
}

export function redeemItems(
  item_id,
  quantity,
  delivery_method
) {
  return makeApiCalls(
    [
      {
        endpoint: 'REDEEM_ITEM',
        data: {
          'item_id': item_id,
          'quantity': quantity,
          'delivery_method': delivery_method,
        }
      }
    ],
  ).then((responses) => {
    return responses[0]?.data;
  })
}

export function registerEvent(
    event_id,
    quantity,
) {
    return makeApiCalls(
        [
            {
                endpoint: 'REGISTER_EVENT',
                data: {
                    'event_id': event_id,
                    'quantity': quantity,
                }
            }
        ],
    ).then((responses) => {
        return responses[0]?.data;
    })
}



