/**
 * Popup params
 * title?: string
 * message?: string
 * buttons?: {
 *     label: string
 *     className?: string
 *     callback: () => void
 * }[]
 * 
 * Usage: 
 * <Popup title='Hello' message='World' buttons={[
 *    { label: 'Back', callback: () => {} }
 * ]} />
 * 
 * or 
 * 
 * <Popup>Your content</Popup>
 */

import { debounce } from "debounce";
import { useCallback } from "react";

const Popup = ({
  title = null,
  message = null,
  buttons = null,
  children = null,
}) => {

  return (
    <div className="popup">
      <div className="wrapper">
        {title && <h5 className="title"><b>{title}</b></h5>}
        {message && <div className="message" dangerouslySetInnerHTML={{ __html: message }}></div>}
        {children}
        {buttons && <div className="button-container mt-5">
          {buttons?.map(
            
            (button, i) => {

              let debounceCallback;
              if (button.callback) {
                debounceCallback = debounce(button.callback, 1000);
              }
            
              return  <button type="button" key={i} onClick={debounceCallback} className={button.className || ''}>
                        {button.label}
                      </button>;
            }
          )}
        </div>}
      </div>
    </div>
  );
};

export default Popup;