import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import RegistrationForm, { ValidationInterface } from "../components/profile/RegistrationForm";
import { validateDob, validateEmail, validateMobile, validateRequired } from "../services/helpers";
import { useEffect, useState } from "react";

const MemberDetailEdit = ({ profile }) => {
    const { t, i18n } = useTranslation();
    const navigate = useHistory();
    const [isBlack, setIsBlack] = useState(true);
    const [validations, setValidations] = useState<ValidationInterface>({
        email: [validateRequired, validateEmail],
        mobile: [validateMobile],
        _custom: [
            formData => validateDob('date_of_birth', formData.year, formData.month)
        ]
    });

    const afterSubmit = ({ success }) => new Promise<string>(async (resolve) => {
        if (!success) return resolve(null);

        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('from') === 'reward-detail') {
            navigate.goBack();
        } else {
            navigate.push(`/${i18n.language}/member-detail`);
        }

        return resolve(null);
    });

    //set html_title
    useEffect(() => { 
        document.title = t('html_title.member_detail_edit')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => { 
        document.title = t('html_title.member_detail_edit')+' | '+  t('html_title.home');
    }, [i18n.language]);

    useEffect(() => {
        if (!profile) return;
        setIsBlack(!profile.membership_tier || profile.membership_tier === 'Black');
    }, [profile]);

    useEffect(() => {
        if (isBlack) return;

        setValidations({
            salutations: [validateRequired],
            first_name: [validateRequired],
            last_name: [validateRequired],
            gender: [validateRequired],
            email: [validateRequired, validateEmail],
            mobile: [validateRequired, validateMobile],
            house_or_building_estate: [validateRequired],
            road_or_street: [validateRequired],
            district: [validateRequired],
            country: [validateRequired],
            _custom: [
                formData => validateRequired('date_of_birth', formData.year, formData.month),
                formData => validateDob('date_of_birth', formData.year, formData.month),
            ]
        });
    }, [isBlack]);

    return (
        <RegistrationForm
            formTitle={t('landing_page.edit_profile')}
            formContent={' '}
            validations={validations}
            labelsWithStar={{ 'date_of_birth': !isBlack }}
            afterSubmit={afterSubmit}
            useStraightForm={true}
            cancelCallback={() => navigate.goBack()}
        />
    );
};

const mapStateToProps = state => ({
    profile: state.shared.profile
});


export default connect(mapStateToProps)(MemberDetailEdit);