import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from '../i18n';
import QrScanner from 'qr-scanner'; // if installed via package and bundling with a module bundler like webpack or rollup
import { isMobile } from 'react-device-detect';
import './QrCodeScanner.css';
import { scanTicket } from '../services/ticket';
import { ScanResultStatus } from '../services/enums';

const QrCodeScanner = ({ profile, scan_required_login }) => {
  const navigate = useHistory();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [showUploadPhotoReminder, setShowUploadPhotoReminder] = useState<boolean>(false);
  const [qrCodeError, setQrCodeError] = useState<string>(null);

  // QrCodeScanner
  const [scanner, setScanner] = useState<QrScanner>();
  const [video, setVideo] = useState<HTMLVideoElement>();
  const [scannerScale, setScannerScale] = useState('1');

  const camQrResult = document.getElementById('cam-qr-result');
  // const camQrResultTimestamp = document.getElementById('cam-qr-result-timestamp');
  const fileSelector = document.getElementById('file-selector') as HTMLInputElement;
  const fileQrResult = document.getElementById('file-qr-result');

  const submit = async (code: string) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const result = await scanTicket(code);

      navigate.push(`/${i18n.language}/scan-preview`, {
        state: {
          scan_ticket_status: result?.status,
          ticket_info: result?.ticket_info || null,
        }
      });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setQrCodeError(err.message);
    }
  };


  const setResult = async (label, scannerResult) => { //= submit
    if (!scannerResult.data) return;
    console.log(scannerResult.data); // = barcode
    // handle scanner result
    await submit(scannerResult.data);
  }

  // ####### Web Cam Scanning #######
  useEffect(() => {
    setVideo(document.getElementById('qr-video') as HTMLVideoElement);
  }, [])


  useEffect(() => {
    setScannerScale(isMobile ? '4' : '1');
  }, [isMobile])

  useEffect(() => {
    video && setScanner(new QrScanner(
      video,
      result => setResult(camQrResult, result), {
      onDecodeError: error => {
        if (!(error == 'No QR code found' || 'Scanner error: No QR code found')) {
          console.log(error);
        };
      },
      highlightScanRegion: true,
      highlightCodeOutline: true,
    },
    ));
  }, [video])

  useEffect(() => {
    console.log('scanner useEffect', scanner)
    // check if camera is found
    QrScanner.listCameras()
      .then(cameras => {
        if (!cameras || cameras.length === 0) return;
      })
      .catch(err => console.error(err.message));

    if (scanner) {
      scanner?.start().then(() => {
        // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
        // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
        // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
        // start the scanner earlier.
        QrScanner.listCameras(true).then(cameras => cameras.forEach(camera => {
          const option = document.createElement('option');
          option.value = camera.id;
          option.text = camera.label;
        }));
      });

      document.getElementById('start-button').addEventListener('click', () => {
        scanner?.start();
        document.getElementById('video-container').classList.add('opacity-visible');
        setIsScanning(true);
        setTimeout(() => {
          setShowUploadPhotoReminder(true);
        }, 5000);
      });

      document.getElementById('stop-button').addEventListener('click', () => {
        scanner?.stop();
        setIsScanning(false);
        setShowUploadPhotoReminder(false);
      });
    }

    // // ####### File Scanning #######
    if (!fileSelector) return;
    fileSelector.addEventListener('change', event => {
      const file = fileSelector.files[0];
      if (!file) {
        return;
      }
      QrScanner.scanImage(file, { returnDetailedScanResult: true })
        .then(result => setResult(fileQrResult, result))
        .catch(e => setResult(fileQrResult, { data: e || 'No QR code found.' }));
    });
  }, [scanner])


  return (
    <>
      <div className='qr-code-scanner-container'>

        {!isScanning && <div className="wrapper">
          <p className="mt-5"
          >
            {t('scan_your_ticket.upload_photo_hint')}
          </p>
        </div>
        }
        {isScanning &&
          <>
            <div className="wrapper">
              <div className="scanner-hint">
                {t('scan_your_ticket.scanner_hint')}
              </div>
              <div className="scanner-hint">
                <br />
                <br />
                {showUploadPhotoReminder && t('scan_your_ticket.upload_photo_reminder')}
              </div>
            </div>
          </>
        }
        <div id="video-container"
          style={{
            height: '500px',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            opacity: 0
          }}>
          <video id="qr-video"
            style={{
              width: '100%',
              scale: scannerScale,
            }}
          ></video>
        </div>
        <span id="cam-has-camera"></span>

        <div
          style={{ display: isScanning ? 'none' : 'block' }}
        >
          {/* scan by camera */}
          <button id="start-button" className='btn'>{t('scan_your_ticket.start_scanning')}</button>
          <br />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <button id="stop-button" className='btn'
            style={{ display: isScanning ? 'block' : 'none' }}
          >
            {t('scan_your_ticket.stop_scanning')}
          </button>
        </div>
        {/* Upload file */}
        <div>
          <label htmlFor={"file-selector"} className="btn">
            {t('scan_your_ticket.upload_photo')}
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-upload ms-2">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"></path>
            </svg>
          </label>
          <input type="file" id="file-selector" className='btn' style={{ visibility: 'hidden' }} />
        </div>
      </div>
    </>
  )
};

const mapStateToProps = state => ({
  profile: state.shared.profile,
  scan_required_login: state.shared.scan_required_login
});

export default connect(mapStateToProps)(QrCodeScanner);
