import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const Footer = () => {
    const { t, i18n } = useTranslation();

    const [disclaimerRedirect, setDisclaimerRedirect] = useState(String);
    const [privacyPolicyRedirect, setPrivacyPolicyRedirect] = useState(String);
    const [mainWebsiteRedirect, setMainWebsiteRedirect] = useState(String);

    useEffect(() => {
        if (window.location.href.split('/')[3] == 'tc') {
            i18n.changeLanguage("tc");
            setDisclaimerRedirect('https://www.hkphil.org/tc/disclaimer');
            setPrivacyPolicyRedirect('https://www.hkphil.org/tc/privacy-policy');
            setMainWebsiteRedirect('https://www.hkphil.org/tc ');
        } else if (window.location.href.split('/')[3] == 'sc') {
            i18n.changeLanguage("sc");
            setDisclaimerRedirect('https://www.hkphil.org/sc/disclaimer');
            setPrivacyPolicyRedirect('https://www.hkphil.org/sc/privacy-policy');
            setMainWebsiteRedirect('https://www.hkphil.org/sc ');
        } else {
            i18n.changeLanguage("en");
            setDisclaimerRedirect('https://www.hkphil.org/disclaimer');
            setPrivacyPolicyRedirect('https://www.hkphil.org/privacy-policy');
            setMainWebsiteRedirect('https://www.hkphil.org ');
        }
    }, [i18n.language])

    return (
        <>
            <footer>
                <div className="container d-sm-flex footer-container">
                        {/* <div className="col-12 col-sm-2  d-flex align-items-center justify-content-center"> */}
                        <div className="footer-swire d-flex align-items-center justify-content-center">
                            <a className="d-block" href="https://www.swirepacific.com/en/global/home.php">
                                <img className="logo-swire mt-3 mb-4" src="/assets/img/Swire_Footer_Logo1x.png" />
                            </a>
                        </div>
                    <div className="row">
                    {/* <div className="row col-sm-8"> */}
                        {/* <div className="col-12 d-sm-none d-flex align-items-center justify-content-center">
                            <div className="col-sm-12 copyright">
                                <p>{t('footer.swire')}</p>
                            </div>
                        </div> */}

                        {/* <div className="col-sm-12 bottomMenu"> */}
                        <div className="bottomMenu">
                            <ul className="nav bottomMenu"
                              style={{
                                flexWrap: 'nowrap',
                            }}>
                                <li className="nav-item">
                                    <Link className="nav-link" to={`/${i18n.language}/terms-and-conditions`} >{t('footer.terms_and_conditions')}</Link>
                                </li>
                                <li className="nav-item"><a className="nav-link" href={disclaimerRedirect}>{t('footer.disclaimer')}</a></li>
                                <li className="nav-item"><a className="nav-link" href={privacyPolicyRedirect}>{t('footer.privacy_policy')}</a></li>
                                <li className="nav-item"><a className="nav-link" href={mainWebsiteRedirect}>{t('footer.hk_phil_main_website')}</a></li>
                            </ul>
                        </div>
                        <div className="copyright">
                        {/* <div className="col-sm-12 copyright"> */}
                            <p>{t('footer.copyright', { year: moment().year() })}</p>
                        </div>
                        {i18n.language === 'en' && <div className="col-sm-12 copyright2">
                            <p>{t('footer.supports')}</p>
                        </div>}
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer