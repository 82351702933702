import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MembershipTier } from '../constants';

const RegistrationSuccess = ( {welcome_black, welcome_red, welcome_gold} ) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [userTier, setUserTier] = useState(MembershipTier.RED);

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.registration_success')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.registration_success')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    if (location.state) {
      setUserTier(location.state.state.tier);
    }
  }, [location]);

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-sm-12 contents m-auto p-4 pb-5">
            <div className="inner-html-container d-flex col-sm-12 flex-wrap justify-content-center">
              <div>
                { 
                  userTier === MembershipTier.BLACK &&
                  welcome_black?.map((item, i) => (
                    <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                  ))
                }
                { 
                  userTier === MembershipTier.RED &&
                  welcome_red?.map((item, i) => (
                    <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                  ))
                }
                { 
                  userTier === MembershipTier.GOLD &&
                  welcome_gold?.map((item, i) => (
                    <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                  ))
                }
              </div>
              <div className="text-center d-flex align-items-center flex-wrap mt-5">
                <Link style={{ textDecoration: "none" }} to={`/${i18n.language}/scan-intro`}>
                  <button type="button" className="classic-button me-3">
                      {t('registration_success.scan_ticket')}
                  </button>
                </Link>
                <Link style={{ textDecoration: "none" }} to={`/${i18n.language}/reward-list`}>
                  <button type="button" className="classic-button">
                      {t('registration_success.view_rewards')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = state => ({
  welcome_black: state.shared.welcome_black,
  welcome_red: state.shared.welcome_red,
  welcome_gold: state.shared.welcome_gold,
});
export default connect(mapStateToProps)(RegistrationSuccess);