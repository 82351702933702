import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const RegistrationContainer = ({
  children,
  registrationFormBenefit,

  steps = null,
  step = 0,
  showHint = true,
  useStraightForm = false,
  rightPaneTitle = null,
  rightPaneContent = null,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <section className="mainContents">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-12 contents registationForm">
            <div className={`formLeft ${useStraightForm ? 'edit-form col-11' : 'col-sm-8 col-12'}`}>
              {children}
            </div>

            {(showHint && !useStraightForm) ? (
              <div className="col-12 col-sm-4 formRight">
                <div className="part1 col-12">
                  <p>{t('landing_page.please_fill_out')}</p>
                </div>
                <div className="part2 col-12 align-self-end curStep">
                  {(registrationFormBenefit || [])
                    .filter((item, i) => (item.seq - 1) === step)
                    .map((item, i) => (
                      <div
                        className='con'
                        key={i}
                        dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}
                      ></div>
                    ))}
                </div>
              </div>
            ) : !useStraightForm && ( 
              <div className="col-12 col-sm-4 formRight">
                <div className="col-12 align-self-end">
                  <h2>{rightPaneTitle || t('registration_common.migrate_description_title')}</h2>
                  <p>{rightPaneContent || t('registration_common.migrate_description_content')}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
const mapStateToProps = state => ({
  registrationFormBenefit: state.shared.registration_form_benefit,
});

export default connect(mapStateToProps)(RegistrationContainer);