import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Html5QrCodeScanner from '../components/Html5QrCodeScanner';
import LoginButton from '../components/LoginButton';
import QrCodeScanner from '../components/QrCodeScanner';
import VerifyEmail from '../components/VerifyEmail';

const Scanner = ({ profile, scan_required_login }) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth0();

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.scanner')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.scanner')+' | '+  t('html_title.home');
  }, [i18n.language]);

  return (
    <>
      {
        (profile && profile.email_verified)
        ?
        <section className="mainContents mainContents2">
          <div className="container conWrapper">
            <div className="row">
              <div className="col-sm-6 contents m-auto">
                {/* <Html5QrCodeScanner /> */}
                <QrCodeScanner />
              </div>
            </div>
          </div>
        </section>
        :
        (profile && !profile.email_verified)
        ?
        (<>

          {!user?.email_verified && (
            <VerifyEmail email={user?.email} />
          )}
        </>)
        :
        (<>
        <section className="mainContents">
          <div className="container conWrapper">
            <div className="row">
              <div className="col-sm-6 contents m-auto">
                <div className="loginBox d-flex col-12">
                  <form className="loginForm d-flex col-12 flex-wrap">
                    <div className="step1 btnLogin col-12">
                      {
                        scan_required_login.map((item, i) => (
                          <h5 key={i} className="title2 text-center">{item.name[i18n.language]}</h5>
                        ))}
                        {
                          scan_required_login.map((item, i) => (
                            <div
                              className='con mt-3'
                              key={i}
                              dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}
                            ></div>
                          ))}
                        <br />
                      <div className="col-12 formCol d-flex flex-wrap mt-5">
                        <LoginButton
                          fontStyle={'white'}
                          loginBtnText={'user.login'}
                        />
                        {profile && profile?.email_verified !== true && <p className="mt-2 text-danger text-center w-100">{t('scan_your_ticket.scan_hint')}</p>}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>)
    }
    </>
  )
};

const mapStateToProps = state => ({
  profile: state.shared.profile,
  scan_required_login: state.shared.scan_required_login
});

export default connect(mapStateToProps)(Scanner);
