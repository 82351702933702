import {makeApiCalls} from "../store/api";

export function scanTicket(ticketQrcode) {
  console.log('ticketData', ticketQrcode)
    return makeApiCalls(
      [
        {
          endpoint: 'SCAN_TICKET',
          data: {
            "qrcode": ticketQrcode
          }
        }
      ],
      ).then((responses) => {
        return responses[0]?.data;
      })
  }

export function confirmAndVerifyScanTicket(ticket_info) {
  console.log(' confirmScanTicket ticketData', ticket_info)
    return makeApiCalls(
      [
        {
          endpoint: 'VERIFY_SCAN_TICKET',
          data: {
            "ticket_info": ticket_info
          }
        }
      ],
      ).then((responses) => {
        return responses[0]?.data;
      })
  }

