import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";

const TermsAndConditions = ({ tnc }) => {
  const { t, i18n } = useTranslation();

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.terms_and_conditions')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.terms_and_conditions')+' | '+  t('html_title.home');
  }, [i18n.language]);

  return (
    <section className="mainContents">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-12 contents p-4" dangerouslySetInnerHTML={{ __html: tnc?.content[i18n.language] }}>
          </div>
        </div>
      </div>
    </section>
  )
};

const mapStateToProps = state => ({
  tnc: state.shared.tnc,
});

export default connect(mapStateToProps)(TermsAndConditions);