import { useTranslation } from "react-i18next";
import { genderOptions, monthOptions, salutationOptions, yearOptions } from "../../services/helpers";
import FormLabel from "./FormLabel";
import {MembershipTier} from "../../constants";
import {useEffect, useState} from "react";

const About = ({ formData, handleFormData, active = true, requiredFields = null, isYAForm = false }) => {
  const { t } = useTranslation();
  const [isMonthInitiallyFilled, setIsMonthInitiallyFilled] = useState(false);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const chineseChar = /[\u4E00-\u9FA5\uF900-\uFA2D]/;

  useEffect(() => {
    if (formHasChanged) {
        return;
    }
    else {
        if (formData.month) {
            setIsMonthInitiallyFilled(true);
        }
    }
  }, [formData.month]);

  return (
    <div id="formPart1" className={`formSteps step1 col-12 flex-wrap ${active ? 'curStep' : ''}`}>
      <div className="formCol col-12">
        <FormLabel
          title={t('landing_page.salutations')}
          required={requiredFields?.salutations}
        />
        <select
          className="form-select formInput"
          name='salutations'
          value={formData?.salutations || ''}
          onChange={handleFormData}
        >
          <option value="" disabled>--</option>
          {salutationOptions.map(v => (
            <option key={v.value} value={v.value}>{t(v.name)}</option>
          ))}
        </select>
      </div>
      <div className="formCol col-12 col-sm-7">
        <FormLabel
          title={t('landing_page.first_name')}
          required={requiredFields?.first_name}
        />
          <input
              className="form-control formInput"
              name="first_name"
              type="text"
              onChange={e => {
                    if (!chineseChar.test(e.target.value)) {
                        handleFormData(e);
                    }
              }}
              value={formData?.first_name || ''}
              placeholder="e.g. Tai Man"
          />

      </div>
      <div className="formCol col-12 col-sm-5 formLP-20">
        <FormLabel
          title={t('landing_page.last_name')}
          required={requiredFields?.last_name}
        />
        <input
          className="form-control formInput"
          name="last_name"
          type="text"
          onChange={e => {
              if (!chineseChar.test(e.target.value)) {
                  handleFormData(e);
              }
          }}
          value={formData?.last_name || ''}
          placeholder="e.g. Chan"
        />
      </div>
      <div className="formCol col-12 col-sm-7">
        <FormLabel
          title={t('landing_page.chinese_name')}
          required={requiredFields?.chinese_name}
        />
        <input
          className="form-control formInput"
          name="chinese_name"
          type="text"
          onChange={handleFormData}
          value={formData?.chinese_name || ''}
        />
      </div>
        <div className="formCol col-12 col-sm-7 dateInput">
            <FormLabel
                title={t('landing_page.date_of_birth')}
                required={requiredFields?.date_of_birth}
            />
            <div className="d-flex align-items-center">
                <select
                    className="form-select formInput"
                    name='month'
                    value={formData?.month || ''}
                    onChange={(e) => {
                        handleFormData(e);
                        setFormHasChanged(true);
                    }}
                    disabled={isMonthInitiallyFilled}
                >
                    <option value="" disabled>--</option>
                    {
                        monthOptions.map(v => (
                            <option key={v} value={v}>{t(`month.${v}`)}</option>
                        ))
                    }
                </select>
                <select
                    className="form-select formInput"
                    name='year'
                    value={formData?.year || ''}
                    onChange={(e) => {
                        handleFormData(e);
                        setFormHasChanged(true);
                    }}

                    disabled={isMonthInitiallyFilled}
                >
                    <option value="" disabled>--</option>
                    {
                        yearOptions.map(v => (
                            <option key={v} value={v}>{v}</option>
                        ))
                    }
                </select>
            </div>
        </div>

      <div className="formCol col-12">
        <FormLabel
          title={t('landing_page.gender')}
          required={requiredFields?.gender}
        />
        <div className="d-flex">
          {genderOptions.map((item, i) => (
            <div className="form-check" key={i}>
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                value={item.value}
                checked={item.value === formData?.gender}
                onChange={handleFormData}
              />
              <label className="form-check-label">{t(item.name)}</label>
            </div>
          ))}
        </div>
      </div>

        { isYAForm && (
            <>
                <div className="formCol col-12">
                    <FormLabel
                        title={t('landing_page.guardian_name')}
                        required={true}
                    />
                    <input
                        className="form-control formInput"
                        name="guardian_name"
                        type="text"
                        onChange={handleFormData}
                        value={formData?.guardian_name || ''}
                    />
                </div>
                <div className="formCol col-12">
                    <FormLabel
                        title={t('landing_page.guardian_email')}
                        required={true}
                    />
                    <input
                        className="form-control formInput"
                        name="guardian_email"
                        type="text"
                        onChange={handleFormData}
                        value={formData?.guardian_email || ''}
                    />
                </div>
                <div className="formCol col-12">
                    <FormLabel
                        title={t('landing_page.guardian_mobile')}
                        required={true}
                    />
                    <input
                        className="form-control formInput"
                        name="guardian_mobile"
                        type="text"
                        onChange={handleFormData}
                        value={formData?.guardian_mobile || ''}
                    />
                </div>
            </>
        )}
    </div>
  );
};

export default About;
