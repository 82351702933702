import { useTranslation } from "react-i18next";

// ref: https://www.paydollar.com/pdf/op/enpdintguide.pdf
const AsiaPayForm = ({ orderRef, amount, payType, merchantId, lang, secureHash, isLive }) => {
    const {  i18n } = useTranslation();
    let remark = 'Club Bravo';

    return (
        <form name="payForm" method="post" action={isLive ? "https://www.paydollar.com/b2c2/eng/payment/payForm.jsp" : "https://test.paydollar.com/b2cDemo/eng/payment/payForm.jsp"} className="d-none">
            <input type="hidden" name="amount" value={amount} />
            <input type="hidden" name="orderRef" value={orderRef} />
            <input type="hidden" name="payType" value={payType} />
            <input type="hidden" name="merchantId" value={merchantId} />
            <input type="hidden" name="currCode" value="344" />  {/* 344 = use HKD */}
            <input type="hidden" name="successUrl" value={`${window.location.origin}/${i18n.language}/registration-success`} />
            <input type="hidden" name="failUrl" value={`${window.location.origin}/${i18n.language}/asia-pay-fail`} />
            <input type="hidden" name="errorUrl" value={`${window.location.origin}/${i18n.language}/asia-pay-error`} />

            <input type="hidden" name="remark" value={remark} />
            <input type="hidden" name="lang" value={lang} />
            <input type="hidden" name="secureHash" value={secureHash} />
            <input id="pay-btn" type="submit" value="Pay Now" />
        </form>
    );
}

export default AsiaPayForm;