import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import PointTransactionTable from '../components/PointTransactionTable';
import WelcomeBackBar from '../components/WelcomeBackBar';
import { Link } from "react-router-dom";
import { useEffect } from 'react';

const PointTransactionLog = ({ profile }) => {
  const { t, i18n } = useTranslation();

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.point_transaction_log')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.point_transaction_log')+' | '+  t('html_title.home');
  }, [i18n.language]);

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-12 contents contents2">
            <div className="col-12 d-flex flex-wrap detailCons">
              <WelcomeBackBar showAvailablePoints={false} />
              <div className="details d-flex col-12 flex-wrap">
                <div className="d-flex justify-content-between col-12 flex-wrap mb-3 mb-sm-0">
                  <h4 className="col-12 col-sm-6"><strong>
                    {t('point_transaction_log.point_transaction_log')}
                  </strong></h4>
                </div>
                <div className="col-12 mb-4">
                  <PointTransactionTable transactions={profile?.point_transactions} />
                </div>
              </div>
              <Link className="col-12 col-sm-4" to={`/${i18n.language}/member-detail`}>
                  <button className="btn btn-outline" type="button">
                    {t('landing_page.back_to_member_detail')}
                  </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  profile: state.shared.profile
});

export default connect(mapStateToProps)(PointTransactionLog);