import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoginButton from '../components/LoginButton';
import TitleBar from '../components/TitleBar';
import VerifyEmail from '../components/VerifyEmail';
import { getJwtToken } from '../services/user';

const ScanIntro = ({ profile, scan_tutorial }) => {
    const { t, i18n } = useTranslation();

    //set html_title
    useEffect(() => { 
        document.title = t('html_title.scan_intro')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => { 
        document.title = t('html_title.scan_intro')+' | '+  t('html_title.home');
    }, [i18n.language]);

    return (
        <section className="mainContents mainContents2">
            <TitleBar
                title={t('header.scan_your_ticket')}
            />
            <div className="container conWrapper">
                <div className="row">
                    <div className="col-12 col-sm-10 contents contents2 m-auto">
                        <div className="d-flex col-12 scanTicket">
                            <div className="col-12">
                                {scan_tutorial?.filter((item) => { return item.seq == 0 }).map((item, i) => (
                                    <div key={i}>
                                        <h3 className="text-uppercase text-center redText fw-bold mb-5 mt-5"> {item.name[i18n.language]}</h3>
                                        <div className="col-12 d-flex flex-wrap">
                                            <div className="col-12 col-sm-6 text-center">
                                                <img className="img-fluid" src={item?.icon && item?.icon[i18n.language]} />
                                            </div>
                                            <div className={`col-12 ${item?.icon ? 'col-sm-6' : 'col-sm-12'} text-center`}>
                                                {
                                                    scan_tutorial?.filter((item) => { return item.seq == 0 }).map((item, i) => (
                                                        <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                                                    ))
                                                }
                                                {
                                                    !profile ?
                                                        <>
                                                            <div className="row justify-content-center">
                                                                <div className="col-12 col-sm-5">
                                                                    <LoginButton
                                                                        fontStyle={'white'}
                                                                        loginBtnText={t('user.login_to_continue')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        : (
                                                            <>
                                                                <div className="row justify-content-center">
                                                                    {profile?.email_verified === true && (
                                                                        <>
                                                                            <div className="col-12 col-sm-5">
                                                                                <Link className="btn" to={`/${i18n.language}/scanner`}>
                                                                                    {t('scan_tutorial.start_now')}
                                                                                </Link>
                                                                                {/* <Link className="btn" to={`/${i18n.language}/scanner-test`}>
                                                                                Test Scanner
                                                                                </Link> */}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {profile?.email_verified !== true && (
                                                                        <div className="col-12">
                                                                            <VerifyEmail
                                                                                email={profile?.email}
                                                                                withoutTemplate={true}
                                                                                message={t('rewards.please_verify_email')}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="mt-5 d-flex flex-wrap">
                                    <h4 className="col-12 text-uppercase text-center redText fw-bold mb-5">{t('scan_tutorial.tips_and_steps')}</h4>
                                    {scan_tutorial?.filter((item) => { return item.seq > 0 }).map((item, i) => (
                                        <div key={i} className="col-12 col-sm-6 text-center">
                                            <img className="img-fluid" src={item?.icon[i18n.language] || "/assets/img/scan_2.png"} />
                                            <h4 className="redText my-4">{item.name[i18n.language]}</h4>
                                            <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blankSpace"></div>
        </section>
    )
};

const mapStateToProps = state => ({
    profile: state.shared.profile,
    scan_tutorial: state.shared.scan_tutorial,
});

export default connect(mapStateToProps)(ScanIntro);
