import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { RewardType } from '../constants';

const RedeemFail = ({ asia_miles_fail, point_insufficient, stock_insufficient }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [redeemDetail, setRedeemDetail] = useState(null);
  const [rewardType, setRewardType] = useState(null);
  const navigate = useHistory();

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.redeem_fail')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.redeem_fail')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    if (location && location.state){
      setRedeemDetail(location.state?.state.redeem_res);
      setRewardType(location.state?.state.type);
    } else {
      navigate.push(`/`)
    }
  }, [location]);

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-sm-6 contents m-auto">
            <div className="resultBox d-flex col-12 flex-wrap">
              <div>

                {rewardType == RewardType.ASIA_MILES &&
                  <>
                    {
                      redeemDetail.message == 'no enough available points for this redeem request' ?
                        (asia_miles_fail || []).map((item, i) => (
                          <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                        ))
                        :
                        <p className="mt-3">{redeemDetail.error}<br /></p>
                    }
                  </>
                }
                {rewardType == RewardType.ITEM &&
                  <>
                    {
                      redeemDetail.message == 'no enough reward item for this redeem request'
                        ? (stock_insufficient || []).map((item, i) => (
                          <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                        ))
                        : (
                          redeemDetail.message == 'no enough available points for this redeem request'
                            ? (point_insufficient || []).map((item, i) => (
                              <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                            ))
                            : <p className="mt-3">{redeemDetail.error || ''}<br /></p>
                        )
                    }
                  </>
                }
                <Link className="btn" to={`/${i18n.language}/reward-list`}>
                  <b>{t('rewards.back_to_reward_list')}</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  asia_miles_fail: state.shared.asia_miles_fail,
  point_insufficient: state.shared.point_insufficient,
  stock_insufficient: state.shared.stock_insufficient,
});
export default connect(mapStateToProps)(RedeemFail);
