import { useTranslation } from "react-i18next";
import { Position } from "../services/enums";

const StaticContentDisplayTopOrBottomContent = ({ 
  staticContentName,
  contentPosition
}) => {
  const { t, i18n } = useTranslation();


  return (
    <>
      {staticContentName.filter(item => {
        return contentPosition == Position.top ? 
        (item.seq < 101) // top
        :
        (item.seq > 100) // bottom
      }).map((item, i) => (
          <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
      ))}
    </>
  );
}

export default StaticContentDisplayTopOrBottomContent;