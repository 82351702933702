import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Popup from '../components/Popup';
import WelcomeBackBar from '../components/WelcomeBackBar';
import { redeemAsiaMilesFromWebsite } from '../services/user';
import VerifyEmail from '../components/VerifyEmail';
import { useAuth0 } from '@auth0/auth0-react';
import { RewardType } from '../constants';

const Asiamiles = ({ profile, asiamile }) => {
  const { user } = useAuth0();
  const navigate = useHistory();
  const { t, i18n } = useTranslation();

  const [availablePoints, setAvailablePoints] = useState(profile?.available_points);
  const [pointsToConvert, setPointsToConvert] = useState(Number);
  const [asiaMilesToBeConverted, setAsiaMilesToBeConverted] = useState(Number);
  const [afterConversionPoints, setAfterConversionPoints] = useState(Number);
  const [asiaMilesFirstName, setAsiaMilesFirstName] = useState(String);
  const [asiaMilesLastName, setAsiaMilesLastName] = useState(String);
  const [asiaMilesNo, setAsiaMilesNo] = useState(String);
  const [conversionRate, setConversionRate] = useState(4);
  const [conversionOptionLeft, setConversionOptionLeft] = useState(1000);
  const [conversionOptionMiddle, setConversionOptionMiddle] = useState(2000);
  const [conversionOptionRight, setConversionOptionRight] = useState(3000);
  const [confirmAsiaMilesNoIsCorrect, setConfirmAsiaMilesNoIsCorrect] = useState(false);
  const [understandConversionIsNonReversible, setUnderstandConversionIsNonReversible] = useState(false);
  const [agreeTermsAndConditions, setAgreeTermsAndConditions] = useState(false);
  const [disableRedeemNowBtn, setDisableRedeemNowBtn] = useState(false);

  // TODO check the below 3 useState can removed or not
  const [showPointsToConvertRemind, setShowPointsToConvertRemind] = useState(false);
  const [showCathayMemberNameRemind, setShowCathayMemberNameRemind] = useState(false);
  const [showCathayMembershipNumberRemind, setShowCathayMembershipNumberRemind] = useState(false);

  const [validationError, setValidationError] = useState(null);

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.asiamiles')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.asiamiles')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => { //init
    setAfterConversionPoints(Math.max(0, availablePoints));
  }, []);

  useEffect(() => {
    if (!asiamile) return;
    setConversionRate(asiamile.rate);
    setConversionOptionLeft(asiamile.options[0]);
    setConversionOptionMiddle(asiamile.options[1]);
    setConversionOptionRight(asiamile.options[2]);
  }, [asiamile]);

  useEffect(() => {
    setAsiaMilesToBeConverted(pointsToConvert / conversionRate);
    setAfterConversionPoints(Math.max(0, Number(availablePoints) - pointsToConvert));
  }, [pointsToConvert])

  const redeemAsiaMiles = () => {
    if (pointsToConvert % conversionRate != 0) {
      setValidationError(t('error.invalid_point_conversion', { conversion: conversionRate }));
      return;
    }

    if (!pointsToConvert) {
      setValidationError(t('error.required', { field: t('offer_convert_to_miles.points_to_convert') }));
      return;
    }

    if (pointsToConvert < conversionOptionLeft) {
      setValidationError(t('error.min_points_not_reached', { min_points: asiamile.options[0] }));
      return;
    }

    if (!asiaMilesFirstName) {
      setValidationError(t('error.required', { field: t('offer_convert_to_miles.your_asia_miles_member_first_name') }));
      return;
    }
    if (!asiaMilesLastName) {
      setValidationError(t('error.required', { field: t('offer_convert_to_miles.your_asia_miles_member_last_name') }));
      return;
    }

    if (!asiaMilesNo) {
      setValidationError(t('error.required', { field: t('offer_convert_to_miles.your_asia_miles_membership_number') }));
      return;
    }

    if (!confirmAsiaMilesNoIsCorrect || !understandConversionIsNonReversible || !agreeTermsAndConditions) {
      setValidationError(t('error.missing_tnc'));
      return;
    }

    if (Number(pointsToConvert) <= 0 || asiaMilesToBeConverted <= 0) {
      setValidationError(t('error.invalid_asia_miles'));
      return;
    }

    redeemAsiaMilesFromWebsite(
      pointsToConvert,
      asiaMilesNo,
      asiaMilesFirstName,
      asiaMilesLastName,
    ).then((res) => {
      if (res.message == 'Asia Miles Redemption successfully') {
        navigate.push(`/${i18n.language}/redeem-success`, {
          state: {
            redeem_res: res,
            type: RewardType.ASIA_MILES
          }
        });
      } else {
        navigate.push(`/${i18n.language}/redeem-fail`, {
          state: {
            redeem_res: res,
            type: RewardType.ASIA_MILES
          }
        });
      }
    });
    setDisableRedeemNowBtn(true);
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.Clipboard;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  return (
    <>
      {user?.email_verified && (
        <section className="mainContents mainContents2">
          {validationError && <Popup
            title={''} // temp removed error.validation_error
            message={validationError}
            buttons={[
              {
                label: t('button.close'),
                callback: () => {
                  setValidationError(null);
                }
              }
            ]}
          />}
          <div className="container conWrapper conWrapper2">
            <div className="row">
              <div className="col-12 contents contents2">
                <div className="redeemCons">
                  <WelcomeBackBar showAvailablePoints={false} />
                </div>
              </div>
            </div>
          </div>
          <div className="container conWrapper conWrapper2">
            <div className="row">
              <div className="col-12 contents contents2">
                <div className="redeemCons redeemCons2">
                  <div className="con2 mt30">
                    <h5 className="title2 redText mb-0">
                      {t('about_club_bravo_tier.available_point')}: 
                      <span className='cb-logo-red ms-2 d-sm-inline-block'>b</span> 
                      {availablePoints?.toLocaleString()}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container conWrapper">
            <div className="row">
              <div className="col-12 contents contents2">
                <div className="convertAsiamiles">
                  <div className="d-flex col-12 con1 flex-wrap">
                    <div className="col-12 mb-5 mt-4">
                      <div className="col-12 col-sm-6 mb-3 logo"><img className="img-fluid" src="/assets/img/Cathay_EN_Horizontal_Colour.png" /></div>
                      <div className="col-12 text-center text-sm-start">
                        <p className="m-0">
                          <span className='' dangerouslySetInnerHTML={{ __html: t('offer_convert_to_miles.asia_miles_intro', { rate: conversionRate }) }}></span>
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="col-12 col-sm-6 mb-2">
                        <p className="mb-0">{t('offer_convert_to_miles.points_to_convert')}*</p>
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          id="point_to_convert"
                          name="point_to_convert"
                          className="form-control formInput"
                          type="number"
                          min="0"
                          onPaste={preventPasteNegative}
                          onKeyPress={preventMinus}
                          onChange={event => {
                            console.log(`Point to convert: ${event.target.value}`)
                            setPointsToConvert(parseInt(event.target.value));
                          }
                          }
                          value={pointsToConvert}
                        />
                        {
                          (showPointsToConvertRemind) &&
                          <p className="validRequiredTxt">{t('registration_message.required_field')}</p>
                        }
                        <p className="mt-1 rateTxt">
                          <span className='' dangerouslySetInnerHTML={{ __html: t('offer_convert_to_miles.conversion_rate', { rate: conversionRate }) }}></span>
                        </p>
                          <br />
                        <p className="mt-1 rateTxt">{t('offer_convert_to_miles.suggest_points_to_convert')}:</p><br />
                        <div className="col-12 mb-4 d-flex flex-wrap flex-sm-nowrap btns top-up-buttons">
                          {(asiamile?.options || []).map((points, i) => (
                            <button
                              className="btn"
                              type="button"
                              key={i}
                              onClick={() => setPointsToConvert(points)}
                            >
                              <span className='cb-logo-white'>b</span>
                              <span>{points?.toLocaleString()}</span>
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="con2 mb-5">
                    <div className="aisamilesPoints d-flex col-12 flex-wrap">
                      <div className="pointsCon col-12 col-sm-4">
                        <div className="cons col-12">
                          <div>
                            <p className="txt">{t('offer_convert_to_miles.asia_miles_to_be_converted')}<br /></p>
                            <p className="point">
                              <span className='asiamile-logo-red'>a</span>
                              {(asiaMilesToBeConverted || 0).toLocaleString()}
                              {t('offer_convert_to_miles.miles')}<br />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="pointsCon col-12 col-sm-4">
                        <div className="cons col-12">
                          <div>
                            <p className="txt">{t('offer_convert_to_miles.available_point_after_conversion')}<br /></p>
                            <p className="point">
                              <span className='cb-logo-red'>b</span>
                              {afterConversionPoints.toLocaleString()}<br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="con2 mb-5">
                    <div className="col-12 mb-4">
                      <div className="col-12 col-sm-6 mb-2">
                        <p className="mb-0">{t('offer_convert_to_miles.your_asia_miles_member_first_name')}</p>
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          id="asia_miles_first_name"
                          name="asia_miles_first_name"
                          className="form-control formInput"
                          type="text"
                          onChange={event => {
                            setAsiaMilesFirstName(event.target.value);
                          }
                          }
                          value={asiaMilesFirstName}
                        />
                        {
                          (showCathayMemberNameRemind) &&
                          <p className="validRequiredTxt">{t('registration_message.required_field')}</p>
                        }
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div className="col-12 col-sm-6 mb-2">
                        <p className="mb-0">{t('offer_convert_to_miles.your_asia_miles_member_last_name')}</p>
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          id="asia_miles_last_name"
                          name="asia_miles_last_name"
                          className="form-control formInput"
                          type="text"
                          onChange={event => {
                            setAsiaMilesLastName(event.target.value);
                          }
                          }
                          value={asiaMilesLastName}
                        />
                        {
                          (showCathayMemberNameRemind) &&
                          <p className="validRequiredTxt">{t('registration_message.required_field')}</p>
                        }
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div className="col-12 col-sm-6 mb-2">
                        <p className="mb-0">{t('offer_convert_to_miles.your_asia_miles_membership_number')}</p>
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          id="asia_miles_no"
                          name="asia_miles_no"
                          className="form-control formInput"
                          type="text"
                          onChange={event => {
                            setAsiaMilesNo(event.target.value);
                          }
                          }
                          value={asiaMilesNo}
                        />
                        {
                          (showCathayMembershipNumberRemind) &&
                          <p className="validRequiredTxt">{t('registration_message.required_field')}</p>
                        }
                      </div>
                    </div>
                    <div className="col-12 mb-5">
                      <div className="col-12 mb-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            name="asia_miles_no"
                            type="checkbox"
                            id="formCheck-1"
                            onChange={() => {
                              setConfirmAsiaMilesNoIsCorrect((state) => state = !state)
                            }}
                          />
                          <label className="form-check-label" htmlFor="formCheck-1">
                            {t('offer_convert_to_miles.confirm_asia_miles_account_correct')}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 mb-0">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="formCheck-2"
                            onChange={() => {
                              setUnderstandConversionIsNonReversible((state) => state = !state)
                            }}
                          />
                          <label className="form-check-label" htmlFor="formCheck-2">
                            {t('offer_convert_to_miles.understand_the_conversion_is_non_changeable_and_non_reversible')}
                            <br />
                          </label>
                        </div>
                      </div>
                      <div className="col-12 mb-0">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="formCheck-3"
                            onChange={() => {
                              setAgreeTermsAndConditions((state) => state = !state)
                            }}
                          />
                          <label className="form-check-label" htmlFor="formCheck-3">
                            {t('offer_convert_to_miles.i_agree_to_the')}
                            <Link to={`/${i18n.language}/terms-and-conditions`} >
                              {t('offer_convert_to_miles.term_and_condition')}
                            </Link>
                            <br />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4 d-flex flex-wrap btns">
                      <div className="col-12 col-sm-2 me-4 mb-3 mb-sm-0">
                        <button className="btn" type="button" onClick={redeemAsiaMiles} disabled={disableRedeemNowBtn}>{t('offer_convert_to_miles.redeem_now')}</button>
                      </div>
                      <Link className="col-12 col-sm-2" to={`/${i18n.language}/reward-list`}>
                        <button className="btn btn-outline" type="button">{t('offer_convert_to_miles.back_to_offers')}</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileOn blankSpace"></div>
        </section>
      )}

      {!user?.email_verified && (
        <VerifyEmail email={user?.email} />
      )}
    </>
  )
};

const mapStateToProps = state => ({
  profile: state.shared.profile,
  asiamile: state.shared.asiamile,
});

export default connect(mapStateToProps)(Asiamiles);