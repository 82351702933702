import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import './WhatsClubBravo.css';
import TitleBar from '../components/TitleBar';

const WhatsClubBravo = ({ whatIsClubBravo }) => {
    const navigate = useHistory();
    const [currentTab, setCurrentTab] = useState(null);
    const { t, i18n } = useTranslation();

    const updateSubmenuTop = () => {
        const submenu: HTMLDivElement = document.querySelector('#page-whats-cb .submenu');
        const header: HTMLDivElement = document.querySelector('.mainHeader');
        if (!submenu || !header) return;
        submenu.style.top = (header.clientHeight - 1) + 'px';
    };

    let isInit = false;
    const addListenerToDesc = () => {
        if (isInit) return;
        isInit = true;

        document.querySelectorAll('#page-whats-cb .content-container .content .cbCards:nth-of-type(2)')
            .forEach(elem => {
                elem.addEventListener('click', ({ target }) => {
                    const card = (target as HTMLDivElement).closest('.cbCards');
                    if (!card) return;
                    card.parentElement.querySelectorAll('.cbCards')
                        .forEach(card => card.classList.toggle('active'));
                });
            });
    };

    const onTabClicked = hash => {
        setCurrentTab(hash);
        navigate.push(`/${i18n.language}/whats-club-bravo#${hash}`);
    }

    useEffect(() => {
        if (!whatIsClubBravo || whatIsClubBravo.length === 0) return;

        updateSubmenuTop();
        addListenerToDesc();

        setCurrentTab(window.location.hash.replace('#', '') || whatIsClubBravo.at(0).hash);
    }, [whatIsClubBravo]);

    useEffect(() => {
        const addClassToSubmenu = () => {
            const submenu = document.querySelector('#page-whats-cb .submenu');
            const banner = document.querySelector('#page-whats-cb .banner');
            if (!submenu || !banner) return;

            if (window.scrollY >= banner.clientHeight) {
                submenu.classList.add('position-fixed');
                updateSubmenuTop();
            } else {
                submenu.classList.remove('position-fixed');
            }
        };
        // set html_title
        document.title = t('html_title.what_is_club_bravo')+' | '+  t('html_title.home') ;
    

        // subscribe to window scroll event
        window.addEventListener('scroll', addClassToSubmenu);

        return () => {
            // unsubscribe to window scroll event
            window.removeEventListener('scroll', addClassToSubmenu);
        }
    }, []);

    // set html_title
    useEffect(() => { 
        document.title = t('html_title.what_is_club_bravo')+' | '+  t('html_title.home');
    }, [i18n.language]);
    
    return (
        <section className="mainContents mainContents2">
            <div id="page-whats-cb">
                <TitleBar
                    title={t('header.what_is_club_bravo')}
                />
                <section className='submenu'>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <ul>
                                    {whatIsClubBravo?.map((item, i) => (
                                        <li
                                            key={i}
                                            className={currentTab === item.hash ? 'active' : ''}
                                            onClick={() => onTabClicked(item.hash)}
                                        >
                                            {item.name[i18n.language]}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='content-container'>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                {whatIsClubBravo?.map((item, i) => (
                                    <div
                                        key={i}
                                        className={`content ${currentTab === item.hash ? 'active' : ''}`}
                                        dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    )
};

const mapStateToProps = state => ({
    whatIsClubBravo: state.shared.what_is_club_bravo,
});

export default connect(mapStateToProps)(WhatsClubBravo);