import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { redeemItems, registerEvent } from "../services/redeem";
import { connect } from "react-redux";
import Popup from "./Popup";
import { Link } from "react-router-dom";
import { getAddress } from "../services/helpers";
import VerifyEmail from "./VerifyEmail";
import LoginButton from "./LoginButton";
import QuantityPanel from "./QuantityPanel";
import { DeliveryOption, MembershipTier, RewardType, YaStatus } from "../constants";
import { RewardStatus, EventStatus } from "../services/enums";


/**
 * This component is used for both reward items and events.
 * 
 * @returns 
 */
const RewardInfo = ({ 
    reward, 
    rewardType, 
    profile, 
    countries, 
    public_expired_message, 
    before_priority_message,
    start_priority_message 
}) => {
    const navigate = useHistory();
    const { t, i18n } = useTranslation();

    const [availableQuantity, setAvailableQuantity] = useState(0);
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState('');
    const [showConfirmRedeem, setShowConfirmRedeem] = useState(false);
    const [showConfirmRegister, setShowConfirmRegister] = useState(false);
    const [showTnc, setShowTnc] = useState(false);
    const [showRetryPopup, setShowRetryPopup] = useState(false);
    const [completedRequiredAddressElement, setCompletedRequiredAddressElement] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [isConsent, setIsConsent] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [rewardIsExpired, setRewardIsExpired] = useState(false);

    let currentHKDateTime = new Date().toLocaleString('en-US', {
        timeZone: 'Asia/Hong_Kong',
        hour12: false,
    }).split(',')
    let currentHKDate = currentHKDateTime[0].split('/')
    let month = (currentHKDate[1].length === 1 ? '0' + currentHKDate[1] : currentHKDate[1]) ;
    const currentDate = currentHKDate[2]+'-'+currentHKDate[0]+'-'+ month + currentHKDateTime[1] // yyyy-mm-dd hh:mm:ss


    const setSanitizedQuantity = (quantityRequested) => {
        let minQuantity :number;

        if (rewardType === RewardType.ITEM) {
            minQuantity = Math.min(
                quantityRequested,
                reward.each_member_max,
                availableQuantity
            )
        }
        else if (rewardType === RewardType.EVENT) {
            minQuantity = Math.min(
                quantityRequested,
                profile?.ya_status == YaStatus.YA_STATUS_APPROVED
                    ? reward.ya_individual_quota
                    : reward.normal_individual_quota,
            )
        }
        setQuantity(Math.max(1, minQuantity));
    };

    useEffect(() => {
        if (!reward) {
            return;
        }

        if (rewardType === RewardType.ITEM) {
            setAvailableQuantity(reward?.availableQuantityOfRewardItem);
            setSelectedDeliveryMethod(reward?.delivery_methods[0]);
        }

        // Check if reward is expired
        const currentDateTime = new Date(currentDate);
        const expiryDateTime = new Date(reward.expiry_date);
        if (currentDateTime > expiryDateTime) {
            setRewardIsExpired(true);
        }
    }, [reward]);

    useEffect(() => {
                if (rewardType === RewardType.ITEM && profile && profile?.shipping_address_1) {
            setCompletedRequiredAddressElement(
                profile?.shipping_address_1?.at(0)?.memberProfile['house_or_building_estate'] &&
                profile?.shipping_address_1?.at(0)?.memberProfile['road_or_street'] &&
                profile?.shipping_address_1?.at(0)?.memberProfile['district'] &&
                profile?.shipping_address_1?.at(0)?.memberProfile['country']
            );
        }
    }, [profile]);
    
    const BackButton = () => {
        return (
          <button className="btn btn-outline w-100" type="button">
            {t('rewards.back_to_rewards')}
          </button>
        );
      };

    const onConfirmRedeem = async () => {
        redeemItems(reward?.id, quantity, selectedDeliveryMethod).then((res) => {

            if (res.message == "redeem successfully") {

                // Successful redemption
                navigate.push(`/${i18n.language}/redeem-success`, {
                    state: {
                        redeem_res: res,
                        type: RewardType.ITEM
                    }
                })

            } else if (res.message == 'no enough reward item for this redeem request') {

                // Not enough quantity
                setAvailableQuantity(res.availableQuantityOfRewardItem);
                setShowRetryPopup(true);
                setShowConfirmRedeem(false);
                return;

            } else {

                // Has error
                if (res.error && typeof res.error !== 'string') {
                    // error is empty
                    if (Object.keys(res.error).length === 0) {
                        res.error = '';
                    }
                    // FIXME: get correct error, currently get the first key as error
                    else {
                        res.error = res.error[Object.keys(res.error)[0]];
                    }
                }

                navigate.push(`/${i18n.language}/redeem-fail`, {
                    state: {
                        redeem_res: res || null,
                        type: RewardType.ITEM
                    }
                })
            }
        });
    };

    const onConfirmRegister = async () => {
        registerEvent(reward?.id, quantity).then((res) => {
           // Has error
            if (res.error && typeof res.error !== 'string') {
                // error is empty
                if (Object.keys(res.error).length === 0) {
                    res.error = '';
                }
                // FIXME: get correct error, currently get the first key as error
                else {
                    res.error = res.error[Object.keys(res.error)[0]];
                }
            }

            // res.success
            navigate.push(`/${i18n.language}/registration-event-result`, {
                state: {
                    result: res || null,
                }
            })
        });
    };

    return (
        <>
            {/* Start of Popups  */}
            {showConfirmRedeem && (
                <Popup
                    title={t('rewards.popup_confirm_title')}
                    buttons={[
                        {
                            label: t('button.confirm'),
                            callback: () => {
                                setHasError(false);
                                if (reward.delivery_option == 'Mail' && !completedRequiredAddressElement) return
                                if (!isConsent) {
                                    setHasError(true);
                                    return
                                };
                                onConfirmRedeem();
                            }
                        },
                        {
                            label: t('button.cancel'),
                            className: 'disabled',
                            callback: () => setShowConfirmRedeem(false)
                        },
                    ]}
                >
                    <p>{t('rewards.popup_confirm_message')}</p>
                    <br />

                    <h5><b>{t('rewards.reward_name')}</b></h5>
                    <p>{reward?.name[i18n.language]}</p>
                    <br />
                    {
                        reward.delivery_methods &&
                        <>
                            <div className="formCol col-12">
                                <select
                                    className="form-select formInput"
                                    name='delivery_method'
                                    value={selectedDeliveryMethod || reward.delivery_methods[0]}
                                    onChange={(res) => {
                                        console.log(res.target.value);
                                        setSelectedDeliveryMethod(res.target.value)
                                    }}
                                >
                                    {reward.delivery_methods.map((method, i) => (
                                        <option key={i} value={method}>{method}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    }
                    {
                        // item.delivery_option == DeliveryOption.mail ?
                        selectedDeliveryMethod == DeliveryOption.MAIL ?
                            (
                                <>
                                    <div className="d-flex">
                                        <h5><b>{t('rewards.shipping_to')}</b></h5>
                                        <Link to={`/${i18n.language}/member-detail-edit?from=reward-detail`} className="ms-auto btn small-btn">
                                            {t('button.edit')}
                                        </Link>
                                    </div>
                                    <p>{completedRequiredAddressElement
                                        ? getAddress(profile?.shipping_address_1?.at(0)?.memberProfile, countries)
                                        : t('rewards.please_enter_address')
                                    }</p>
                                </>
                            ) :
                            // item.delivery_option == DeliveryOption.onSitePickUp ? (
                            selectedDeliveryMethod == DeliveryOption.ON_SITE_PICKUP ? (
                                <>
                                    <div className="d-flex">
                                        <h5><b>{t('rewards.delivery')}: {t('rewards.on_site_pick_up')}</b></h5>
                                    </div>
                                    <div className="d-flex">
                                        <span className='' dangerouslySetInnerHTML={{ __html: reward?.popup_content[i18n.language] }}></span>
                                    </div>
                                    <br />
                                </>
                            ) : selectedDeliveryMethod == DeliveryOption.EMAIL ? (
                                <>
                                    <div className="d-flex">
                                        <h5><b>{t('rewards.delivery')}: {t('rewards.email')}</b></h5>
                                    </div>
                                    <div className="d-flex">
                                        <span className='' dangerouslySetInnerHTML={{ __html: reward?.popup_content[i18n.language] }}></span>
                                    </div>
                                    <br />
                                </>
                            ) : (
                                // show nth 
                                <></>
                            )
                    }
                    <br />

                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="consent"
                            onChange={(e: any) => setIsConsent(e.target.checked)} />
                        <label className="form-check-label">
                            {t('rewards.agree_tnc')}
                        </label>
                    </div>
                    {hasError && (
                        <div className="text-danger">
                            <small>{t('rewards.please_accept_tnc')}</small>
                        </div>
                    )}
                </Popup>
            )
            }

            {showConfirmRegister && (
                <Popup
                    title={t('events.popup_confirm_title')}
                    buttons={[
                        {
                            label: t('button.confirm'),
                            callback: () => {
                                setHasError(false);
                                if (!isConsent) {
                                    setHasError(true);
                                    return
                                }
                                onConfirmRegister();
                            }
                        },
                        {
                            label: t('button.cancel'),
                            className: 'disabled',
                            callback: () => setShowConfirmRegister(false)
                        },
                    ]}
                >
                    <p>{t('events.popup_confirm_message')}</p>
                    <br />

                    <h5><b>{t('events.event_name')}</b></h5>
                    <p>{reward?.name[i18n.language]}</p>
                    <br />
                    {/*  EVENT no delivery dropdown */}
                    {
                        <>
                            <div className="d-flex">
                                <span className='' dangerouslySetInnerHTML={{ __html: reward?.popup_content[i18n.language] }}></span>
                            </div>
                            <br />
                        </>
                    }    

                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="consent"
                            onChange={(e: any) => setIsConsent(e.target.checked)} />
                        <label className="form-check-label">
                            {t('rewards.agree_tnc')}
                        </label>
                    </div>
                    {hasError && (
                        <div className="text-danger">
                            <small>{t('rewards.please_accept_tnc')}</small>
                        </div>
                    )}
                </Popup>
            )}

            {
                showRetryPopup && <Popup
                    title={availableQuantity > 0
                        ? t('rewards.popup_retry_title')
                        : t('rewards.sold_out_title')
                    }
                    message={availableQuantity > 0
                        ? t('rewards.popup_retry_message', { quantity: availableQuantity })
                        : t('rewards.sold_out_message')
                    }
                    buttons={[
                        {
                            label: t('button.close'),
                            className: 'disabled',
                            callback: () => setShowRetryPopup(false)
                        },
                    ]}
                />
            }
            {/* End of Popups  */}

            {/* Start of Reward Info  */}
            {
                reward && (
                    (reward.status == RewardStatus.ACTIVE && !rewardIsExpired && rewardType === RewardType.ITEM && (!reward.reward_visibility_tiers || reward.reward_visibility_tiers.includes(profile?.membership_tier)))
                    || (reward && rewardType === RewardType.EVENT)
                ) && (
                    <div className="d-flex col-12 con2 flex-wrap">
                        {/* Start of Reward Info Left Container  */}
                        <div className="col-12 col-sm-6 redeemImg">
                            <img className="img-fluid w-100" src={
                                (reward?.icon && reward?.icon[i18n.language]) ?
                                    reward?.icon[i18n.language] :
                                    (
                                        reward?.image_url ?
                                            reward?.image_url[i18n.language] :
                                            reward?.image?.path
                                    )
                            } />
                        </div>
                        {/* End of Reward Info Left Container  */}

                        {/* Start of Reward Info Right Container  */}
                        <div className="col-12 col-sm-6 redeemDesc align-items-center">
                            <div className="desc">
                                {['description', 'remarks'].map((field, i) => (
                                    <div key={i}>
                                        {reward[field] && reward[field][i18n.language] && (<>
                                            <div className="mb-3 descCon">
                                                <div dangerouslySetInnerHTML={{
                                                    __html: reward[field][i18n.language]
                                                }}></div>
                                            </div>
                                        </>)}
                                    </div>
                                ))}
                                {['terms_and_conditions'].map((field, i) => (
                                    <div key={i} className="tnc-rows">
                                        {reward[field] && reward[field][i18n.language] && (<>
                                            <div className="tnc" onClick={() => setShowTnc(!showTnc)}>
                                                <p className="mb-1"><strong>{t(`rewards.${field}`)}</strong></p>
                                                <div>
                                                    {showTnc ?
                                                        // minus icon
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                                                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                                        </svg>
                                                        :
                                                        // plus icon
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                        </svg>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`mb-3 descCon ${showTnc ? 'show' : ''}`}>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: reward[field][i18n.language]
                                                }}>
                                                </div>
                                            </div>
                                        </>)}
                                    </div>
                                ))}
                            </div>

                            {/* Start of Reward Info Right Container Buttons  */}

                            {/* Start of Redeem ITEM panel*/}
                            {profile?.email_verified === true && reward.points > 0 && (
                                <>
                                    <QuantityPanel 
                                        setSanitizedQuantity={setSanitizedQuantity} 
                                        quantity={quantity} 
                                        totalRequiredAmount={(reward.points * quantity).toLocaleString() + " " + t('offer_convert_to_miles.points')}
                                    /> 
                                    <div className="btns col-12 con2 d-flex justify-content-between flex-column flex-xl-row">
                                        <div className="col-12 col-xl-6 xl-pe-3 me-3 text-align-end" >
                                            {
                                                availableQuantity > 0 ? (
                                                    /* Not sold out */
                                                    <button
                                                        type="button"
                                                        className={' w-100  btn ' + (profile?.available_points < reward.points * quantity ? 'btn-outline' : '')}
                                                        onClick={() => {
                                                            if (profile?.available_points < reward.points * quantity) {
                                                                return;
                                                            }
                                                            setHasError(false);
                                                            setShowConfirmRedeem(true);
                                                        }}
                                                    >
                                                        {t(
                                                            profile?.available_points < reward.points * quantity ?
                                                                /* User doesn't have enough points */
                                                                'offer_convert_to_miles.come_back_with_more_cb_points' :
                                                                /* User has enough points */
                                                                'offer_convert_to_miles.redeem_now'
                                                        )}
                                                    </button>
                                                ) : (
                                                    /* Sold out */
                                                    <button type="button" className='col-12 col-xl-6 btn btn-outline'>
                                                        {t('offer_convert_to_miles.sold_out')}
                                                    </button>
                                                )
                                            }
                                        </div>
                                        {/* Back button only for redeem item */}
                                        <Link className="col-12 col-xl-6 pl-3 text-align-end mt-2 mt-xl-0" to={`/${i18n.language}/reward-list`}>
                                            <BackButton />
                                        </Link>
                                    </div>
                                </>
                            )}
                            {/* End of Redeem ITEM panel*/}

                            {/* Start of Event registration panel */}
                            {profile?.email_verified === true && rewardType === RewardType.EVENT && (
                                <>
                                    { /* Registration Available Cases */
                                        (currentDate >= reward.public_registration_start_date /* Public registration */
                                        || ( /* Priority registration, priority user */
                                            currentDate >= reward.priority_registration_start_date
                                            && currentDate < reward.public_registration_start_date
                                            && (profile.membership_tier === MembershipTier.RED || profile.membership_tier === MembershipTier.GOLD)
                                        )) &&
                                        /* show register btn for event if now before its expired date or unset expired date */
                                        (currentDate < reward.event_expiry_date || !reward.event_expiry_date) 
                                        && (
                                            <>
                                                <QuantityPanel 
                                                    setSanitizedQuantity={setSanitizedQuantity} 
                                                    quantity={quantity} 
                                                    totalRequiredAmount={(quantity).toLocaleString() + " " + t('events.tickets')}
                                                /> 
                                                <div className="btns col-12 con2 d-flex justify-content-between flex-column flex-xl-row">
                                                    <div className="col-12 col-xl-6 xl-pe-3 me-3 text-align-end" >
                                                        <button
                                                            type="button"
                                                            className="w-100 btn btn-outline"
                                                            onClick={() => {
                                                                setHasError(false);
                                                                setShowConfirmRegister(true);
                                                            }}
                                                        >
                                                            {t('registration_common.register_now')}
                                                        </button>
                                                    </div>

                                                    <Link className="col-12 col-xl-6 pl-3 text-align-end mt-2 mt-xl-0" to={`/${i18n.language}/reward-list`}>
                                                        <BackButton />
                                                    </Link>
                                                </div>
                                            </>
                                        )
                                    }

                                    { /* Registration Unavailable Cases*/
                                        (currentDate < reward.priority_registration_start_date /* Pre-registration */
                                        || ( /* Priority registration, public user*/
                                            currentDate >= reward.priority_registration_start_date
                                            && currentDate < reward.public_registration_start_date
                                            && (profile.membership_tier === MembershipTier.BLACK)
                                        ))
                                        && (
                                            <>
                                            <div className="d-flex justify-content-center">
                                                <span className='text-center' 
                                                     dangerouslySetInnerHTML={{ __html: currentDate < reward.priority_registration_start_date ? 
                                                        before_priority_message[0].content[i18n.language] : 
                                                        start_priority_message[0].content[i18n.language] }}>
                                                </span>
                                            </div>
                                            <Link className="col-12 col-xl-6 pl-3 text-align-end mt-2 mt-xl-0" to={`/${i18n.language}/reward-list`}>
                                                <BackButton />
                                            </Link>
                                        </>
                                            // <>
                                            //     <div className="d-flex">
                                            //     <span className='text-center' 
                                            //         dangerouslySetInnerHTML={{ __html: currentDate < reward.priority_registration_start_date ? 
                                            //             before_priority_message[0].content[i18n.language] : 
                                            //             start_priority_message[0].content[i18n.language] }}>
                                            //     </span>
                                            //     </div>
                                            //     <Link className="col-12 col-xl-6 pl-3 text-align-end mt-2 mt-xl-0" to={`/${i18n.language}/reward-list`}>
                                            //         <BackButton />
                                            //     </Link>
                                            // </>
                                        )
                                    }

                                    { /* Registration Public Expired */
                                        currentDate >= reward.event_expiry_date
                                        && (
                                            <>
                                                <div className="d-flex">
                                                    <span className='text-center' dangerouslySetInnerHTML={{ __html: public_expired_message[0].content[i18n.language] }}></span>
                                                </div>
                                                <Link className="col-12 col-xl-6 pl-3 text-align-end mt-2 mt-xl-0" to={`/${i18n.language}/reward-list`}>
                                                    <BackButton />
                                                </Link>
                                            </>
                                        )
                                    }
                                </>
                            )}
                            {/* End of Event registration panel */}

                            {reward?.points >= 0 && !profile && (
                                <>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-xl-6">
                                            <LoginButton
                                                fontStyle={'white'}
                                                loginBtnText={t('user.login_to_continue')}
                                            />
                                        </div>
                                        <Link className="col-12 col-xl-6 text-align-end mt-2 mt-xl-0" to={`/${i18n.language}/reward-list`}>
                                            <BackButton />
                                        </Link>
                                    </div>
                                </>
                            )}
                            {(reward?.points > 0 || rewardType === RewardType.EVENT )&& profile && profile?.email_verified !== true && (
                                <div className="d-block d-sm-flex flex-column justify-content-center">
                                    <VerifyEmail
                                        email={profile?.email}
                                        withoutTemplate={true}
                                        message={t('rewards.please_verify_email')}
                                    />
                                    <Link className="w-100 col-12 col-xl-6 mt-2 mt-xl-0" to={`/${i18n.language}/reward-list`}>
                                        <BackButton />
                                    </Link>
                                </div>
                             )}
                        </div>
                        {/* End of Reward Info Right Container  */}

                    </div>
                )
            }

            {
                (
                    reward && 
                        ( rewardIsExpired
                        || (rewardType === RewardType.ITEM && reward.status == RewardStatus.INACTIVE )
                        || (rewardType === RewardType.EVENT && reward.status == EventStatus.INACTIVE) 
                        )
                    || !reward
                ) && 
                <div>{t('rewards.item_not_found')}.</div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    profile: state.shared.profile,
    countries: state.shared.countries,
    public_expired_message: state.shared.public_expired_message,
    before_priority_message: state.shared.before_priority_message,
    start_priority_message: state.shared.start_priority_message,
});

export default connect(mapStateToProps)(RewardInfo);
