import { useTranslation } from 'react-i18next';

const NextLevelGauge = ({
  showFullLabel,
  strokeDasharray,
  currentLevel,
  targetLevel,
  gaugeLabel
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="d-flex col-4 circleWrap flex-wrap gap-2">
      <div className="circle-wrap p-1 p-lg-0">
        <svg viewBox="0 0 36 36" className="circular-chart">
          <path className="circle-bg"
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path className="circle"
            strokeDasharray={strokeDasharray}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          {showFullLabel ?
            (
              <text className="percentage">
                <tspan x="18" y="20" fontWeight="bold">{t('landing_page.full')}</tspan>
              </text>
            )
            :
            (
              <text className="percentage">
                <tspan x="18" y="16" fontWeight="bold">{currentLevel.toLocaleString()}</tspan>
                <tspan x="19" y="24">/{targetLevel.toLocaleString()}</tspan>
              </text>
            )
          }
        </svg>
      </div>
      <div className="col-12 text-center">
        <p>{gaugeLabel}</p>
      </div>
    </div>
  );
}

export default NextLevelGauge;