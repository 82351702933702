import React, { useState, useEffect } from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import TitleBar from "../components/TitleBar";

const StaticPage = ({ alias, staticPage }) => {
    const [staticContent, setStaticContent] = useState([]);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (staticPage.length > 0) {
            for (let i = 0; i < staticPage.length; i++) {
                if (staticPage[i].alias == alias) {
                    setStaticContent([staticPage[i]]);
                    break;
                } else {
                    setStaticContent(null);
                }
            }
        }
    }, [alias, staticPage]);

    return (
        <section className="mainContents mainContents2">
            <div>
                {staticContent && staticContent.length > 0 && (
                    <section className="mainContents mainContents2">
                        <TitleBar
                            title = {staticContent[0].name[i18n.language]}
                        />
                        <div id="page-whats-cb">
                            <section className='content-container'>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: staticContent[0].content[i18n.language]}}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </section>
                )}
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    staticPage: state.shared.static_page,
});
export default connect(mapStateToProps)(StaticPage);
