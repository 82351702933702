import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../services/helpers";
import { Link, useHistory } from "react-router-dom";
import { pointTransactionType } from "../services/enums";

const PointTransactionTable = ({ transactions }) => {
    const { t, i18n } = useTranslation();
    const navigate = useHistory();

    useEffect(() => {
        if (!transactions) navigate.push(`/`);
      }, []);

    return (
        <div className="w-100" style={{ overflow: "auto" }}>
            <table className="pointTable">
                <thead>
                    <tr>
                        <th>{t('point_transaction_log.date')} </th>
                        <th>{t('point_transaction_log.description')}</th>
                        <th>{t('point_transaction_log.point_change')}</th>
                    </tr>
                </thead>
                <tbody>
                    { transactions?.length > 0 ? 
                        transactions.map(item => (
                            <tr key={item.id}>
                                <td>{dateFormat(item.created_at)}</td>
                                <td>
                                    {
                                        // TODO refactor
                                        item.description == pointTransactionType.completed_profile ? t('point_transaction_log.profile_completion') :
                                        item.description == pointTransactionType.scan_ticket ? t('point_transaction_log.ticket_scanned') :
                                        item.description == pointTransactionType.scan_thanksgiving ? t('point_transaction_log.ticket_scanned_with_bonus') :
                                        item.description == pointTransactionType.scan_ticket_thanksgiving ? t('point_transaction_log.ticket_scanned_with_bonus') :
                                        item.description == pointTransactionType.reward_redemption ? t('point_transaction_log.reward_redemption') :
                                        item.description == pointTransactionType.asia_miles_redemption ? t('point_transaction_log.asia_miles_redemption') :
                                        item.description == pointTransactionType.bulk_import ? t('point_transaction_log.bulk_import') :
                                        item.description == pointTransactionType.return_refund_exchange ? t('point_transaction_log.return_refund_exchange') :
                                        item.description == pointTransactionType.christmas_birthday_reward_2023 ? t('point_transaction_log.christmas_birthday_reward_2023') :
                                        item.description == pointTransactionType.birthday_double_points ? t('point_transaction_log.birthday_double_points') :
                                        item.description == pointTransactionType.season_subscription_points ? t('point_transaction_log.season_subscription_points') :
                                        item.description[i18n.language] ? item.description[i18n.language] :
                                        item.description
                                    }
                                </td>
                                <td className='text-end'>{item?.delta_balance >= 0 && '+'}{item?.delta_balance?.toLocaleString()}</td>
                            </tr>
                        ))
                        :
                        <tr>
                            <td className='text-center'>--</td>
                            <td className='text-center'>--</td>
                            <td className='text-center'>--</td>
                            <td className='text-center'>--</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

export default PointTransactionTable;
