import { useTranslation } from "react-i18next";

const QuantityPanel = ({ 
    setSanitizedQuantity,
    quantity,
    totalRequiredAmount
}) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="quantity d-flex col-12 flex-wrap con2 align-items-center">
                <p className="col-12">
                    {t('rewards.quantity')}
                </p>
                {/* Quantity input spinner */}
                <div className="col-6 stock justify-content-start">
                    <span>
                        <a onClick={() => setSanitizedQuantity(quantity - 1)}>
                            <i className="fas fa-minus"></i>
                        </a>
                        <input type="text" className="quanCount" value={quantity} disabled={true} />
                        <a onClick={() => setSanitizedQuantity(quantity + 1)}>
                            <i className="fas fa-plus"></i>
                        </a>
                    </span>
                </div>
                {/* total */}
                <div className="col-6 total">
                    <h3 className="text-end redText mb-0">
                        {totalRequiredAmount}
                    </h3>
                </div>
            </div>
        </>
    );
}

export default QuantityPanel;