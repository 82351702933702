import { combineReducers } from 'redux';
import { RouterState, connectRouter } from 'connected-react-router';
import sharedReducer from './store/shared';

const createRootReducer = (history) => combineReducers({
    shared: sharedReducer,
    router: connectRouter(history),
});

export interface State {
    profile: any,
    router: RouterState
}

export default createRootReducer;