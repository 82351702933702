import { useAuth0 } from '@auth0/auth0-react';
import { logout as AppLogout } from '../services/user';
import { useTranslation } from 'react-i18next'

const LogoutButton = () => {
    const { t } = useTranslation();
    const { logout } = useAuth0();

    return (
        <button
            className="btn"
            type="button"
            onClick={() => {
                AppLogout();
                logout({ returnTo: `${window.location.origin}` })
            }}
        >
            {t('user.logout')}
        </button>
    )
}

export default LogoutButton