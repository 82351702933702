import { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import FormLabel from "./FormLabel";

const Preference = ({ schoolTypes, formData, handleFormData, handleFileUpload, active = true, instruments, requiredFields = null, isYAForm = false }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!formData || !instruments) return;

    (formData?.instruments || []).forEach(instrument => {
      const elem = document.querySelector(`[data-instrument="${instrument.id}"]`);
      if (!elem) return;
      elem.classList.add('active');
    });
  }, [formData, instruments]);

  const onInstrumentChange = (item) => {
    let instruments = [...(formData?.instruments || [])];
    const index = instruments.findIndex(v => v.id === item.id);
    const elem = document.querySelector(`[data-instrument="${item.id}"]`);

    if (index > -1) {
      instruments.splice(index, 1);
      elem?.classList.remove('active');
    } else {
      instruments.push(item);
      elem?.classList.add('active');
    }

    handleFormData({
      target: {
        name: 'instruments',
        value: instruments,
      }
    })
  }

  return (
    <div id="formPart3" className={`formSteps step3 col-12 flex-wrap ${active ? 'curStep' : ''}`}>

      {/* Fields specific for the YA registration form */}
      {isYAForm && (
          <>

            <p className="mb-4">{t('registration_common.ensure_correct_student_info')}</p>

            <div className="formCol col-12 col-sm-12">
              <FormLabel title={t('landing_page.school_name')} required={requiredFields?.school_name}/>
              <div className="d-flex align-items-center">
                <input
                    className="form-control formInput"
                    name="school_name"
                    type="text"
                    onChange={handleFormData}
                    value={formData?.school_name || ''}
                />
              </div>
            </div>

            <div className="formCol col-12 col-sm-7">
              <FormLabel title={t('landing_page.school_type')} required={requiredFields?.school_type}/>
              <div className="d-flex align-items-center">
                <select
                    className="form-select formInput"
                    name='school_type'
                    value={formData?.school_type || ''}
                    onChange={handleFormData}
                >
                  <option value="" disabled>--</option>
                  {(schoolTypes || []).map(schoolTypeKey => (
                      <option key={schoolTypeKey} value={schoolTypeKey}>
                        {t(`model_constants.MemberProfile.SCHOOL_TYPES.${schoolTypeKey}`)}
                      </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="formCol col-12 col-sm-7">
              <FormLabel title={t('landing_page.student_card_file')} required={requiredFields?.student_card_file}/>
              <div className="d-flex align-items-center">
                <input
                    className="form-control formInput"
                    name="student_card_file"
                    type="file"
                    accept="image/*|application/pdf"
                    onChange={(ev) => handleFileUpload(ev, '_student_card_base64')}
                />
              </div>
            </div>
          </>
      )}
      <div className="formCol col-12">
        <FormLabel
          title={t('landing_page.area_of_interest')}
          required={requiredFields?.interest}
        />
        <div className="interests">
          {(instruments || []).map((item, i) => (
            <div
              key={i}
              className='interest'
              data-instrument={item.id}
              onClick={() => onInstrumentChange(item)}
            >
              {item[i18n.language]?.name || item.name}
            </div>
          ))}
        </div>
      </div>
      <div className="formCol col-12 col-sm-7">
        <FormLabel
          title={t('landing_page.preferred_language')}
          required={requiredFields?.preferred_lang}
        />
        <div className="d-flex">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="preferred_lang"
              value='en'
              checked={formData?.preferred_lang === 'en' || false}
              onChange={handleFormData}
            />
            <label className="form-check-label" htmlFor="langEng">{t('landing_page.english')}</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="preferred_lang"
              value='tc'
              checked={formData?.preferred_lang === 'tc' || false}
              onChange={handleFormData}
            />
            <label className="form-check-label" htmlFor="langChi">{t('landing_page.chinese')}</label>
          </div>
        </div>
      </div>

      <div className="formCol col-12">
        <p className="formBlank"></p>
      </div>

      <div className="formCol col-12">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name='receive_email_promotion'
            checked={formData?.receive_email_promotion || false}
            onChange={handleFormData}
          />
          <label className="form-check-label">
            {t('registration_step_three.do_not_wish_receive_promotion')}
          </label>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  instruments: state.shared.instruments,
  schoolTypes: state.shared.school_types
});

export default connect(mapStateToProps)(Preference);