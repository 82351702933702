import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getUserProfile } from '../services/user';
import { sharedSetItem, SharedSetItemType } from '../store/shared';
import { useAuth0 } from '@auth0/auth0-react';
import StaticContentDisplayTopOrBottomContent from '../components/StaticContentDisplayTopContent';
import { DeliveryOption, RewardType } from '../constants';

const RedeemSuccess = ({
  redeem_on_site_pick_up_item_success,
  redeem_coupon_success,
  redeem_free_ticket_success,
  redeem_mail_item_success,
  asia_miles_success,
  setProfile
}) => {
  const { user } = useAuth0();

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [redeemDetail, setRedeemDetail] = useState(null);
  const [rewardType, setRewardType] = useState(null);
  const navigate = useHistory();


  //set html_title
  useEffect(() => {
    document.title = t('html_title.redeem_success') + ' | ' + t('html_title.home');
  }, []);
  useEffect(() => {
    document.title = t('html_title.redeem_success') + ' | ' + t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    if (location && location.state) {
      setRedeemDetail(location.state.state.redeem_res);
      setRewardType(location.state.state.type);
    } else {
      navigate.push(`/`)
    }
  }, [location]);

  // update profile available points
  useEffect(() => {
    // console.log(redeemDetail)
    if (user) assignUserProfile(user);
  }, [user]);

  const assignUserProfile = async (credential) => {
    const profile = await getUserProfile(credential);
    setProfile(profile);
  };

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-sm-6 contents m-auto">
            <div className="loginBox loginForm d-flex col-12 flex-wrap">
              <div> 
                {/* Asia Miles */}
                {
                  rewardType == RewardType.ASIA_MILES &&
                  <>
                    <StaticContentDisplayTopOrBottomContent
                      staticContentName={asia_miles_success}
                      contentPosition={'top'}
                    />
                    <p className="mt-4 mb-2">
                      <strong>{t('offer_convert_to_miles.asia_miles_convert_ref_no')}</strong>
                      {redeemDetail?.ref_no}
                      <br />
                      <strong>{t('offer_convert_to_miles.asia_miles_name')}</strong>
                      {redeemDetail?.asia_miles_name}
                      <br />
                      <strong>{t('offer_convert_to_miles.asia_miles_no')}</strong>
                      {redeemDetail?.asia_miles_no}
                      <br />
                      <strong>{t('offer_convert_to_miles.asia_miles_converted')}</strong>
                      <span className='' dangerouslySetInnerHTML={{ __html: t('innerHtml.cb_logo') }}></span>
                      {redeemDetail?.asia_miles_convert}
                      <br />
                      <strong>{t('offer_convert_to_miles.point_to_convert')}</strong>
                      <span className='' dangerouslySetInnerHTML={{ __html: t('innerHtml.cb_logo') }}></span>
                      {redeemDetail?.point_to_convert}
                      <br />
                      <strong>{t('offer_convert_to_miles.remaining_points')}</strong>
                      <span className='' dangerouslySetInnerHTML={{ __html: t('innerHtml.cb_logo') }}></span>
                      {redeemDetail?.remaining_points}
                      <br />
                    </p>
                    <StaticContentDisplayTopOrBottomContent
                      staticContentName={asia_miles_success}
                      contentPosition={'bottom'}
                    />
                  </>
                }

                {/* Item */}
                {
                  rewardType == RewardType.ITEM &&
                  <>
                    <StaticContentDisplayTopOrBottomContent
                      staticContentName={
                        redeemDetail.delivery == DeliveryOption.EMAIL ? redeem_coupon_success :
                          redeemDetail.delivery == DeliveryOption.ON_SITE_PICKUP ? redeem_on_site_pick_up_item_success :
                            redeem_mail_item_success
                      }
                      contentPosition={'top'}
                    />
                    <p className="mt-4 mb-2">
                      <strong>{t('rewards.redeem_ref_no')}</strong>
                      {redeemDetail?.ref_no}
                      <br />
                      <strong>{t('rewards.redeem_gift_name')}</strong>
                      {i18n.language == 'tc' && (redeemDetail?.gift_chi_name)}
                      {i18n.language == 'sc' && (redeemDetail?.gift_chi_name)}
                      {i18n.language != 'tc' && i18n.language != 'sc' && (redeemDetail?.gift_eng_name)}
                      <br />
                      <strong>{t('rewards.points_redeemed')}</strong>
                      <span className='' dangerouslySetInnerHTML={{ __html: t('innerHtml.cb_logo') }}></span>
                      {redeemDetail?.points_redeemed}
                      <br />
                      <strong>{t('rewards.remaining_points')}</strong>
                      <span className='' dangerouslySetInnerHTML={{ __html: t('innerHtml.cb_logo') }}></span>
                      {redeemDetail?.remaining_points}
                      <br />
                      { 
                        redeemDetail.delivery == DeliveryOption.ON_SITE_PICKUP ?
                        <>
                          <strong>{t('rewards.delivery_option')}</strong>
                          {t('rewards.on_site_pick_up')}
                        </>
                        :
                        redeemDetail.delivery == DeliveryOption.EMAIL ?
                        <>
                          <strong>{t('rewards.coupon')}</strong>
                          {redeemDetail?.couponArr.map((url) => ( 
                            <p>{url}</p>
                          ))}
                        </>
                        :
                        redeemDetail.delivery == DeliveryOption.MAIL ?
                        <>
                          <strong>{t('rewards.mailing_address')}</strong>
                          {redeemDetail?.address}
                        </>
                        :
                        '' // show nothing when cannot get correct delivery type
                      }
                      <br />
                    </p>
                    {
                      (redeemDetail.delivery == DeliveryOption.MAIL) ?

                        <StaticContentDisplayTopOrBottomContent
                          staticContentName={
                            redeem_mail_item_success
                          }
                          contentPosition={'bottom'}
                        /> :

                        <div className='con' 
                          dangerouslySetInnerHTML={{ __html: 
                            (i18n.language == 'en')?
                            redeemDetail?.enRedeemedItemBottomMessage
                            :
                            redeemDetail?.tcRedeemedItemBottomMessage
                          }}>
                        </div>
                    }
                  </>
                }

                <Link className="btn" to={`/${i18n.language}/reward-list`}>
                  <b>{t('rewards.add_another_redeem')}</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  redeem_on_site_pick_up_item_success: state.shared.redeem_on_site_pick_up_item_success,
  redeem_mail_item_success: state.shared.redeem_mail_item_success,
  redeem_coupon_success: state.shared.redeem_coupon_success,
  redeem_free_ticket_success: state.shared.redeem_free_ticket_success,
  asia_miles_success: state.shared.asia_miles_success,
});
const mapDispatchToProps = (dispatch) => ({
  setProfile: (value) => dispatch(sharedSetItem(SharedSetItemType.profile, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RedeemSuccess);
