
// Types of rewards
export enum RewardType {
  ITEM = 'Item',
  ASIA_MILES = 'Asia Miles',
  EVENT = 'Event',
}

// Delivery options for redeeming "item" type rewards.
// Must be in sync with MemberProfile.php
export enum DeliveryOption {
    MAIL = 'Mail',
    ON_SITE_PICKUP = 'On-site pick-up',
    EMAIL = 'Email',
}

// Must be in sync with MemberProfile.php
export enum MembershipTier {
  RED = 'Red',
  BLACK = 'Black',
  GOLD = 'Gold',
  DIAMOND = 'Diamond',
}

// Must be in sync with StaticContent.php
export enum GenericStaticContentCode {
  SC_UPGRADE_OFFER = 'sc-upgrade-offer',
}

// Must be in sync with MemberProfile.php
export enum YaStatus {
    YA_STATUS_NA = 'N/A',
    YA_STATUS_PENDING_PAYMENT = 'Pending Payment',
    YA_STATUS_PENDING_APPROVAL = 'Pending Approval',
    YA_STATUS_APPROVED = 'Approved',
    YA_STATUS_REJECTED = 'Rejected',
    YA_STATUS_EXPIRED = 'Expired',
}

// Must be in sync with RedeemedItemCategories.php
export enum RedeemedItemCategories {
    REWARDS = 'Rewards',
    SPECIAL_OFFER = 'Special Offer',
    ALL_YEAR_ROUND = 'All Year Round',
    ARTS_AND_CULTURE = 'Merchant Offer - Arts & Cultural Organisations',
    BOOKSTORE_AND_CULTURAL_INSTITUTION = 'Merchant Offer - Bookstores & Cultural Institutions',
    LIFESTYLE = 'Merchant Offer - Lifestyle',
    MUSIC_CENTRE_AND_INSTITUTION = 'Merchant Offer - Music Centres & Companies',
    RESTAURANT = 'Merchant Offer - Restaurants',
    EVENT = 'Events',
}
