import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { parseFormData, useSCTier } from "../../services/helpers";
import { getSCTier } from "../../services/user";
import Popup from "../Popup";
import ReactLoadingSpinner from "../ReactLoadingSpinner";
import RegistrationContainer from "./RegistrationContainer";
import { connect } from "react-redux";
import LoginButton from "../LoginButton";
import {MembershipTier} from "../../constants";

const SCForm = ({
  placeholder_membership_number,
  profile,
}) => {
  const { t, i18n } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const { setSCTier, setSCToken } = useSCTier();
  const [formData, setFormData] = useState<any>({});
  const [validationError, setValidationError] = useState(null);
  const [placeholderToken, setPlaceholderToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [usedHash, setUsedHash] = useState(false);

  const handleFormData = (e) => {
    const { name, value } = parseFormData(e);
    setFormData({ ...formData, [name]: value });
  }

  const submit = async () => {
    setIsLoading(true);

    const res = await getSCTier(formData);
    // Prevent gold members downgrading to red
    if (res.success && profile?.membership_tier == MembershipTier.GOLD && res.scTier == MembershipTier.RED) {
      setValidationError(t('error.invalid_tier'));
    }

    else if (res.success) {
      setSCTier(res.scTier);
      setSCToken(res.scToken);
      loginWithRedirect({
        type: 'register',
        lang: i18n.language,
        redirectUri: `${window.location.origin}/login`,
        appState: {
          page: `/${i18n.language}/registration-sc`
        }
      });
    } else {
      setValidationError(t('error.duplicate_token'));
    }

    setIsLoading(false);
  };

  const submitLogin = async () => {
    setIsLoading(true);

    const res = await getSCTier(formData);
    // Prevent gold members downgrading to red
    if (res.success && profile?.membership_tier == MembershipTier.GOLD && res.scTier == MembershipTier.RED) {
      setValidationError(t('error.invalid_tier'));
    }

    else if (res.success) {
      setSCTier(res.scTier);
      setSCToken(res.scToken);
      loginWithRedirect({
        type: 'login',
        lang: i18n.language,
        redirectUri: `${window.location.origin}/login`,
        appState: {
          page: `/${i18n.language}/registration-sc`
        }
      });
    } else {
      setValidationError(t('error.duplicate_token'));
    }

    setIsLoading(false);
  };


  useEffect(() => {
    if (placeholder_membership_number && placeholder_membership_number.length > 0) setPlaceholderToken(placeholder_membership_number[0].name[i18n.language]);
  }, [i18n.language]);

  // auto set formData.sc_token = Url hash Token if user access page by url with token in hash
  useEffect(() => {
    if (window.location.hash.replace('#', '')) {
      setUsedHash(true);
      formData.sc_token = window.location.hash.replace('#', '');
    }
  }, []);

  return (
    <>
      {validationError && <Popup
        title={''} // temp removed error.validation_error
        message={validationError}
        buttons={[
          {
            label: t('button.close'),
            callback: () => {
              setValidationError(null);
            }
          }
        ]}
      />}

      {isLoading && <ReactLoadingSpinner />}

      <RegistrationContainer
          showHint={false}
          rightPaneTitle={t('registration_sc_form.right_title')}
          rightPaneContent={t('registration_sc_form.right_content')}>

        <h2 className="title1">
          {t('registration_sc_form.registration')}
        </h2>
        <h1 className="title2">
          {t('registration_sc_form.left_title')}
        </h1>
        <p dangerouslySetInnerHTML={{__html: usedHash ? t('registration_sc_form.left_content') : t('registration_sc_form.token_left_content')}}/>
        <span className="footnote" style={{ color: "grey" }}>
          <br /> {t('registration_sc_form.footnote')} <br />
        </span>

        <br />
            <form>
              {/* Client requested to hide, may need it in future */}
              {/* Normal SC Form for user access page by registration options */}
              {/*<div className="formCol col-12 col-xl-8" style={usedHash ? {display: 'none'} : {  }} >*/}
              {/*  <p className="formTitle">*/}
              {/*    Token*/}
              {/*  </p>*/}
              {/*  <input*/}
              {/*    name="sc_token"*/}
              {/*    className="form-control formInput"*/}
              {/*    type="text"*/}
              {/*    onChange={handleFormData}*/}
              {/*    value={formData?.sc_token || ''}*/}
              {/*    placeholder={placeholderToken || "e.g. CB123456"}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="col-12 formCol justify-content-end" style={usedHash ? {display: 'none'} : { display: 'flex' }}>*/}
              {/*  <label*/}
              {/*    id="btnSubmit"*/}
              {/*    className="btn nextStep"*/}
              {/*    onClick={submit}*/}
              {/*  >*/}
              {/*    {t('button.submit')}*/}
              {/*  </label>*/}
              {/*</div>*/}

              <div className="col-12 formCol justify-content-end" style={usedHash ? {display: 'none'} : { display: 'flex' }} >
                <LoginButton className="btn nextStep"
                             loginBtnText={t('sign_in.sign_in')}
                />
              </div>

              {!profile && (
                  <>
                    <div className="col-6 margin-auto" style={usedHash ? {display: "flex", marginLeft: 'auto', paddingRight: '10px', paddingBottom: '25px'} : {display: 'none'}}>

                      <label
                          id="btnSubmitLogin"
                          className="btn nextStep"
                          onClick={submitLogin}
                      >
                        <strong style={{fontSize: '14px'}}>{t('sign_in.sign_in')}</strong>
                      </label>
                    </div>
                  </>
              )}

              {/* Start now btn for user access page by url with token in hash */}
              <div className={profile ? "col-12 formCol justify-content-end" : "col-6 margin-auto"} style={usedHash ? {display: "flex", marginRight: '0', paddingBottom: '25px'} : {display: 'none'}}>
                <label
                  id="btnSubmit"
                  className="btn nextStep"
                  onClick={submit}
                >
                  <strong style={{fontSize: '14px'}}>{profile ? t('button.start_now') : t('registration_common.register_now')}</strong>
                </label>
              </div>
            </form>
      </RegistrationContainer>
    </>
  );
};

const mapStateToProps = state => ({
  placeholder_membership_number: state.shared.placeholder_membership_number,
  profile: state.shared.profile,
});

export default connect(mapStateToProps)(SCForm);
