import React, { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

const AsiaPayError = ( {asia_pay_error} ) => {
  const { t, i18n } = useTranslation( );

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.asiapay_error')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.asiapay_error')+' | '+  t('html_title.home');
  }, [i18n.language]);

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-sm-6 contents m-auto">
            <div className="resultBox d-flex col-12 flex-wrap">
              <div>
                {
                  asia_pay_error?.map((item, i) => (
                    <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                  ))
                }
                <Link className="btn" to={`/${i18n.language}/registration-red`}>
                  <b>{t('registration_paid_failed.restart_membership_application')}</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = state => ({
  asia_pay_error: state.shared.asia_pay_error,
});
export default connect(mapStateToProps)(AsiaPayError);