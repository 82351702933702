import { connect } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import ReactLoadingSpinner from "../components/ReactLoadingSpinner";
import { sharedSetItem, SharedSetItemType } from "../store/shared";
import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';

// this page is for general Auth0 redirect
// use this instead of Auth0Provider because of auto login
const Login = ({ hasTriedLoginAuth0, setHasTriedLoginAuth0 }) => {
  const navigate = useHistory();
  const { handleRedirectCallback } = useAuth0();
  const { t, i18n } = useTranslation();

  const getData = async () => {
    const params = new URLSearchParams(window.location.search);

    // redirect from Auth0
    if (params.has('redirect')) {
      if (hasTriedLoginAuth0) return;
      setHasTriedLoginAuth0(true);

      // wait for state change
      const path = window.location.href.split('?redirect=').at(1);
      setTimeout(() => {
        changeLanguage(path.split('/').at(1));
        navigate.replace(path);
      }, .3e3);
      return;
    }

    // to work around a bug in which React shows a blank page after redirecting from Auth0
    if (params.has("code")) {
      const state = await handleRedirectCallback();
      navigate.replace(state.appState?.page || '/');
      return;
    }

    navigate.replace('/');
    return;
  }

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.login')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.login')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <ReactLoadingSpinner />
      <section className="mainContents">
        <div className="container conWrapper">
          <div className="row justify-content-center"></div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = state => ({
  hasTriedLoginAuth0: state.shared.has_tried_login_auth0,
});

const mapDispatchToProps = (dispatch) => ({
  setHasTriedLoginAuth0: (value) => dispatch(sharedSetItem(SharedSetItemType.has_tried_login_auth0, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);