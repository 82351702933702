import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStaticContent } from '../services/staticContent';

const ContactUs = () => {
  const [content, setContent] = useState(null);
  const { t, i18n } = useTranslation();

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.contact_us')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.contact_us')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    getStaticContent().then(setContent);
  }, []);

  return (
    <section className="mainContents">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-sm-12 contents">
            {content && <div dangerouslySetInnerHTML={{
              __html: i18n.language === 'tc'
                ? content.staticContentTC?.contact_us
                : (i18n.language === 'sc'
                  ? content.staticContentSC?.contact_us
                  : content.contactUsEN
                )
            }}></div>}
          </div>
        </div>
      </div>
    </section>
  )
};

export default ContactUs;