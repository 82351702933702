import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Slider from "react-slick";
import TitleBar from '../components/TitleBar';

const HowTo = ({ faq, how_to_banner }) => {
  const { t, i18n } = useTranslation();
  const [navSlider, setNavSlider] = useState(null);
  const [conSlider, setConSlider] = useState(null);
  const [slides, setSlides] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(6);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const navSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    arrows: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const conSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.how_to')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.how_to')+' | '+  t('html_title.home');
  }, [i18n.language]);


  // make sure faq is double of slidesToShow to allow scroll
  useEffect(() => {
    if (!faq || faq.length === 0) return;

    let slides = [...faq];
    if (faq.length < navSliderSettings.slidesToShow * 2) {
      for (let i = 0; i < Math.ceil(navSliderSettings.slidesToShow * 2 / faq.length) - 1; i++) {
        slides = [...slides, ...faq];
      }
    }

    setSlides(slides);
  }, [faq]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    if (windowWidth < 769) {
      setSlidesToShow(6);
    } else {
      setSlidesToShow(6);
    }
  });

  return (
    <section className="mainContents mainContents2">
      <TitleBar
        title={t('header.how_to')}
      />
      <section>
        <div className="container-fluid">
          <div className="row">
          </div>
        </div>
      </section>
      {(slides || [])?.length > 0 && (
        <section className="mainContents mainContents2">
          <div className="container conWrapper">
            <div className="row">
              <div className="col-12 contents contents2">
                <div className="howtoCons">
                  <div className="con1">
                    <div className="icons">
                      <div className="slider-arrow">
                        <div
                          className="howtoPrev slickBtn arrow-btn prev"
                          onClick={() => navSlider?.slickPrev()}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-96 0 512 512" width="1em" height="1em" fill="currentColor">
                            <path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"></path>
                          </svg>
                        </div>
                        <div
                          className="howtoNext slickBtn arrow-btn next"
                          onClick={() => navSlider?.slickNext()}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-96 0 512 512" width="1em" height="1em" fill="currentColor">
                            <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                          </svg>
                        </div>
                      </div>
                      <Slider
                        ref={slider => setNavSlider(slider)}
                        asNavFor={conSlider}
                        {...navSliderSettings}
                      >
                        {slides.map((item, i) => (
                          <div className='wrapper' key={i}>
                            <div className="icon" 
                             onClick={() => {
                              navSlider.slickGoTo(i);
                            }}>
                              <img src={item.icon[i18n.language]} />
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>

                    <div className="con2 mt-3 mt-sm-5">
                      <Slider
                        ref={slider => setConSlider(slider)}
                        asNavFor={navSlider}
                        {...conSliderSettings}
                      >
                        {slides.map((item, i) => (
                          <div className='con' key={i}
                            dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}
                          ></div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </section>
  )
};

const mapStateToProps = state => ({
  faq: state.shared.faq,
  how_to_banner: state.shared.how_to_banner,
});

export default connect(mapStateToProps)(HowTo);