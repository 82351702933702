import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";

const RegistrationEventResult = ({  }) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [registerDetail, setRegisterDetail] = useState(null);
    const navigate = useHistory();

    //set html_title
    useEffect(() => {
        document.title = t('html_title.registration_success')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => {
        document.title = t('html_title.registration_success')+' | '+  t('html_title.home');
    }, [i18n.language]);

    useEffect(() => {
        if (location && location.state){
            setRegisterDetail(location.state?.state.result);
        } else {
            navigate.push(`/`)
        }
    }, [location]);

    return (
      <section className="mainContents mainContents2">
        <div className="container conWrapper">
          <div className="row">
            <div className="col-sm-4 contents m-auto">
              <div className="resultBox d-flex col-12 flex-wrap">
                <div>
                    { registerDetail?.message[i18n.language] ?
                      <>
                        <p className="mt-5 text-center" dangerouslySetInnerHTML={{ __html:registerDetail?.message[i18n.language]}}></p>
                      </>
                      :
                      // if cms static content no result message for event registration
                      <>
                        <p className="mt-5 text-center">{t('events.' + registerDetail?.message)} </p>
                      </>
                    }
                    <br></br>
                    <br></br>
                    <Link className="btn " to={`/${i18n.language}/reward-list`}>
                        <b>{t('rewards.back_to_reward_list')}</b>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

const mapStateToProps = state => ({
});
export default connect(mapStateToProps)(RegistrationEventResult);
