import { makeApiCalls } from "../store/api";

export function getStaticContent() {

  return makeApiCalls(
    [
      {
        endpoint: 'PUBLIC_GET_STATIC_CONTENT',
      }
    ],
  ).then((responses) => responses[0]?.data);
}

export function getHomepagePopup(

) {
    return makeApiCalls(
        [
            {
                endpoint: 'PUBLIC_GET_HOMEPAGE_POPUP',
            }
        ],
    ).then((responses) => responses[0]?.data);
}



