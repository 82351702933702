import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import ReactLoadingSpinner from './ReactLoadingSpinner';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Popup from './Popup';
import axios from 'axios';

const VerifyEmail = ({
  email,
  resendEmailPath,
  allowSkip = false,
  withoutTemplate = false,
  message = null,
  onSkip = () => { },
}) => {
  const navigate = useHistory();
  const { user, loginWithRedirect } = useAuth0();
  const [t, i18n] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  let timeout = null;

  const onResend = async () => {
    if (isLoading || !resendEmailPath) return;
    setIsLoading(true);

    try {
      const res = await axios.post(resendEmailPath, { email });
      setShowPopup(true);
    } catch (ex) {
      console.error(ex);
    }

    setIsLoading(false);
  }

  const check = async () => {
    if (user.email_verified) return;

    timeout = setTimeout(() => {
      loginWithRedirect({
        type: 'login',
        lang: i18n.language,
        redirectUri: `${window.location.origin}/login`,
        appState: {
          page: navigate.location.pathname
        }
      });
    }, (parseInt(process.env.REACT_APP_VERIFY_EMAIL_AUTO_RELOAD_TIME_IN_SECOND) || 60) * 1e3);
  }

  const onSkipClick = () => {
    clearTimeout(timeout);
    onSkip();
  }

  useEffect(() => {
    if (user) check();

    // cleanup
    return () => {
      clearTimeout(timeout);
    }
  }, [user]);

  return (
    <>
      {showPopup && <Popup
        title={t('verify_email.popup_title')}
        message={t('verify_email.popup_message')}
        buttons={[
          {
            label: t('button.close'),
            callback: () => {
              setShowPopup(false);
            }
          }
        ]}
      />}

      {isLoading && <ReactLoadingSpinner />}

      {!withoutTemplate && (
        <section className="mainContents">
          <div className="container conWrapper">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-8 contents p-4">
                <h3 className="text-uppercase text-center redText fw-bold mb-5">
                  {t('verify_email.title')}
                </h3>
                <p>
                  {message || t('verify_email.content')}
                </p>
                <div className="d-flex mb-3 col-12">
                  <button type="button" className="btn me-3" onClick={onResend} disabled={!email}>
                    {t('verify_email.resend')}
                  </button>
                  <button type="button" className="btn btn-cancel" onClick={() => navigate.go(navigate.location.pathname)}>
                    {t('verify_email.reload')}
                  </button>
                </div>
                <p className='text-align-center'><small>
                  {t('verify_email.reload_hint')}
                </small></p>
                {allowSkip && (
                  <div className='text-end'>
                    <small>
                      <a className='btn-link text-decoration-none' onClick={onSkipClick}>
                        {t('button.verify_later')}
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right icons ms-1">
                          <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                        </svg>
                      </a>
                    </small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      {withoutTemplate && (
        <>
          <h3 className="text-uppercase text-center redText fw-bold mb-3">
            {t('verify_email.title')}
          </h3>
          <p className='text-center mb-4'>
            {message || t('verify_email.content')}
          </p>
          <div className="d-flex mb-3 col-12 flex-wrap flex-sm-nowrap">
            <button type="button" className="btn me-sm-3 mb-3 mb-sm-0" onClick={onResend} disabled={!email}>
              {t('verify_email.resend')}
            </button>
            <button type="button" className="btn btn-cancel" onClick={() => navigate.go(navigate.location.pathname)}>
              {t('verify_email.reload')}
            </button>
          </div>
          <p className='text-center mb-3'><small>
            {t('verify_email.reload_hint')}
          </small></p>
        </>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  resendEmailPath: state.shared.auth0_resend_email_path
});

export default connect(mapStateToProps)(VerifyEmail);