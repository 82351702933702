import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { confirmAndVerifyScanTicket } from '../services/ticket';
import { dateFormat } from '../services/helpers';
import { ScanResultStatus } from '../services/enums';


const ScanPreview = () => {
  const { t, i18n } = useTranslation();
  const [ticketInfo, setTicketInfo] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const location = useLocation();
  const navigate = useHistory();
  const [disableConfirmBtn, setDisableConfirmBtn] = useState(false);

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.scan_preview')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.scan_preview')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    if (location && location.state){
      setTicketInfo(location.state.state.ticket_info);
      setTicketStatus(location.state.state.scan_ticket_status);
    } else {
      navigate.push(`/`)
    }
  }, [location]);

  const onConfirm = () => {
    if (!disableConfirmBtn) {
      setDisableConfirmBtn(true);
      if (ticketStatus === ScanResultStatus.pending) {
        navigate.push(`/${i18n.language}/scan-success`, {
          state: {
            scan_ticket_status: ticketStatus,
            ticket_info: ticketInfo || null,
          }
        });
      }
      else {
        confirmAndVerifyScanTicket(ticketInfo).then((res) => {
          if (res.status === ScanResultStatus.success || res.status === ScanResultStatus.pending) { // case 3: add points
            navigate.push(`/${i18n.language}/scan-success`, {
              state: {
                scan_ticket_status: res.status,
                ticket_info: res.ticket_info || null,
              }
            });
          } else { // case: invalid (0 points) || case 4: expired || case 5: duplicate
            navigate.push(`/${i18n.language}/scan-failed`, {
              state: {
                scan_ticket_status: res.status,
                ticket_info: res.ticket_info || null,
              }
            })
          }
        });
      }
    } else {
      console.log('disabled')
    }
  }

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-sm-6 contents m-auto">
            <div className="loginBox d-flex col-12 confirmTicket">
              <div className="col-12">
                {
                  ticketStatus && (ticketStatus === ScanResultStatus.pending || ticketStatus === ScanResultStatus.invalid || ticketStatus === ScanResultStatus.not_found) ? (
                      <p>{t('scan_your_ticket.pending_and_invalid')}</p>
                  ) : (
                      <>
                        <h5 className="title2">{t('scan_your_ticket.ticket')}</h5>
                        <div className="col-12">
                          <p className="mt-4 mb-2">
                            <strong>{t('scan_your_ticket.ref_number')}</strong>
                            {ticketInfo?.ticket_no}
                            <br />
                            <strong>{t('scan_your_ticket.concert_name')}</strong>
                            {
                              i18n.language === 'tc' ?
                                  ticketInfo?.tc_concert_name :
                                  i18n.language === 'sc' ?
                                      ticketInfo?.sc_concert_name :
                                      ticketInfo?.concert_name
                            }
                            <br />
                            <strong>{t('scan_your_ticket.performance_date')}</strong>
                            {dateFormat(ticketInfo?.performance_datetime)}
                            <br />
                            <strong>{t('scan_your_ticket.performance_time')}</strong>
                            {ticketInfo?.performance_datetime.split(' ')[1]}
                            <br />
                            <strong>{t('scan_your_ticket.seat_number')}</strong>
                            {ticketInfo?.seat}
                            <br />
                            <strong>{t('scan_your_ticket.price')}</strong>
                            {ticketInfo?.amount}
                            <br />
                          </p>
                        </div>
                      </>
                  )
                }
                <div className="col-12 formCol d-flex flex-wrap flex-sm-nowrap mt-5">
                  <button className="btn" type="button"
                    disabled={disableConfirmBtn}
                    onClick={onConfirm}
                  >
                    {t('button.confirm')}
                  </button>
                  <Link className="btn btn-outline ms-sm-3 mt-3 mt-sm-0" to={`/${i18n.language}/scanner`}>
                    {t('button.retake')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blankSpace"></div>
    </section>
  )
}


export default ScanPreview;
