import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import SimpleSlider from '../components/SimpleSlider';
import { dateFormat } from '../services/helpers';
import UserName from '../components/UserName';
import { useEffect, useState } from "react";
import { getHomepagePopup } from "../services/staticContent";
import Popup from "../components/Popup";
import { useHistory } from "react-router-dom";

const Index = ({ profile, highlightRewards, home_grid }) => {
    const { t, i18n } = useTranslation();
    const [popupData, setPopupData] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showCompleteProfileReminder, setShowCompleteProfileReminder] = useState(false);
    const [upgradableButIncompleteProfile, setUpgradableButIncompleteProfile] = useState(false);
    const history = useHistory();
    const goToMemberDetailEditPage = () => {
        history.push(`/${i18n.language}/member-detail-edit`);
    }
    useEffect(() => { 
        document.title = t('html_title.home') 
    }, []);

    useEffect(() => { 
        document.title = t('html_title.home') 
    }, [i18n.language]);

    useEffect(() => {
        if (!profile) return;

        // check if user is eligible to upgrade but incomplete profile
        const isUpgradableButIncomplete = !profile.is_profile_completed &&
            (
                profile.upgrade_progress.annualTicket >= profile.upgrade_progress.nextUpgradeTicketsPurchased ||
                profile.upgrade_progress.annualNumberOfProgramSynonyms >= profile.upgrade_progress.nextUpgradeProgramSynonyms ||
                profile.upgrade_progress.totalAnnualEarnedPoint >= profile.upgrade_progress.nextUpgradeTicketSpend
            );

        setUpgradableButIncompleteProfile(isUpgradableButIncomplete);

        if (isUpgradableButIncomplete) {
            setShowCompleteProfileReminder(true);
        }
    }, [profile]);

    useEffect(() => {
        const fetchData = async () => {
            getHomepagePopup().then((res) => {
                if (res.title && res.content) {
                    setPopupData(res);
                    setShowPopup(true);
                }
            })
        }
        fetchData();
    }, []);

    return (
        <>
            <div>
                {showPopup && <Popup
                    title={popupData.title[i18n.language]}
                    message={popupData.content[i18n.language]}
                    buttons={[
                        {
                            label: t('button.close'),
                            className: 'disabled',
                            callback: () => setShowPopup(false)
                        },
                    ]}/>}
            </div>

            <div>
                {showCompleteProfileReminder && <Popup
                    title={t('about_club_bravo_tier.complete_profile_reminder')}
                    buttons={[
                        {
                            label: t('button.go_to_profile'),
                            className: 'disabled',
                            callback: () => goToMemberDetailEditPage()
                        },
                        {
                            label: t('button.close'),
                            className: 'disabled',
                            callback: () => setShowCompleteProfileReminder(false)
                        },
                    ]}
                />}
            </div>

            <section className="homebanner">
                <div className="container-fluid">
                    <div className="row">
                        {
                            profile ?
                                (
                                    <div className="col-12 col-sm-8 herobanner bannerl d-flex align-items-center flex-wrap px-5 justify-content-sm-end">
                                        <div className="d-flex col-12 flex-wrap hblCon flex-wrap justify-content-sm-end">
                                            <div className="col-12 col-sm-6 text-left welcome">
                                                <p className="title1">{t('user.welcome_back')},&nbsp;</p>
                                                <p className="title2">
                                                    <UserName profile={profile} />
                                                </p>
                                            </div>
                                            <div className="bannerText col-12 align-items-end d-flex flex-wrap pointBox justify-content-center justify-content-sm-end">
                                                <div className="d-flex flex-wrap flex-sm-column">
                                                    <p className="points">
                                                        {t('homepage.point_balance')}:
                                                        <br />
                                                        <span className="cb-logo-white">b</span> {profile?.available_points.toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-12 col-sm-8 herobanner bannerl d-flex align-items-center justify-content-center justify-content-lg-end flex-wrap px-5">
                                        <div className="d-flex col-12 flex-wrap hblCon">
                                            <div className="text-left logo">
                                                {/* <img src="/assets/img/logo_bravo.svg" /> */}
                                            </div>
                                            <div className="bannerText text-center d-flex align-items-center flex-wrap justify-content-center">
                                                <p>
                                                    {t('homepage.experience_new_cb')}
                                                </p>
                                                <Link style={{ textDecoration: "none" }} to={`/${i18n.language}/registration`}>
                                                    <button type="button" className="classic-button join-for-free-btn">
                                                        {t('homepage.join_for_free')}
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                        <div className="col-12 col-sm-4 herobanner bannerr d-flex align-items-center justify-content-center justify-content-lg-start px-5">
                            <div className="d-flex col-12 flex-wrap hbrCon">
                                <Link to={`/${i18n.language}/scan-intro`}>
                                    <div className="bannerText col-12 col-sm-6 text-center"><img src="/assets/img/icon_ht_1.svg" />
                                        <h3>{t('registration_success.scan_ticket')}</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mainContents mainContents2 mainContents3">
                <div className="container conWrapper">
                    <div className="row">
                        <div className="col-12 contents contents2">
                            <div className="col-12 flex-wrap homeCons">
                                <div className="topTitle">
                                    <h1>
                                        {t('homepage.highlight_rewards')}
                                    </h1>
                                </div>
                                <div className="redeemItems slides slide1">
                                    <SimpleSlider rewards={highlightRewards} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mainContents mainContents2">
                <div className="container conWrapper">
                    <div className="row">
                        <div className="col-12 contents contents4">
                            {
                                home_grid?.map((item, i) => (
                                    <div className={`d-flex col-12 con1 flex-wrap ${i%2 != 0 && 'flex-row-reverse'}`} key={i}>
                                        {/* img gird */}
                                        <div className="d-flex col-12 col-sm-6 align-items-center justify-content-center flex-wrap clubBravo">
                                            <img className="img-fluid" src={item.icon[i18n.language]} />
                                        </div>
                                        {/* text gird */}
                                        <div className="d-flex col-12 col-sm-6 align-items-center justify-content-center flex-wrap bgClubBravo">
                                            <div className="col-10 col-sm-8">
                                                <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="my-5"></div>
                        </div>
                    </div>
                </div>
            </section>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/litepicker/2.0.11/litepicker.js"></script>
        </>
    )
};

const mapStateToProps = state => ({
    profile: state.shared.profile,
    home_grid: state.shared.home_grid,
    highlightRewards: state.shared.highlight_rewards,
});

export default connect(mapStateToProps)(Index);
