import { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { dateFormat } from '../services/helpers';
import { getUserProfile } from '../services/user';
import { sharedSetItem, SharedSetItemType } from '../store/shared';
import { useAuth0 } from '@auth0/auth0-react';
import { ScanResultStatus } from '../services/enums';

const ScanSuccess = ( {
  scan_pending, 
  scan_success, 
  setProfile
} ) => {
  const { user } = useAuth0();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [ticketInfo, setTicketInfo] = useState(null);
  const [scanStatus, setScanStatus] = useState<ScanResultStatus>(null);
  const navigate = useHistory();

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.scan_success')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.scan_success')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    if (location && location.state){
      setScanStatus(location.state.state.scan_ticket_status);
      setTicketInfo(location.state.state.ticket_info);
    } else {
      navigate.push(`/`)
    }
  }, [location]);

  // update profile available points
  useEffect(() => {
    if (user) assignUserProfile(user);
  }, [user]);

  const assignUserProfile = async (credential) => {
      const profile = await getUserProfile(credential);
      setProfile(profile);
  };

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-sm-6 contents m-auto">
            <div className="resultBox d-flex col-12 flex-wrap">
              <div>
                  { scanStatus === ScanResultStatus.pending &&
                    scan_pending?.map((item, i) => (
                      <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                    ))
                  }
                  {/* duplicated top content */}
                  { scanStatus === ScanResultStatus.success &&
                    scan_success?.filter(item => {return item.seq < 101}).map((item, i) => (
                      <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                    ))
                  }
                { scanStatus === ScanResultStatus.success && 
                <>
                  {
                    ticketInfo && 
                    <p className="mt-4 mb-2">
                        <strong>{t('scan_your_ticket.ref_number')}</strong>
                        {ticketInfo?.ticket_no}
                        <br />
                        <strong>{t('scan_your_ticket.concert_name')}</strong>
                        {
                          i18n.language == 'tc' ?
                          ( ticketInfo?.tc_concert_name || ticketInfo?.concert_name)
                          :
                          i18n.language == 'sc' ?
                          (ticketInfo?.sc_concert_name || ticketInfo?.concert_name)
                          :
                          ticketInfo?.concert_name
                        }
                        <br />
                        <strong>{t('scan_your_ticket.performance_date')}</strong>
                        {dateFormat(ticketInfo?.performance_datetime)}
                        <br />
                        <strong>{t('scan_your_ticket.performance_time')}</strong>
                        {ticketInfo?.performance_datetime?.split(' ')[1]}
                        <br />
                        <strong>{t('scan_your_ticket.seat_number')}</strong>
                        {ticketInfo?.seat}
                        <br />
                        <strong>{t('scan_your_ticket.price')}</strong>
                        {ticketInfo?.amount}
                      </p>
                  }
                </>
                }
                  {/* duplicated top content */}
                  { scanStatus === ScanResultStatus.success &&
                    scan_success?.filter(item => {return item.seq > 100}).map((item, i) => (
                      <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                    ))
                  }
                <div className="col-12 formCol d-flex flex-wrap mt-5">
                  <Link className="btn" style={{ textDecoration: "none" }} to={`/${i18n.language}/scanner`}>
                    <b>{t('scan_your_ticket.scan_another')}</b>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

const mapStateToProps = state => ({
  scan_pending: state.shared.scan_pending,
  scan_success: state.shared.scan_success,
});

const mapDispatchToProps = (dispatch) => ({
  setProfile: (value) => dispatch(sharedSetItem(SharedSetItemType.profile, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanSuccess);
