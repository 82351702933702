import { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { dateFormat } from '../services/helpers';
import { ScanResultStatus } from '../services/enums';


const ScanFailed = ({ scan_duplicated, scan_expired, scan_invalid }) => {
  const { t, i18n } = useTranslation();
  const [scanStatus, setScanStatus] = useState<ScanResultStatus>(null);
  const [ticketInfo, setTicketInfo] = useState(null);
  const location = useLocation();
  const navigate = useHistory();

  //set html_title
  useEffect(() => { 
      document.title = t('html_title.scan_failed')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.scan_failed')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    if (location && location.state){
      setScanStatus(location.state.state.scan_ticket_status);
      setTicketInfo(location.state.state.ticket_info);
    } else {
      navigate.push(`/`)
    }
  }, [location]);

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-sm-6 contents m-auto">
            <div className="resultBox d-flex col-12 flex-wrap">
                <div>
                  {scanStatus === ScanResultStatus.expired &&
                    scan_expired?.map((item, i) => (
                      <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                    ))
                  }
                  {(scanStatus === ScanResultStatus.invalid || scanStatus === ScanResultStatus.not_found) &&
                    scan_invalid?.map((item, i) => (
                      <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                    ))
                  }
                  {/* duplicated top content */}
                  {scanStatus === ScanResultStatus.duplicated &&
                    scan_duplicated?.filter(item => { return item.seq < 101 }).map((item, i) => (
                      <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                    ))
                  }
                  {/*  duplicated ticket Info */}
                  {scanStatus === ScanResultStatus.duplicated &&
                    <>
                      {ticketInfo &&
                        <p className="mt-4 mb-2">
                          <strong>{t('scan_your_ticket.ref_number')}</strong>
                          {ticketInfo?.ticket_no}
                          <br />
                          <strong>{t('scan_your_ticket.concert_name')}</strong>
                          {
                            i18n.language === 'tc' ?
                              ticketInfo?.tc_concert_name :
                              i18n.language === 'sc' ?
                                ticketInfo?.sc_concert_name :
                                ticketInfo?.concert_name
                          }
                          <br />
                          <strong>{t('scan_your_ticket.performance_date')}</strong>
                          {dateFormat(ticketInfo?.performance_datetime)}
                          <br />
                          <strong>{t('scan_your_ticket.performance_time')}</strong>
                          {ticketInfo?.performance_datetime.split(' ')[1]}
                          <br />
                          <strong>{t('scan_your_ticket.seat_number')}</strong>
                          {ticketInfo?.seat}
                          <br />
                          <strong>{t('scan_your_ticket.price')}</strong>
                          {ticketInfo?.amount}
                        </p>
                      }
                    </>
                  }
                  {/* duplicated bottom content */}
                  {scanStatus === ScanResultStatus.duplicated &&
                    scan_duplicated?.filter(item => { return item.seq > 100 }).map((item, i) => (
                      <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                    ))
                  }
                  <div className="col-12 formCol d-flex flex-wrap mt-5">
                    <Link className="btn" style={{ textDecoration: "none" }} to={scanStatus === ScanResultStatus.invalid || scanStatus === ScanResultStatus.not_found ? `/${i18n.language}/scan-intro` : `/${i18n.language}/scanner`}>
                      <b>
                        {t('scan_your_ticket.scan_another')}
                      </b>
                    </Link>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

const mapStateToProps = state => ({
  scan_duplicated: state.shared.scan_duplicated,
  scan_expired: state.shared.scan_expired,
  scan_invalid: state.shared.scan_invalid,
});
export default connect(mapStateToProps)(ScanFailed);
