import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import RewardInfo from '../components/RewardInfo';
import WelcomeBackBar from '../components/WelcomeBackBar';
import { getEvent, getRedeemItem } from '../services/redeem';
import { RewardType } from '../constants';
import {connect} from "react-redux";

const RewardDetail = ({ rewardType, profile}) => {
  const { alias } = useParams();
  const { t, i18n } = useTranslation();
  const [reward, setReward] = useState(null);
  const currentDate = `${new Date().toISOString().split('T')[0]}`;

  //set html_title
  useEffect(() => { 
    document.title = t('html_title.reward_detail')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
    document.title = t('html_title.reward_detail')+' | '+  t('html_title.home');
  }, [i18n.language]);

  useEffect(() => {
    // Use different API endpoints to obtain reward item detail depending on the reward type
    if (rewardType == RewardType.ITEM) {
      getRedeemItem(alias).then((res) => setReward(res.rewardItem));
    } else {
      getEvent(alias).then((res) => setReward(res.event));
    }
  }, []);

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper">
        <div className="row">
          <div className="col-12 contents contents2">
            <div className="redeemCons redeemDetail">
              <WelcomeBackBar showAvailablePoints={true} />
              <div className="con2">
                <h3 className="mb-4">
                  {
                    reward && (!reward.reward_visibility_tiers || reward.reward_visibility_tiers.includes(profile?.membership_tier))
                      ? (<span>{reward.name[i18n.language]}</span>) : null
                  }
                </h3>
              </div>

              <RewardInfo reward={reward} rewardType={rewardType}/>

              <div className="col-12 mt-4 mb-4 d-flex flex-wrap btns">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobileOn blankSpace"></div>
      <div className="mobileOn blankSpace"></div>
    </section>
  )
};

const mapStateToProps = state => ({
  profile: state.shared.profile,
});
export default connect(mapStateToProps)(RewardDetail);
