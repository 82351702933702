import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from "react";
import { registrationForm } from "../services/enums";

const Registration = ({ profile, registration }) => {
    const navigate = useHistory();
    const { t, i18n } = useTranslation();
    const { loginWithRedirect } = useAuth0();

    //set html_title
    useEffect(() => { 
        document.title = t('html_title.registration')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => { 
        document.title = t('html_title.registration')+' | '+  t('html_title.home');
    }, [i18n.language]);

    useEffect(()=>{
        if (profile?.is_submitted_profile == registrationForm.submitted)  navigate.push(`/${i18n.language}/member-detail`);
    },[profile])

    const onCardClicked = async (type) => {
        loginWithRedirect({
            type: 'register',
            lang: i18n.language,
            redirectUri: `${window.location.origin}/login`,
            appState: {
                page: `/${i18n.language}/registration-${type}`
            }
        });
    }

    return (
        <section className="mainContents">
            <div className="container conWrapper">
                <div className="row">
                    <div className="col-sm-12">
                        <div className='contents'>
                            <h1 className="title1 text-center w-100">{t('registration_common.join_club_bravo')}</h1>
                            <p className="text-center w-100">{t('registration_common.subtitle')}</p>
                            <div className="regBoxes d-flex">

                                {
                                    registration &&
                                    registration[0] &&
                                    registration[0].content && (
                                        <a className="regBox"
                                           onClick={() => navigate.push(`/${i18n.language}/registration-existing`)}
                                           dangerouslySetInnerHTML={{
                                               __html:
                                                   i18n.language === 'tc'
                                                       ? registration[0].content['tc']
                                                       : i18n.language === 'sc'
                                                           ? registration[0].content['sc']
                                                           : registration[0].content['en'],
                                           }}
                                        >
                                        </a>
                                    )}

                                {
                                    registration &&
                                    registration[1] &&
                                    registration[1].content && (
                                        <a
                                            className="regBox"
                                            target="_blank"
                                            onClick={() => onCardClicked('free')}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    i18n.language === 'tc'
                                                        ? registration[1].content['tc']
                                                        : i18n.language === 'sc'
                                                            ? registration[1].content['sc']
                                                            : registration[1].content['en'],
                                            }}
                                        ></a>
                                    )
                                }

                                {
                                    registration &&
                                    registration[2] &&
                                    registration[2].content && (
                                        <a
                                            className="regBox"
                                            onClick={() => onCardClicked('red')}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    i18n.language === 'tc'
                                                        ? registration[2].content['tc']
                                                        : i18n.language === 'sc'
                                                            ? registration[2].content['sc']
                                                            : registration[2].content['en'],
                                            }}
                                        ></a>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

const mapStateToProps = state => ({
    profile: state.shared.profile,
    registration: state.shared.registration,
});

export default connect(mapStateToProps)(Registration);
