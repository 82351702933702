import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import configureStore, { history } from './configureStore';

const store = configureStore({});

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={`${window.location.origin}/login`} // This must be whitelisted on Auth0. Any path will do because we don't actually redirect the browser to it
        useRefreshTokens={true}
      >
        <App history={history} />
      </Auth0Provider>
    </Provider>,
    document.getElementById('root')
  );
}

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();