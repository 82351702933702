import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import WelcomeBackBar from '../components/WelcomeBackBar';
import { dateFormat, dateFormatDob, getAddress } from '../services/helpers';
import BackButton from '../components/BackButton';
import { useEffect, useState } from 'react';

const PersonalDetail = ({ profile, countries }) => {
  const { t, i18n } = useTranslation();
  const [isBlack, setIsBlack] = useState(true);
  
  //set html_title
  useEffect(() => { 
      document.title = t('html_title.personal_detail')+' | '+  t('html_title.home');
  }, []);
  useEffect(() => { 
      document.title = t('html_title.personal_detail')+' | '+  t('html_title.home');
  }, [i18n.language]);
  
  useEffect(() => {
    if (!profile) return;
    setIsBlack(!profile.membership_tier || profile.membership_tier === 'Black');
  }, [profile]);

  return (
    <section className="mainContents mainContents2">
      <div className="container conWrapper conWrapper2">
        <div className="row">
          <div className="col-12 contents contents2">
            <div className="redeemCons">
              <WelcomeBackBar showAvailablePoints={false} />
            </div>
          </div>
        </div>
      </div>
      <div className="container conWrapper conWrapper2">
        <div className="row">
          <div className="col-12 contents contents2">
            <div className="redeemCons redeemCons2">
              <div className="con2 mt30">
                <h5 className="title2 redText mb-4">{t('landing_page.personal_details')}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container conWrapper">
        <div className="row">
          <div className="col-12 contents contents2">
            <div className="con2">
              <div className="d-flex justify-content-between flex-wrap">
                <p className="col-12 col-sm-6 text-center text-sm-start"></p>
              </div>
            </div>
            <div className="profiles d-flex flex-wrap">
              <div className="col-12 flex-wrap d-flex justify-content-between status">
                <div className="d-flex justify-content-between col-12 col-sm-6 flex-wrap">
                  <h5 className="col-12 col-sm-auto m-0 mb-2"><strong>{t('landing_page.membership')}</strong><br /></h5>
                  <div className="col-12">
                    {!isBlack &&<h6 className="title">{t(`landing_page.account_number`).toUpperCase()}</h6>}
                    {!isBlack && <p className="mb-2">{profile?.member_no || '--'}</p>}

                    <h6 className="title">{t('landing_page.membership_status')}</h6>
                    <p className="mb-2">{t(`landing_page.club_bravo_${profile?.membership_tier || 'Black'}`)}</p>

                    {!isBlack && <h6 className="title">{t('landing_page.expiry_date')}</h6>}
                    {/* <p className="title m-0">{t('landing_page.expiry_date')}</p> */}
                    {!isBlack && <p className="m-0 mb-2">{dateFormat(profile?.tier_expiry_date) || '--'}</p>}
                  </div>
                </div>
                {/* TODO may confirm later */}
                {/* <div className="d-flex col-12 col-sm-6 justify-content-center justify-content-sm-end flex-wrap cons align-items-center mt-4 mt-sm-0">
                  <button type="button">Renew Membership</button>
                </div> */}
              </div>

              <div className="conBox col-12 col-sm-6">
                <div className="d-flex col-12 con1 justify-content-between flex-wrap">
                  <h6 className="mb-3"><strong>{t('landing_page.account_details')}</strong></h6>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.name')}</h6>
                      <p>{profile?.first_name || '--'} {profile?.last_name || '--'}</p>
                    </div>
                  </div>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.chinese_name')}</h6>
                      <p>{profile?.chinese_name || '--'}</p>
                    </div>
                  </div>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.date_of_birth')}</h6>
                      <p>{dateFormatDob(profile?.dob) || '--'}</p>
                    </div>
                  </div>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.gender')}</h6>
                      <p>{profile?.gender ? t(`landing_page.${profile?.gender}`) : '--'}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="conBox col-12 col-sm-6">
                <div className="d-flex col-12 con1 justify-content-between flex-wrap">
                  <h6 className="mb-3"><strong>{t('registration_common.contact_details')}</strong><br /></h6>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.phone')}</h6>
                      <p>
                        {profile?.country_code && <>+{profile?.country_code} </>}
                        {profile?.mobile || '--'}
                      </p>
                    </div>
                  </div>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.email')}</h6>
                      <p>{profile?.email || '--'}</p>
                    </div>
                  </div>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.address')}</h6>
                      <p>{getAddress(profile?.shipping_address_1?.at(0)?.memberProfile, countries) || '--'}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="conBox col-12 col-sm-6">
                <div className="d-flex col-12 con1 justify-content-between flex-wrap">
                  <h6 className="mb-3"><strong>{t('registration_common.your_preference')}</strong><b /></h6>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.your_interest')}</h6>
                      <p>
                        {(profile?.instruments || []).map(instrument => instrument.name).join(', ') || '--'}
                      </p>
                    </div>
                  </div>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.preferred_language')}</h6>
                      <p>
                        {t(`lang.${profile?.preferred_lang || 'en'}`)}
                      </p>
                    </div>
                  </div>
                  <div className="cons col-12">
                    <div>
                      <h6 className="title">{t('landing_page.consent_for_receiving_email')}</h6>
                      <p>{t(`landing_page.${profile?.receive_email_promotion ? 'yes' : 'no'}`)}</p>
                    </div>
                  </div>
                </div>
              </div>
              {
                profile?.ya_status === 'approved' &&
                (
                  <div className="conBox col-12 col-sm-6">
                    <div className="d-flex col-12 con1 justify-content-between flex-wrap">
                      <h6 className="mb-3"><strong>Student Details</strong><br /></h6>
                      <div className="cons col-12">
                        <div>
                          <h6 className="title">SCHOOL</h6>
                          <p>Hong Kong High School</p>
                        </div>
                      </div>
                      <div className="cons col-12">
                        <div>
                          <h6 className="title">INSTRUMENT</h6>
                          <p>Clarinet, Cello</p>
                        </div>
                      </div>
                      <div className="cons col-12">
                        <div>
                          <h6 className="title">STUDENT ID</h6>
                          {/* <a href="#">View Photo</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="col-12 mt-4 mb-4 d-flex flex-wrap btns">
                <Link className="col-12 col-sm-4 me-4" to={`/${i18n.language}/member-detail-edit`}>
                  <button className="btn" type="button">
                    {t('landing_page.edit')}
                  </button>
                </Link>
                <Link className="col-12 col-sm-4" to={`/${i18n.language}/member-detail`}>
                  <button className="btn btn-outline" type="button">
                    {t('landing_page.back_to_member_detail')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobileOn blankSpace"></div>
    </section>
  )
};

const mapStateToProps = state => ({
  profile: state.shared.profile,
  countries: state.shared.countries,
});

export default connect(mapStateToProps)(PersonalDetail);