import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import i18n from "../../i18n";
import FormLabel from "./FormLabel";

const ContactDetail = ({ formData, handleFormData, active, countries, requiredFields = null }) => {
  const { t } = useTranslation();

  return (
    <div id="formPart1" className={`formSteps step1 col-12 flex-wrap ${active ? 'curStep' : ''}`}>
      <div className="formCol col-12">
        <FormLabel
          title={t('landing_page.email')}
          required={requiredFields?.email}
        />
        <input
          className="form-control formInput"
          name="email"
          type="email"
          onChange={handleFormData}
          value={formData?.email || ''}
          disabled={true}
        />
      </div>
      <div className="formCol col-12 col-sm-5" >
        <FormLabel
          title={t('landing_page.country_code')}
          required={requiredFields?.country_code}
        />
        <select
          className="form-select formInput"
          name='country_code'
          value={formData?.country_code || '852'} // set default country code is 852
          onChange={handleFormData}
        >
          <option value="" disabled>--</option>
          {(countries || []).map(option => (
            <option key={option.country_code} value={option.country_code}>
              +{option.country_code+' '+option.name[i18n.language]} 
            </option>
          ))}
        </select>
      </div>
      <div className="formCol col-12 col-sm-4 formLP-20">
        <FormLabel
          title={t('landing_page.mobile')}
          required={requiredFields?.mobile}
        />
        <input
          className="form-control formInput"
          name="mobile"
          type="mobile"
          onChange={handleFormData}
          value={formData?.mobile || ''}
        />
      </div>
      <div className="formCol col-12 col-sm-4">
        <FormLabel
          title={t('landing_page.flat_or_unit')}
          required={requiredFields?.flat_or_unit}
        />
        <input
          className="form-control formInput"
          name="flat_or_unit"
          type="text"
          onChange={handleFormData}
          value={formData?.flat_or_unit || ''}
        />
      </div>
      <div className="formCol col-12 col-sm-4 formLP-20">
        <FormLabel
          title={t('landing_page.floor')}
          required={requiredFields?.floor}
        />
        <input
          className="form-control formInput"
          name="floor"
          type="text"
          onChange={handleFormData}
          value={formData?.floor || ''}
        />
      </div>
      <div className="formCol col-12 col-sm-4 formLP-20">
        <FormLabel
          title={t('landing_page.block_or_phase')}
          required={requiredFields?.block_or_phase}
        />
        <input
          className="form-control formInput"
          name="block_or_phase"
          type="text"
          onChange={handleFormData}
          value={formData?.block_or_phase || ''}
        />
      </div>
      <div className="formCol col-12 col-sm-7">
        <FormLabel
          title={t('landing_page.house_or_building_estate')}
          required={requiredFields?.house_or_building_estate}
        />
        <div className="d-flex align-items-center">
          <input
            className="form-control formInput"
            name="house_or_building_estate"
            type="text"
            onChange={handleFormData}
            value={formData?.house_or_building_estate || ''}
          />
        </div>
      </div>
      <div className="formCol col-12 col-sm-5 formLP-20">
        <FormLabel
          title={t('landing_page.road_or_street')}
          required={requiredFields?.road_or_street}
        />
        <div className="d-flex align-items-center">
          <input
            className="form-control formInput"
            name="road_or_street"
            type="text"
            onChange={handleFormData}
            value={formData?.road_or_street || ''}
          />
        </div>
      </div>
      <div className="formCol col-12 col-sm-4">
        <FormLabel
          title={t('landing_page.district')}
          required={requiredFields?.district}
        />
        <div className="d-flex align-items-center">
          <input
            className="form-control formInput"
            name="district"
            type="text"
            onChange={handleFormData}
            value={formData?.district || ''}
          />
        </div>
      </div>
      <div className="formCol col-12 col-sm-3 formLP-20">
        <FormLabel
          title={t('landing_page.country')}
          required={requiredFields?.country}
        />
        <div className="d-flex align-items-center">
          <select
            className="form-select formInput"
            name='country'
            value={formData?.country || ''}
            onChange={handleFormData}
          >
            <option value="" disabled>--</option>
            {(countries || []).map(option => (
              <option key={option.id} value={option.id}>
                {option.name[i18n.language]}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  countries: state.shared.countries
});

export default connect(mapStateToProps)(ContactDetail);