import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getJwtToken } from '../services/user';
import WelcomeBackBar from '../components/WelcomeBackBar';
import SimpleSlider from '../components/SimpleSlider';
import { useTranslation } from 'react-i18next'
import LoginButton from '../components/LoginButton';
import TitleBar from '../components/TitleBar';
import {RedeemedItemCategories} from "../constants";

const RewardList = ({
    profile,
    IsRedeemItemsReady,
    allYearRounds,
    redeems,
    specialOffers,
    artsAndCultures,
    bookstoreAndCulturalInstitutions,
    lifestyles,
    musicCentreAndInstitutions,
    restaurants,
    redeemed_item_categories,
    events
}) => {
    const { t, i18n } = useTranslation();
    const [rewards, setRewards] = useState([]);

    //set html_title
    useEffect(() => { 
        document.title = t('html_title.reward_list')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => { 
        document.title = t('html_title.reward_list')+' | '+  t('html_title.home');
    }, [i18n.language]);

    useEffect(() => {
        if (redeemed_item_categories) {
            const orderedRewards = redeemed_item_categories.map(category => {
                const title = (() => {
                    switch (category?.name['en']) {
                        case RedeemedItemCategories.EVENT:
                            return 'event';
                        case RedeemedItemCategories.REWARDS:
                            return 'redeem'; // TODO it is all returning as redeem
                        case RedeemedItemCategories.SPECIAL_OFFER:
                            return 'special_offer';
                        case RedeemedItemCategories.ALL_YEAR_ROUND:
                            return 'all_year_round';
                        case RedeemedItemCategories.ARTS_AND_CULTURE:
                            return 'arts_and_culture';
                        case RedeemedItemCategories.BOOKSTORE_AND_CULTURAL_INSTITUTION:
                            return 'bookstore_and_cultural_institution';
                        case RedeemedItemCategories.LIFESTYLE:
                            return 'lifestyle';
                        case RedeemedItemCategories.MUSIC_CENTRE_AND_INSTITUTION:
                            return 'music_centre_and_institution';
                        case RedeemedItemCategories.RESTAURANT:
                            return 'restaurant';
                        default:
                            return 'redeem';
                    }
                })();

                const detail = (() => {
                    switch (category?.name['en']) {
                        case RedeemedItemCategories.EVENT:
                            return events;
                        case RedeemedItemCategories.REWARDS:
                            return redeems;
                        case RedeemedItemCategories.SPECIAL_OFFER:
                            return specialOffers;
                        case RedeemedItemCategories.ALL_YEAR_ROUND:
                            return allYearRounds;
                        case RedeemedItemCategories.ARTS_AND_CULTURE:
                            return artsAndCultures;
                        case RedeemedItemCategories.BOOKSTORE_AND_CULTURAL_INSTITUTION:
                            return bookstoreAndCulturalInstitutions;
                        case RedeemedItemCategories.LIFESTYLE:
                            return lifestyles;
                        case RedeemedItemCategories.MUSIC_CENTRE_AND_INSTITUTION:
                            return musicCentreAndInstitutions;
                        case RedeemedItemCategories.RESTAURANT:
                            return restaurants;
                        default:
                            return redeems;
                    }
                })();

                return {
                    title,
                    detail
                };
            });
            setRewards(orderedRewards);
        }

    }, [IsRedeemItemsReady == 'ready_for_guest']);

    // TODO optimize the code above and below.
    useEffect(() => {
        if (redeemed_item_categories) {
            const orderedRewards = redeemed_item_categories.map(category => {
                const title = (() => {
                    switch (category?.name['en']) {
                        case RedeemedItemCategories.EVENT:
                            return 'event';
                        case RedeemedItemCategories.REWARDS:
                            return 'redeem';
                        case RedeemedItemCategories.SPECIAL_OFFER:
                            return 'special_offer';
                        case RedeemedItemCategories.ALL_YEAR_ROUND:
                            return 'all_year_round';
                        case RedeemedItemCategories.ARTS_AND_CULTURE:
                            return 'arts_and_culture';
                        case RedeemedItemCategories.BOOKSTORE_AND_CULTURAL_INSTITUTION:
                            return 'bookstore_and_cultural_institution';
                        case RedeemedItemCategories.LIFESTYLE:
                            return 'lifestyle';
                        case RedeemedItemCategories.MUSIC_CENTRE_AND_INSTITUTION:
                            return 'music_centre_and_institution';
                        case RedeemedItemCategories.RESTAURANT:
                            return 'restaurant';
                        default:
                            return 'redeem';
                    }
                })();

                const detail = (() => {
                    switch (category?.name['en']) {
                        case RedeemedItemCategories.EVENT:
                            return events;
                        case RedeemedItemCategories.REWARDS:
                            return redeems;
                        case RedeemedItemCategories.SPECIAL_OFFER:
                            return specialOffers;
                        case RedeemedItemCategories.ALL_YEAR_ROUND:
                            return allYearRounds;
                        case RedeemedItemCategories.ARTS_AND_CULTURE:
                            return artsAndCultures;
                        case RedeemedItemCategories.BOOKSTORE_AND_CULTURAL_INSTITUTION:
                            return bookstoreAndCulturalInstitutions;
                        case RedeemedItemCategories.LIFESTYLE:
                            return lifestyles;
                        case RedeemedItemCategories.MUSIC_CENTRE_AND_INSTITUTION:
                            return musicCentreAndInstitutions;
                        case RedeemedItemCategories.RESTAURANT:
                            return restaurants;
                        default:
                            return redeems;
                    }
                })();

                return {
                    title,
                    detail
                };
            });
            setRewards(orderedRewards);
        }

    }, [IsRedeemItemsReady == 'ready_for_member'])

    return (
        <section className="mainContents mainContents2">
            <TitleBar
                title = {t('landing_page.redeem_rewards')}
            />
            <div className="container conWrapper conWrapper2 desktop-title-bar">
                <div className="row">
                    <div className="col-12 contents contents2">
                        <div className="redeemCons">
                            <WelcomeBackBar showAvailablePoints={true} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container conWrapper">
                <div className="row">
                    <div className="col-12 contents contents2">
                        <div className="redeemCons redeemCons2">
                            <div className="d-flex col-12 con1">
                                <div className="col-12 asiamiles d-flex flex-wrap">
                                    <div className="col-12 col-sm-2 d-flex align-items-center">
                                        <img className="img-fluid" src="/assets/img/Cathay_EN_Horizontal_Colour.png" />
                                    </div>
                                    <div className="col-12 col-sm-8 d-flex align-items-center asiamileText">
                                        <p className="mt-3">
                                            <span className='' dangerouslySetInnerHTML={{ __html: t('registration_common.asia_miles_conversion_content') }}></span>
                                            <br />
                                        </p>
                                    </div>
                                    {
                                        !profile ? (
                                            <div className="col-12 col-sm-2 d-flex align-items-center asiamilesBtn">
                                                <LoginButton
                                                    fontStyle={'white'}
                                                    loginBtnText={'user.login'}
                                                />
                                            </div>
                                        ) : (
                                            <Link className="col-12 col-sm-2 d-flex align-items-center asiamilesBtn" to={`/${i18n.language}/asiamiles`}>
                                                <button className='btn' type="button">{t('landing_page.asia_miles')}</button>
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>
                            <div className='col-12 flex-wrap homeCons'>
                                {/* Iterate over each "playlist" i.e. type of rewards */}
                                {rewards.map((reward, i) => (
                                    <div key={i}>
                                        {/* reward.detail is an array of reward items */}
                                        {(reward.detail || []).length > 0 && <div className="con2">
                                            <h2 className="title1">{redeemed_item_categories[i]?.name[i18n.language] || t(`rewards.${reward.title}`)}</h2>
                                            <div className="redeemItems slides slide1">
                                                <SimpleSlider rewards={reward.detail} />
                                            </div>
                                        </div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobileOn blankSpace"></div>
        </section>
    )
};

const mapStateToProps = state => ({
    profile: state.shared.profile,
    IsRedeemItemsReady: state.shared.is_redeem_items_ready,
    allYearRounds: state.shared.all_year_rounds,
    redeems: state.shared.redeems,
    specialOffers: state.shared.special_offers,
    artsAndCultures: state.shared.arts_and_cultures,
    bookstoreAndCulturalInstitutions: state.shared.bookstore_and_cultural_institutions,
    lifestyles: state.shared.lifestyles,
    musicCentreAndInstitutions: state.shared.music_centre_and_institutions,
    restaurants: state.shared.restaurants,
    redeemed_item_categories: state.shared.redeemed_item_categories,
    events: state.shared.events,
});

export default connect(mapStateToProps)(RewardList);
