import Spinner from 'react-bootstrap/Spinner';

function ReactLoadingSpinner() {

  return (
    <>
    <div className='loading-spinner-background' 
        style={{
            position: 'fixed',
            height: '100vh',
            top: '0',
            left: '0',
            width: '100vw',
            backgroundColor: 'rgb(0 0 0 / 70%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10000,
        }}
    >
        <Spinner className='loading-spinner' animation="border" variant="danger" >
        <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>
    </>
  );
}

export default ReactLoadingSpinner;