import { useTranslation } from "react-i18next";

const UserName = ({ profile }) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            {
                // show nth if no chinese, first name and last name
                (profile?.chinese_name || profile?.last_name || profile?.first_name) 
                &&
                (
                    i18n.language != 'en' ?
                        <>
                            { // Chinese salutation
                                (profile?.chinese_name || profile?.first_name || profile?.last_name)
                                + (profile?.salutations.toLowerCase()
                                    ? t(`landing_page.${profile?.salutations.toLowerCase()}`)
                                    : ''
                                )
                            }
                        </>
                        :
                        <>
                            { // English salutation
                                (profile?.salutations.toLowerCase()
                                    ? (t(`landing_page.${profile?.salutations.toLowerCase()}`) + ' ')
                                    : ''
                                ) + ( profile?.first_name || profile?.last_name  || profile?.chinese_name)
                            }
                        </>
                )
            }
        </>
    );
}

export default UserName;