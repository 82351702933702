import { useTranslation } from "react-i18next";
import './TitleBar.css';

const TitleBar = ({ title }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={`conWrapper conWrapper2 fullWrapper`}>
        <div className="row">
          <div className="contents contents2">
            <div className="redeemCons redeemCons2">
              <div className="con2 mt15">
                <h1 className="title2 redText">{title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TitleBar;