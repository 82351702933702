export enum AsiaPayFormLang {
    en = 'E',
    tc = 'C',
    sc = 'C',
}

export enum DeliveryType {
    mail = 'Mail',
    email = 'Email', // for coupon
    on_site_pick_up = 'On-site pick-up',
  }

export enum FormTypes {
    PROFILE_EXISTING_FORM = 'Existing',
    PROFILE_FREE_FORM = 'Free',
    PROFILE_RED_FORM = 'Red',
    PROFILE_SC_FORM = 'SC',
    PROFILE_SUBMITTED = 'Submitted',
    PROFILE_YA_FORM = 'YA',
}

export enum pointTransactionType {
    completed_profile = 'Completed Profile',
    scan_ticket_thanksgiving = 'Scan Ticket Thanksgiving',
    scan_thanksgiving = 'Scan Thanksgiving',
    scan_ticket = 'Scan ticket',
    reward_redemption = 'Reward Redemption',
    asia_miles_redemption = 'Asia Miles Redemption',
    bulk_import = 'Bulk Import',
    return_refund_exchange = 'Return/Refund/Exchange',
    christmas_birthday_reward_2023 = '2023 Christmas Club Bravo Double Points',
    birthday_double_points = 'Birthday Double Points',
    season_subscription_points = '2324 Season Subscription Points',
}

export enum Position {
    top = 'top',
    bottom = 'bottom',
}

// This should be in sync with the constants in MemberProfile.php
export enum registrationForm {
    existing = 'Existing',
    free = 'Free',
    red = 'Red',
    sc = 'SC',
    submitted = 'Submitted',
    ya = 'YA',
}

export enum ScanResultStatus {
    found = 'Found',
    pending = 'Pending',
    invalid = 'Invalid',
    success = 'Success',
    expired = 'Expired',
    duplicated = 'Duplicated',
    not_found = 'Not Found',
}

export enum RewardStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}
export enum EventStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    DRAFT = 'Hidden',
    PUBLIC = 'Public',
}

// Must be in sync with constants in MemberProfile
export enum YAStatus {
    YA_STATUS_NA = 'N/A',
    YA_STATUS_PENDING_PAYMENT = 'Pending Payment',
    YA_STATUS_PENDING_APPROVAL = 'Pending Approval',
    YA_STATUS_APPROVED = 'Approved',
    YA_STATUS_REJECTED = 'Rejected',
    YA_STATUS_EXPIRED = 'Expired',
}

