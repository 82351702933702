import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { parseFormData, useOldMember } from "../../services/helpers";
import { getExistingMemberProfile } from "../../services/user";
import Popup from "../Popup";
import ReactLoadingSpinner from "../ReactLoadingSpinner";
import RegistrationContainer from "./RegistrationContainer";
import { connect } from "react-redux";
import { sharedSetItem, SharedSetItemType } from "../../store/shared";

const MigrationForm = ({
  placeholder_membership_number,
  placeholder_last_name,
  placeholder_email_or_mobile,
}) => { 
  const { t, i18n } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const { setOldMember } = useOldMember();
  const [formData, setFormData] = useState<any>({});
  const [validationError, setValidationError] = useState(null);
  const [placeholderMembershipNumber, setPlaceholderMembershipNumber] = useState(null);
  const [placeholderLastName, setPlaceholderLastName] = useState(null);
  const [placeholderEmailOrMobile, setPlaceholderEmailOrMobile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormData = (e) => {
    const { name, value } = parseFormData(e);
    setFormData({ ...formData, [name]: value });
  }

  const submit = async () => {
    setIsLoading(true);

    const res = await getExistingMemberProfile(formData);
    if (res.success) {
      setOldMember(res.existingMemberProfile);
      loginWithRedirect({
        type: 'register',
        lang: i18n.language,
        redirectUri: `${window.location.origin}/login`,
        appState: {
            page: `/${i18n.language}/registration-existing`
        }
      });
    } else {
      setValidationError(t('error.incorrect_account_data'));
    }

    setIsLoading(false);
  };
  
  useEffect(()=>{
    if (placeholder_membership_number && placeholder_membership_number.length > 0) setPlaceholderMembershipNumber(placeholder_membership_number[0].name[i18n.language]);
    if (placeholder_last_name && placeholder_last_name.length > 0) setPlaceholderLastName(placeholder_last_name[0].name[i18n.language]);
    if (placeholder_email_or_mobile && placeholder_email_or_mobile.length > 0)  setPlaceholderEmailOrMobile(placeholder_email_or_mobile[0].name[i18n.language]);
  },[i18n.language]);

  return (
    <>
      {validationError && <Popup
         title={''} // temp removed error.validation_error
        message={validationError}
        buttons={[
          {
            label: t('button.close'),
            callback: () => {
              setValidationError(null);
            }
          }
        ]}
      />}

      {isLoading && <ReactLoadingSpinner />}

      <RegistrationContainer showHint={false}>
        <h2 className="title1">{t('registration_common.registration')}</h2>
        <h1 className="title2">{t('registration_membership_options.current_members_title')}</h1>
        <p>{t('registration_common.migrate_description')}</p>

        <br/>
        <form>
          <div className="formCol col-12 col-xl-8">
            <p className="formTitle">
              {t('registration_existing_step_zero.club_bravo_membership_number_with_example')}
            </p>
            <input
              name="cb_member_no"
              className="form-control formInput"
              type="text"
              onChange={handleFormData}
              value={formData?.cb_member_no || ''}
              placeholder={ placeholderMembershipNumber || "e.g. CB123456" }
            />
          </div>
          <div className="formCol col-12 col-xl-8">
            <p className="formTitle">
              {t('registration_existing_step_zero.your_last_name_with_example')}
            </p>
            <input
              name="last_name"
              className="form-control formInput"
              type="text"
              onChange={handleFormData}
              value={formData?.last_name || ''}
              placeholder={placeholderLastName || "e.g. Chan" }
            />
          </div>
          <div className="formCol col-12 col-xl-8">
            <p className="formTitle">
              {t('landing_page.email_or_mobile')}
            </p>
            <input
              name="email_or_mobile"
              className="form-control formInput"
              type="text"
              onChange={handleFormData}
              value={formData?.email_or_mobile || ''}
              placeholder={placeholderEmailOrMobile || t('landing_page.email_or_mobile_placeholder') }
            />
          </div>
          <div className="col-12 formCol d-flex justify-content-end">
            <label
              id="btnSubmit"
              className="btn nextStep"
              onClick={submit}
            >
              {t('button.submit')}
            </label>
          </div>
        </form>
      </RegistrationContainer>
    </>
  );
};

const mapStateToProps = state => ({
  placeholder_membership_number: state.shared.placeholder_membership_number,
  placeholder_last_name: state.shared.placeholder_last_name,
  placeholder_email_or_mobile: state.shared.placeholder_email_or_mobile,
});

export default connect(mapStateToProps)(MigrationForm);
