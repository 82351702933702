import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import LoginButton from '../components/LoginButton';
import { useEffect, useState } from "react";
import React from "react";

const Header = () => {
    const navigate = useHistory();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [activeMenuItem, setActiveMenuItem] = useState(String);
    const path = window.location.pathname
      .split('/')
      .filter(v => !!v && ['en', 'tc', 'sc'].indexOf(v) === -1)
      .join('/');

    const changeTo = (lang) => {
        i18n.changeLanguage(lang);
        navigate.push(`/${i18n.language}/${path}`, location.state);
    }

    useEffect(() => {
        setActiveMenuItem(path);
    }, [location]);

    return (
        <section className="mainHeader">
            <header>
                <div className="header1">
                    <div className="col-sm-6 d-flex align-items-end">
                        <Link to={`/${i18n.language}`} className="d-block">
                            <img className="logo-hkphil" src="/assets/img/logo_hkphil.png" />
                        </Link>
                        <a className="d-block">
                            <img className="logo-cb" src="/assets/img/logo_club_bravo.png" />
                        </a>
                    </div>

                    <div className="col-6 d-none d-sm-flex align-items-end justify-content-end">
                        <a className="d-block" href="https://www.swirepacific.com/en/global/home.php">
                            <img className="logo-swire" src="/assets/img/logo_swire.svg" />
                        </a>
                    </div>
                </div>
                <div className="header2">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-8 d-flex align-items-center nav1 px-0">
                                <ul className="nav">
                                    <li className="nav-item navItem0">
                                        <Link className="nav-link nav-link-bottom" to={`/${i18n.language}`}>
                                            <div className="icons">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icon-tabler-home">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                                                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                                                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                                                </svg>
                                            </div>
                                            <p><strong>{t('header.home')}</strong></p>
                                        </Link>
                                    </li>
                                    <li className="nav-item navItem1">
                                        <Link className='nav-link' style={{ textDecoration: "none" }} 
                                            to={`/${i18n.language}/whats-club-bravo`}
                                        >
                                            <p className={`${activeMenuItem == 'whats-club-bravo' && 'active'}`}><strong>{t('header.what_is_club_bravo')}</strong></p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link nav-link-bottom" style={{ textDecoration: "none" }} to={`/${i18n.language}/reward-list`}>
                                            <div className="icons">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icon-tabler-flag">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <line x1="5" y1="5" x2="5" y2="21"></line>
                                                    <line x1="19" y1="5" x2="19" y2="14"></line>
                                                    <path d="M5 5a5 5 0 0 1 7 0a5 5 0 0 0 7 0"></path>
                                                    <path d="M5 14a5 5 0 0 1 7 0a5 5 0 0 0 7 0"></path>
                                                </svg>
                                            </div>
                                            <p className={`${activeMenuItem == 'reward-list' && 'active'}`}><strong>{t('header.offers')}</strong><br /></p>
                                        </Link>
                                    </li>
                                    <li className="nav-item navItem3">
                                        <Link className="nav-link" style={{ textDecoration: "none" }} to={`/${i18n.language}/scan-intro`}>
                                            <p className={`${activeMenuItem == 'scan-intro' && 'active'}`}><span>{t('header.scan_your_ticket')}</span></p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link nav-link-bottom" style={{ textDecoration: "none" }} to={`/${i18n.language}/how-to`}>
                                            <div className="icons"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icon-tabler-question-mark">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                                                <line x1="12" y1="19" x2="12" y2="19.01"></line>
                                            </svg></div>
                                            <p className={`${activeMenuItem == 'how-to' && 'active'}`}><strong>{t('header.how_to')}</strong><br /></p>
                                        </Link>
                                    </li>
                                    <li className="nav-item navItem5">
                                        <LoginButton
                                            showLoginIcon={true}
                                            fontStyle={'primary-text-color fs14'}
                                            loginBtnText={'user.login'}
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex justify-content-end align-items-center loginM">
                                <ul className="nav nav2 ps-0">
                                    {i18n.language != 'en' && (
                                        <li className="nav-item" onClick={() => changeTo('en')}>
                                            <a className="nav-link"><strong>EN</strong></a>
                                        </li>
                                    )}
                                    {i18n.language != 'tc' && (
                                        <li className="nav-item" onClick={() => changeTo('tc')}>
                                            <a className="nav-link">繁</a>
                                        </li>
                                    )}
                                    {i18n.language != 'sc' && (
                                        <li className="nav-item" onClick={() => changeTo('sc')}>
                                            <a className="nav-link"><strong>简</strong></a>
                                        </li>
                                    )}
                                    <li className="nav-item">
                                        <a className="nav-link" href={`https://www.hkphil.org/${i18n.language}/form/contact/entry`}>
                                            <i className="fas fa-envelope"></i>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <LoginButton
                                            showLoginIcon={true}
                                            fontStyle={'white'}
                                            loginBtnText={'user.login'}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header >
        </section >
    )
}

export default Header;