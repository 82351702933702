const initState = {
    hasTriedLoginAuth0: false,

    profile: null,
    auth0_change_password_path: null,
    auth0_resend_email_path: null,
    instruments: [],
    countries: [],
    error_page_not_found: [],
    faq: [],
    tnc: null,
    home_grid: [],
    what_is_club_bravo: [],
    welcome_black: [],
    welcome_red: [],
    welcome_gold: [],
    redeem_success: [],
    point_insufficient: [],
    stock_insufficient: [],
    asia_miles_success: [],
    asia_miles_fail: [],
    asia_pay_fail: [],
    asia_pay_error: [],
    scan_required_login: [],
    scan_duplicated: [],
    scan_success: [],
    scan_pending: [],
    scan_expired: [],
    scan_invalid: [],
    how_to_banner: [],
    registration_form_benefit: [],
    scan_tutorial: [],
    asiamiles: null,
    before_priority_message: null,
    start_priority_message: null,
    public_expired_message: null,
    registration: [],
    static_page: [],

    isRedeemItemsReady: false,
    highlight_rewards: [],
    all_year_rounds: [],
    redeems: [],
    special_offers: [],
    arts_and_cultures: [],
    bookstore_and_cultural_institutions: [],
    lifestyles: [],
    music_centre_and_institutions: [],
    restaurants: [],
    events: [],
};

export enum SharedSetItemType {
    has_tried_login_auth0 = 'SET_HAS_TRIED_LOGIN_AUTH0',

    profile = 'SET_PROFILE',
    auth0_change_password_path = 'SET_AUTH0_CHANGE_PASSWORD_PATH',
    auth0_resend_email_path = 'SET_AUTH0_RESEND_EMAIL_PATH',
    generic_static_content = 'SET_GENERIC_STATIC_CONTENT',
    instruments = 'SET_INSTRUMENTS',
    countries = 'SET_COUNTRIES',
    error_page_not_found = 'SET_ERROR_PAGE_NOT_FOUND',
    faq = 'SET_FAQ',
    tnc = 'SET_TNC',
    home_grid = 'SET_HOME_GRID',
    what_is_club_bravo = 'SET_WHAT_IS_CLUB_BRAVO',
    welcome_black = 'SET_WELCOME_BLACK',
    welcome_red = 'SET_WELCOME_RED',
    welcome_gold = 'SET_WELCOME_GOLD',
    redeem_on_site_pick_up_item_success = 'SET_REDEEM_ON_SITE_PICK_UP_ITEM_SUCCESS',
    redeem_mail_item_success = 'SET_REDEEM_MAIL_ITEM_SUCCESS',
    redeem_coupon_success = 'SET_REDEEM_COUPON_SUCCESS',
    redeem_free_ticket_success = 'SET_REDEEM_FREE_TICKET_SUCCESS',
    point_insufficient = 'SET_POINT_INSUFFICIENT',
    stock_insufficient = 'SET_STOCK_INSUFFICIENT',
    asia_miles_success = 'SET_ASIA_MILES_SUCCESS',
    asia_miles_fail = 'SET_ASIA_MILES_FAIL',
    asia_pay_fail = 'SET_ASIA_PAY_FAIL',
    asia_pay_error = 'SET_ASIA_PAY_ERROR',
    scan_required_login = 'SET_SCAN_REQUIRED_LOGIN',
    scan_duplicated = 'SET_SCAN_DUPLICATED',
    scan_expired = 'SET_SCAN_EXPIRED',
    scan_invalid = 'SET_SCAN_INVALID',
    scan_success = 'SET_SCAN_SUCCESS',
    scan_pending = 'SET_SCAN_PENDING',
    asiamile = 'SET_ASIAMILE',
    how_to_banner = 'SET_HOW_TO_BANNER',
    registration_form_benefit = 'SET_REGISTRATION_FORM_BENEFIT',
    scan_tutorial = 'SET_SCAN_TUTORIAL',
    school_types = 'SET_SCHOOL_TYPES',
    placeholder_membership_number = 'SET_PLACEHOLDER_MEMBERSHIP_NUMBER',
    placeholder_last_name = 'SET_PLACEHOLDER_LAST_NAME',
    placeholder_email_or_mobile = 'SET_PLACEHOLDER_EMAIL_OR_MOBILE',
    before_priority_message = 'SET_BEFORE_PRIORITY_MESSAGE',
    start_priority_message = 'SET_START_PRIORITY_MESSAGE',
    public_expired_message = 'SET_PUBLIC_EXPIRED_MESSAGE',
    registration = 'SET_REGISTRATION',
    static_page = 'SET_STATIC_PAGE',

    is_redeem_items_ready = 'SET_IS_REDEEM_ITEMS_READY',
    highlight_rewards = 'SET_HIGHLIGHT_REWARDS',
    redeemed_item_categories = 'SET_REDEEMED_ITEM_CATEGORIES',
    all_year_rounds = 'SET_ALL_YEAR_ROUNDS',
    redeems = 'SET_REDEEMS',
    special_offers = 'SET_SPECIAL_OFFERS',
    arts_and_cultures = 'SET_ARTS_AND_CULTURES',
    bookstore_and_cultural_institutions = 'SET_BOOKSTORE_AND_CULTURAL_INSTITUTIONS',
    lifestyles = 'SET_LIFESTYLES',
    music_centre_and_institutions = 'SET_MUSIC_CENTRE_AND_INSTITUTIONS',
    restaurants = 'SET_RESTAURANTS',
    events = 'SET_EVENTS',
}

const sharedReducer = (state = initState, action) => {
    if (Object.values(SharedSetItemType).indexOf(action.type) === -1) return state;

    const match = /^SET_(.*)/.exec(action.type);
    return {
        ...state,
        [match.at(1).toLowerCase()]: action.payload
    };
}

export const sharedSetItem = (
    type: SharedSetItemType,
    payload: any
) => ({ type, payload });

export default sharedReducer;
